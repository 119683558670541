/* Use this file for adding small snippets of style, that don't fit anywhere else. Or, for bits of style that aren't
yet large enough to move to their own file. */

.center-children {
    --center-children-padding: 16px;

    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: var(--center-children-padding);

    @media (--denim-breakpoint-desktop) {
        & {
            --center-children-padding: 0;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --center-children-padding: 0;
        }
    }
}

.loading {
    --loading-image-height: 100px;
    --loading-image-width: 100px;

    height: var(--loading-image-height);
    transition-timing-function: ease-in-out;
    user-select: none;
    width: var(--loading-image-width);

    & .animate-target {
        --loading-animation-duration: 1s;
        --loading-animation-timing-function: ease-in-out;

        animation: loading-rotate var(--loading-animation-duration) var(--loading-animation-timing-function) infinite;
        transform-origin: center;
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --loading-image-height: 40px;
            --loading-image-width: 306px;

            & .animate-target {
                transform-origin: 66px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --loading-image-height: 30px;
            --loading-image-width: 229px;

            & .animate-target {
                transform-origin: 66px;
            }
        }
    }
}

@keyframes loading-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.divider {
    --divider-width: var(--minerva-divider-width, 24px);
    --divider-vertical-margin: var(--minerva-divider-vertical-margin, 32px);

    margin: var(--divider-vertical-margin) 0;
    width: var(--divider-width);
}

/* Used by components like the temporary landing page, and NotFound to render vertically and
horizontally centered within the grid. */
.center-in-grid {
    align-self: center;
    grid-column: 1 / -1;

    @media (--denim-breakpoint-desktop) {
        & {
            grid-column: 4 / 10;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            grid-column: 3 / 9;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            grid-column: 1 / -1;
        }
    }
}

.api-error {
    margin: 32px 0;
}

.error-text {
    display: block;
    margin: 8px 0 0;

    &.bg-blue {
        --color: var(--genomics-color-secondary-yellow);
    }
}

.player-wrapper {
    padding-top: 56.25%; /* Video player aspect ratio: 100 / (16 / 9) */
    position: relative;
}

.react-player {
    border-radius: 4px;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
}
