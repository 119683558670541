.icon {
    &.add {
        --icon-add: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z"/></svg>');

        &.before::before {
            mask: var(--icon-add) no-repeat;
        }

        &.after::after {
            mask: var(--icon-add) no-repeat;
        }
    }
}
