.static-content {
    & :--heading {
        /* Enough space to account for the header */
        scroll-margin-top: 100px;
    }

    & .content-section {
        --card-margin-bottom: 0;
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;
    }

    & .page-title {
        grid-column: 1 / -1;

        & h1 {
            --heading-margin: 32px 0 0 0;

            & + h3 {
                --heading-margin: 32px 0 0 0;
            }
        }
    }

    & .contents-listing {
        & .contents-link {
            margin: 8px 0 8px 16px;
        }
    }

    & .content-item {
        grid-column: 1 / -1;
        margin-bottom: 32px;
        margin-top: 32px;

        & :last-child {
            margin-bottom: 0;
        }

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    & .content-table-container {
        --table-header-color: var(--genomics-color-shade-white, rgb(255 255 255));
        --table-header-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));

        grid-column: 1 / -1;
        margin-bottom: 32px;

        & table {
            width: 100%;

            & thead {
                background: var(--table-header-background-color);
                color: var(--table-header-color);
            }

            & td,
            & th {
                padding: 16px;
                vertical-align: top;
            }

            & th,
            & td:first-of-type {
                --font-weight: 600;

                font-weight: var(--font-weight);
                text-align: left;

                @supports (font-variation-settings: normal) {
                    & {
                        font-variation-settings: "wght" var(--font-weight);
                    }
                }
            }

            & tr {
                border-bottom: 1px solid var(--genomics-color-shade-mid-grey);
            }

            & .subtext {
                display: block;
                font-size: 14px;
                font-style: italic;
            }

            & .hidden-on-large {
                display: none;
            }
        }

        & ul {
            &.no-decoration {
                margin-bottom: 0;

                & li {
                    font-size: 14px;
                    line-height: 20px;
                    margin: 0;
                }
            }
        }

        @media (--denim-breakpoint-max-tablet-portrait) {
            & table {
                & thead,
                & tbody,
                & th,
                & td,
                & tr {
                    display: block;
                    padding-left: 0;
                    padding-right: 0;
                }

                & thead tr {
                    height: 1px;
                    left: -9999px;
                    position: absolute;
                    top: -9999px;
                    width: 1px;
                }

                & .hidden-on-large {
                    display: block;
                }

                & td {
                    &::before {
                        --font-weight: 600;

                        display: block;
                        font-weight: var(--font-weight);

                        @supports (font-variation-settings: normal) {
                            & {
                                font-variation-settings: "wght" var(--font-weight);
                            }
                        }
                    }
                }

                & th,
                & td:first-of-type {
                    --font-weight: 400;
                }

                &#other-service-providers-table {
                    & td {
                        &:nth-of-type(1)::before {
                            content: "Provider:";
                        }

                        &:nth-of-type(2)::before {
                            content: "Information:";
                        }

                        &:nth-of-type(3)::before {
                            content: "Use of information:";
                        }
                    }
                }

                &#data-use-overview-table {
                    & td {
                        &:nth-of-type(1)::before {
                            content: "Company:";
                        }

                        &:nth-of-type(2)::before {
                            content: "Data / Sample:";
                        }

                        &:nth-of-type(3)::before {
                            content: "Used in an identifiable* form:";
                        }

                        &:nth-of-type(4)::before {
                            content: "Use:";
                        }
                    }
                }

                &#use-of-cookies {
                    & td {
                        &:nth-of-type(1)::before {
                            content: "Service Provider:";
                        }

                        &:nth-of-type(2)::before {
                            content: "Cookie Name:";
                        }

                        &:nth-of-type(3)::before {
                            content: "Expiry:";
                        }

                        &:nth-of-type(4)::before {
                            content: "Description:";
                        }
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .page-title {
            grid-column: span 10;
        }

        & .content-item {
            grid-column: span 8;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .page-title {
            grid-column: span 10;
        }

        & .content-item {
            grid-column: span 6;
        }
    }
}
