.nav-header {
    --nav-header-background-color: var(--denim-nav-header-background-color, transparent);
    --nav-header-position: var(--denim-nav-header-position, fixed);
    --nav-header-position-properties: var(--denim-nav-header-position-properties, 0);

    background-color: var(--nav-header-background-color);
    left: var(--nav-header-position-properties);
    position: var(--nav-header-position);
    right: var(--nav-header-position-properties);
    top: var(--nav-header-position-properties);
    z-index: 1;

    &.go-to-account {
        --denim-nav-padding-vertical: 16px;
    }

    & .nav-width-controller {
        --nav-container-horizontal-margin: var(--denim-grid-horizontal-margin, 16px);
        --nav-container-max-width: var(--denim-grid-max-width, 1072px);

        margin-left: var(--nav-container-horizontal-margin);
        margin-right: var(--nav-container-horizontal-margin);
        max-width: var(--nav-container-max-width);
    }

    &.variant-dark {
        --nav-header-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    }

    &.variant-light {
        --nav-header-background-color: var(--genomics-color-shade-white, rgb(255 255 255));

        & .icon {
            --icon-background-color: var(--genomics-color-shade-black, rgb(29 29 29));
        }

        & .link {
            --link-color: var(--genomics-color-shade-black, rgb(29 29 29));
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .nav-width-controller {
            --nav-container-horizontal-margin: auto;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .nav-width-controller {
            --nav-container-horizontal-margin: 32px;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .nav-width-controller {
            --nav-container-horizontal-margin: 32px;
        }
    }
}
