body {
    --background-color: var(--denim-background-color, rgb(249 249 249));
    --color: var(--denim-color, rgb(249 249 249));
    --font-family: var(--denim-font-family, Inter, sans-serif);
    --font-size: var(--denim-font-size, 16px);
    --font-weight: var(--denim-font-weight, var(--denim-font-weight-normal, 400));
    --letter-spacing: var(--denim-letter-spacing, 0);
    --line-height: var(--denim-line-height, 22px);

    background-color: var(--background-color);
    color: var(--color);
    font-family: var(--font-family);
    font-size: var(--font-size);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: var(--font-weight);
    letter-spacing: var(--letter-spacing);
    line-height: var(--line-height);
    text-transform: none;

    @supports (font-variation-settings: normal) {
        & {
            --font-family: var(--denim-font-family-variable, "Inter var", sans-serif);

            font-variation-settings: "wght" var(--font-weight);
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 18px;
            --line-height: 26px;
        }
    }
}

@custom-selector :--heading
    h1,
    h2,
    h3,
    h4,
    h5,
    h6;

:--heading {
    --heading-color: var(--denim-heading-color, rgb(29 29 29));
    --heading-font-weight: var(--denim-heading-font-weight, var(--denim-font-weight-normal, 400));
    --heading-margin: var(--denim-heading-margin, 0 0 0.75em);
    --heading-padding: var(--denim-heading-padding, 0);
    --heading-text-align: var(--denim-heading-text-align, inherit);

    color: var(--heading-color);
    font-weight: var(--heading-font-weight);
    margin: var(--heading-margin);
    padding: var(--heading-padding);
    text-align: var(--heading-text-align);
}

h1,
h2,
h3 {
    --font-family: var(--denim-font-family-alternate, SK-Modernist, sans-serif);
    --letter-spacing: 0.03em;

    font-family: var(--font-family);
    letter-spacing: var(--letter-spacing);
}

h1 {
    --font-size: 40px;
    --line-height: 40px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 48px;
            --line-height: 48px;
        }
    }
}

h2 {
    --font-size: 30px;
    --line-height: 32px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 32px;
            --line-height: 36px;
        }
    }
}

h3 {
    --font-size: 22px;
    --line-height: 26px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 24px;
            --line-height: 32px;
        }
    }
}

h4 {
    --font-size: 18px;
    --heading-font-weight: var(--denim-font-weight-semibold, 600);
    --line-height: 24px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    @supports (font-variation-settings: normal) {
        & {
            font-variation-settings: "wght" var(--heading-font-weight);
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 20px;
            --line-height: 28px;
        }
    }
}

.is-nav,
nav {
    --font-family: var(--denim-font-family-alternate, SK-Modernist, sans-serif);
    --font-size: 14px;
    --line-height: 18px;

    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 16px;
            --line-height: 22px;
        }
    }
}

.capitalize-first-letter {
    &::first-letter {
        text-transform: capitalize;
    }
}

.is-small,
small {
    --font-size: 14px;
    --letter-spacing: 0;
    --line-height: 18px;

    font-size: var(--font-size);
    letter-spacing: var(--letter-spacing);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 16px;
            --line-height: 22px;
        }
    }
}

.is-large {
    --font-size: 18px;
    --line-height: 24px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 20px;
            --line-height: 28px;
        }
    }
}

.is-eyebrow {
    --font-family: var(--denim-font-family-alternate, SK-Modernist, sans-serif);
    --font-size: 16px;
    --letter-spacing: 0.1em;
    --line-height: 22px;

    font-family: var(--font-family);
    font-size: var(--font-size);
    letter-spacing: var(--letter-spacing);
    line-height: var(--line-height);
    text-transform: uppercase;

    @media (--denim-breakpoint-desktop) {
        & {
            --font-size: 18px;
            --line-height: 26px;
        }
    }

    &.is-small,
    & small {
        --font-size: 14px;
        --letter-spacing: 0.08em;
        --line-height: 18px;

        @media (--denim-breakpoint-desktop) {
            & {
                --line-height: 20px;
            }
        }
    }
}

.is-dark {
    --color: var(--denim-dark-color, var(--genomics-color-shade-mid-grey, rgb(189 189 189)));

    color: var(--color);
}

.is-really-dark {
    --color: var(--denim-really-dark-color, var(--genomics-color-shade-dark-grey, rgb(100 100 100)));

    color: var(--color);
}

.is-caption {
    --font-size: 14px;
    --line-height: 18px;

    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (--denim-breakpoint-desktop) {
        & {
            --line-height: 20px;
        }
    }

    &.is-second {
        --font-size: 12px;
        --line-height: 16px;
    }
}

.is-uppercase {
    text-transform: uppercase;
}

.is-underlined {
    text-decoration: underline;
}

.is-heavy,
strong,
th,
b {
    --font-weight: var(--denim-font-weight-semibold, 600);

    font-weight: var(--font-weight);

    @supports (font-variation-settings: normal) {
        & {
            font-variation-settings: "wght" var(--font-weight);
        }
    }
}

.is-error {
    --color: var(--minerva-color-error-on-navy, rgb(252 65 131));
    --icon-background-color: var(--minerva-color-error-on-navy, rgb(252 65 131));

    color: var(--color);

    /* Not all icons are at the same level in the CSS, so also replicate the style for children */
    & .icon {
        --icon-background-color: var(--color);
    }
}

p {
    --paragraph-bottom-margin: var(--denim-paragraph-vertical-margin, 32px);
    --paragraph-color: var(--denim-paragraph-color, inherit);
    --paragraph-top-margin: var(--denim-paragraph-vertical-margin, 32px);

    color: var(--paragraph-color);
    margin-bottom: var(--paragraph-bottom-margin);
    margin-top: var(--paragraph-top-margin);

    &.half-margin {
        --paragraph-bottom-margin: calc(var(--denim-paragraph-vertical-margin, 32px) / 2);
        --paragraph-top-margin: calc(var(--denim-paragraph-vertical-margin, 32px) / 2);
    }

    &.no-margin {
        --paragraph-bottom-margin: 0;
        --paragraph-top-margin: 0;
    }

    &.no-margin-top {
        --paragraph-top-margin: 0;
    }

    &.text-center {
        text-align: center;
    }

    @nest .form-group & {
        --paragraph-bottom-margin: calc(var(--denim-paragraph-vertical-margin, 32px) / 2);
        --paragraph-top-margin: calc(var(--denim-paragraph-vertical-margin, 32px) / 2);
    }
}

@custom-selector :--list
    ul,
    ol;

:--list {
    --list-bottom-margin: var(--denim-list-vertical-margin, 32px);
    --list-top-margin: var(--denim-list-vertical-margin, 32px);
    --list-padding-left: var(--denim-list-padding-left, 1.25rem);

    margin-bottom: var(--list-bottom-margin);
    margin-top: var(--list-top-margin);
    padding-left: var(--list-padding-left);

    & li {
        --list-item-bottom-margin: var(--denim-list-item-vertical-margin, 16px);
        --list-item-top-margin: var(--denim-list-item-vertical-margin, 16px);

        margin-bottom: var(--list-item-bottom-margin);
        margin-top: var(--list-item-top-margin);
    }

    &.no-decoration {
        --list-padding-left: 0;

        list-style: none;
    }
}
