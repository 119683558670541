/* Shared properties and styles */
.progress-bar-mobile {
    --pbm-overlay-color: var(--denim-pbm-overlay-color, black);
    --pbm-background-color: var(--denim-pbm-background-color, darkgrey);
    --pbm-font-mid-color: var(--denim-pbm-font-mid-color, lightgrey);
    --pbm-font-highlight-color: var(--denim-pbm-font-highlight-color, white);
    --pbm-steps-color: var(--denim-pbm-steps-color, lightgrey);
    --pbm-steps-opacity: var(--denim-pbm-steps-opacity, 0.5);
    --pbm-steps-margin: 0.5em;
    --pbm-progressbar-background-color: var(--denim-pbm-progressbar-background-color, darkgrey);
    --pbm-progressbar-foreground: var(--denim-pbm-progressbar-foreground, white);
    --pbm-spacing: var(--denim-pbm-spacing, 16px);
    --pbm-bar-color: var(--denim-pbm-bar-color, white);
    --pbm-border-radius: 8px;
    --pbm-tick-color: var(--denim-pbm-tick-color, black);

    /* tweak the gradient component's background */
    --denim-pbg-background-color: var(--pbm-background-color);

    user-select: none;

    /* Styles for the non-popped-up version */
    &.outer {
        align-items: center;
        background-color: var(--pbm-background-color);
        border-radius: var(--pbm-border-radius);
        display: flex;
        padding: var(--pbm-spacing) var(--pbm-spacing) var(--pbm-spacing) calc(var(--pbm-spacing) / 2);

        & > .back-button {
            --icon-size: 32px;

            cursor: pointer;
        }

        & > .current-topic {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            & > .text {
                --font-family: Inter, sans-serif;

                font-family: var(--font-family);

                @supports (font-variation-settings: normal) {
                    & {
                        --font-family: "Inter var", sans-serif;
                    }
                }

                & > .title-text {
                    color: var(--pbm-font-highlight-color);
                }

                & > .steps-text {
                    color: var(--pbm-steps-color);
                    margin-left: var(--pbm-steps-margin);
                    opacity: var(--pbm-steps-opacity);
                }
            }

            & > .progress {
                --progress-height: 4px;

                height: var(--progress-height);
                margin-top: 8px;
                overflow: hidden;
                position: relative;

                & > .background {
                    background-color: var(--pbm-progressbar-background-color);
                    border-radius: var(--progress-height);
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }

                & > .filled {
                    background: var(--pbm-progressbar-foreground);
                    border-radius: var(--progress-height);
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }

    /* The background overlay to the modal */
    &.overlay {
        /* I'd make this semi transparent if there was an easy way to */
        background-color: var(--pbm-overlay-color);
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;
    }

    /* Styles for the popped-up version */
    &.modal {
        background-color: var(--pbm-background-color);
        border-radius: var(--pbm-border-radius);
        left: var(--pbm-spacing);
        outline: none;
        overflow: auto;
        padding: var(--pbm-spacing);
        position: absolute;
        right: var(--pbm-spacing);
        top: var(--pbm-spacing);

        & > .topics-container {
            --gradient-width: 22px;

            display: flex;
            padding-top: var(--pbm-spacing);
            position: relative;
            width: 100%;

            & > .gradient {
                /*
                This is a semi arbitrary value to add some padding to the
                bottom of the modal. It's attached to the gradient because
                the topics don't have such a well defined height.
                */
                margin-bottom: 16px;
                width: var(--gradient-width);
            }

            & > .topics {
                left: calc(var(--gradient-width) + var(--pbm-spacing));
                position: absolute;
                right: 0;

                & > .topic {
                    align-items: center;
                    display: flex;
                    position: absolute;

                    /*
                    Center topics vertically around their offset to make
                    it nice and easy to line them up with the circles
                    */
                    transform: translateY(-50%);

                    &.text {
                        color: var(--pbm-font-mid-color);
                    }

                    /* Tweak the styles for the current topic */
                    &.current.text {
                        & > .title-text {
                            color: var(--pbm-font-highlight-color);
                        }

                        & > .steps-text {
                            color: var(--pbm-steps-color);
                            margin-left: var(--pbm-steps-margin);
                            opacity: var(--pbm-steps-opacity);
                        }
                    }
                }
            }
        }

        & > .close-button {
            --icon-size: 32px;
            --icon-background-color: white;

            cursor: pointer;
            position: fixed;
            right: calc(var(--pbm-spacing) * 2);
            top: calc(var(--pbm-spacing) * 2);
        }
    }
}
