.your-resources {
    --card-padding-top: 64px;
    --card-padding-bottom: 0;
    --card-margin-bottom: 0;

    & .grid {
        grid-template-areas:
            "title title"
            "download download"
            "about about";

        & h1 {
            grid-area: title;
        }

        & .download {
            --card-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            --card-color: var(--genomics-color-shade-white, rgb(255 255 255));

            grid-area: download;

            & :--heading {
                --heading-color: var(--genomics-color-primary-light-green, rgb(51 255 196));
            }

            & :--list {
                --list-padding-left: 0;

                list-style-type: none;

                & .icon {
                    --icon-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                    background: var(--genomics-color-primary-light-green, rgb(51 255 196));
                    border-radius: 50%;
                    margin-right: 16px;
                    padding: 2px;
                    vertical-align: middle;

                    &.before::before {
                        margin-right: 0;
                    }
                }
            }

            & > p:last-child {
                margin-bottom: 0;
                margin-top: 16px;
                padding-right: 96px;
            }
        }

        & .about {
            --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));

            background-clip: padding-box, border-box;
            background-image:
                linear-gradient(white, white),
                linear-gradient(225deg, var(--top-color) 0%, var(--bottom-color) 100%);
            background-origin: border-box;
            border: 1px solid transparent;
            grid-area: about;

            /* stylelint-disable-next-line selector-class-pattern */
            &.trait-CVD_US {
                --about-caption-color: var(--minerva-cvd-us-dark);
                --top-color: var(--minerva-cvd-us-dark);
                --bottom-color: var(--minerva-cvd-us-light);
            }

            /* stylelint-disable-next-line selector-class-pattern */
            &.trait-T2D {
                --about-caption-color: var(--minerva-t2d-dark);
                --top-color: var(--minerva-t2d-dark);
                --bottom-color: var(--minerva-t2d-light);
            }

            /* stylelint-disable-next-line selector-class-pattern */
            &.trait-BC {
                --about-caption-color: var(--minerva-bc-dark);
                --top-color: var(--minerva-bc-dark);
                --bottom-color: var(--minerva-bc-light);
            }

            /* stylelint-disable-next-line selector-class-pattern */
            &.trait-PC {
                --about-caption-color: var(--minerva-pc-dark);
                --top-color: var(--minerva-pc-dark);
                --bottom-color: var(--minerva-pc-light);
            }

            & .title {
                display: flex;

                & :--heading {
                    --heading-margin: 0;

                    &.is-caption {
                        color: var(--about-caption-color);
                    }
                }

                & .trait-icon {
                    margin-right: 16px;
                    width: 48px;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "title title title title title title"
                "download download download download download download"
                "about about about about about about";
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            grid-template-areas:
                "title title title title title title title title title title"
                "download download download download download about about about about about";

            & .download {
                --card-padding-left: 32px;
                --card-padding-right: 32px;
            }

            & .about {
                --card-padding-left: 32px;
                --card-padding-right: 32px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .grid {
            grid-template-areas:
                "title title title title title title title title title title title title"
                "download download download download download download about about about about about about";

            & .download {
                --card-padding-left: 32px;
                --card-padding-right: 32px;
            }

            & .about {
                --card-padding-left: 32px;
                --card-padding-right: 32px;
            }
        }
    }
}
