.trait-list-title {
    margin-bottom: 16px;
}

.trait-item {
    --card-title-font-size: 22px; /* The font size of our h3 title. */
    --denim-icon-size: calc(var(--card-title-font-size) * 1.25);

    & .trait-item-content {
        display: flex;
        flex-direction: column;

        @media (--denim-breakpoint-desktop) {
            --card-title-font-size: 24px;
        }

        & h3 {
            font-size: var(--card-title-font-size);
            margin-bottom: 0;
        }

        & p {
            margin: 0;
        }

        & .exclusion-reason-faq {
            margin-top: 16px;
        }
    }

    &:last-of-type {
        margin-bottom: 32px;

        @media (--denim-breakpoint-desktop) {
            & {
                margin-bottom: 42px;
            }
        }
    }
}

.participant-details-section {
    margin-bottom: 48px;
    white-space: pre-line;

    @media (--denim-breakpoint-desktop) {
        & {
            margin-bottom: 64px;
        }
    }

    & .participant-details-item {
        margin-bottom: 32px;

        & p,
        & a {
            margin: 0;
        }
    }

    &:last-of-type {
        margin-bottom: 32px;

        @media (--denim-breakpoint-desktop) {
            & {
                margin-bottom: 42px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .addresses {
            display: flex;

            & .participant-details-item {
                flex: 1 1 auto;
                padding-right: 16px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .addresses {
            display: flex;

            & .participant-details-item {
                flex: 1 1 auto;
                padding-right: 32px;
            }
        }
    }
}
