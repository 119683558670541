.icon {
    &.checkmark--outline {
        --icon-checkmark--outline: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M14 21.414l-5-5.001L10.413 15 14 18.586 21.585 11 23 12.415l-9 8.999z"/><path d="M16 2a14 14 0 1014 14A14 14 0 0016 2zm0 26a12 12 0 1112-12 12 12 0 01-12 12z"/></svg>');

        &.before::before {
            mask: var(--icon-checkmark--outline) no-repeat;
        }

        &.after::after {
            mask: var(--icon-checkmark--outline) no-repeat;
        }
    }
}
