.icon {
    &.chat--launch {
        --icon-chat--launch: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13.75 2.5V3.75H16.6163L12.5 7.86625L13.3837 8.75L17.5 4.63375V7.5H18.75V2.5H13.75Z"/><path d="M17.5 10V12.5C17.5003 12.6642 17.4682 12.8269 17.4054 12.9787C17.3427 13.1305 17.2507 13.2684 17.1345 13.3845C17.0184 13.5007 16.8805 13.5927 16.7287 13.6554C16.5769 13.7182 16.4142 13.7503 16.25 13.75H12.5L10 18.125L11.0849 18.75L13.2279 15H16.25C16.5783 15.0001 16.9034 14.9354 17.2068 14.8098C17.5101 14.6842 17.7858 14.5001 18.0179 14.2679C18.2501 14.0358 18.4342 13.7601 18.5598 13.4568C18.6854 13.1534 18.7501 12.8283 18.75 12.5V10H17.5Z"/><path d="M2.5 12.5V5C2.49971 4.83577 2.53185 4.67309 2.59456 4.52131C2.65728 4.36952 2.74934 4.2316 2.86547 4.11547C2.9816 3.99934 3.11952 3.90728 3.27131 3.84456C3.42309 3.78185 3.58577 3.74971 3.75 3.75H11.25V2.5H3.75C3.42166 2.49989 3.09652 2.56447 2.79316 2.69007C2.48979 2.81566 2.21415 2.99981 1.98198 3.23198C1.74981 3.46415 1.56566 3.73979 1.44007 4.04316C1.31447 4.34652 1.24989 4.67166 1.25 5V12.5C1.24993 12.8283 1.31455 13.1534 1.44017 13.4568C1.56578 13.7601 1.74993 14.0358 1.98209 14.2679C2.21425 14.5001 2.48987 14.6842 2.79321 14.8098C3.09656 14.9354 3.42168 15.0001 3.75 15H9.375V13.75H3.75C3.58577 13.7503 3.42309 13.7182 3.27131 13.6554C3.11952 13.5927 2.9816 13.5007 2.86547 13.3845C2.74934 13.2684 2.65728 13.1305 2.59456 12.9787C2.53185 12.8269 2.49971 12.6642 2.5 12.5Z"/></svg>');

        &.before::before {
            mask: var(--icon-chat--launch) no-repeat;
        }

        &.after::after {
            mask: var(--icon-chat--launch) no-repeat;
        }
    }
}
