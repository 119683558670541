.icon {
    &.launch {
        --icon-launch: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M26 28H6a2.003 2.003 0 01-2-2V6a2.003 2.003 0 012-2h10v2H6v20h20V16h2v10a2.003 2.003 0 01-2 2z"/><path d="M20 2v2h6.586L18 12.586 19.414 14 28 5.414V12h2V2H20z"/></svg>');

        &.before::before {
            mask: var(--icon-launch) no-repeat;
        }

        &.after::after {
            mask: var(--icon-launch) no-repeat;
        }
    }
}
