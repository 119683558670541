.how-to-schedule-appointment {
    --card-background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
    --card-margin-bottom: 0;
    --card-padding-bottom: 32px;
    --card-padding-top: 32px;

    & * {
        color: var(--genomics-color-primary-navy, rgb(17 0 53));
    }

    & .grid {
        grid-template-areas:
            "title title"
            "steps steps"
            "video video";

        & .title {
            grid-area: title;
        }

        & .steps {
            grid-area: steps;

            & [class^="step-"] {
                display: flex;
                margin: 16px 0;

                & img {
                    margin-right: 16px;
                    vertical-align: top;
                }

                & .number {
                    color: var(--genomics-color-shade-dark-grey, rgb(100 100 100));
                }
            }
        }

        & .video {
            display: flex;
            flex-direction: column;
            grid-area: video;
            margin: auto 0;

            & .video-description {
                color: var(--genomics-color-shade-black, rgb(0 0 0));
                margin-top: 8px;
                text-align: center;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        --card-padding-bottom: 80px;
        --card-padding-top: 64px;

        & .grid {
            grid-template-areas:
                "title title title title title . video video video video video video"
                "steps steps steps steps steps . video video video video video video";
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --card-padding-bottom: 80px;
        --card-padding-top: 64px;

        & .grid {
            grid-template-areas:
                "title title title title title video video video video video"
                "steps steps steps steps steps video video video video video";
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        --card-padding-top: 48px;

        & .grid {
            grid-template-areas:
                "title title title title title title"
                "steps steps steps steps steps steps"
                "video video video video video video";
        }
    }
}
