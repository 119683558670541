.link {
    --link-color: var(--denim-link-color, rgb(255 255 255));
    --link-hover-text-decoration-line: var(--denim-link-hover-text-decoration-line, underline);
    --link-hover-text-decoration-style: var(--denim-link-hover-text-decoration-style, solid);
    --link-hover-text-underline-offset: var(--denim-link-hover-text-underline-offset, 6px);
    --link-icon-size: var(--denim-link-icon-size, 1.4444em);

    color: var(--link-color);
    cursor: pointer;
    text-decoration: none;
    user-select: none;

    &.icon {
        --icon-size: var(--link-icon-size);

        &.before {
            &::before {
                --icon-before-margin: 0.2222em;
            }
        }

        &.after {
            &::after {
                --icon-after-margin: 0.2222em;
            }
        }
    }

    &:hover {
        text-decoration-line: var(--link-hover-text-decoration-line);
        text-decoration-style: var(--link-hover-text-decoration-style);
        text-underline-offset: var(--link-hover-text-underline-offset);
    }

    & span {
        --link-text-decoration-line: var(--denim-link-text-decoration-line, underline);
        --link-text-decoration-style: var(--denim-link-text-decoration-style, dotted);
        --link-text-underline-offset: var(--denim-link-text-underline-offset, 3px);

        text-decoration-line: var(--link-text-decoration-line);
        text-decoration-style: var(--link-text-decoration-style);
        text-underline-offset: var(--link-text-underline-offset);
    }

    &.active {
        & span {
            --link-text-decoration-style: underline;
        }
    }

    &.is-caption {
        &.icon {
            --icon-size: 1.4286em;

            &.before {
                &::before {
                    --icon-before-margin: 0.1428em;
                }
            }

            &.after {
                &::after {
                    --icon-after-margin: 0.1428em;
                }
            }
        }

        &:hover {
            --link-hover-text-underline-offset: 4px;
        }

        & span {
            --link-text-underline-offset: 2px;
        }
    }

    &.is-help-link {
        --link-is-help-margin-top: var(--denim-link-is-help-margin-top, 32px);

        display: block;
        margin-top: var(--link-is-help-margin-top);
    }
}
