.icon {
    &.close {
        --icon-close: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4l6.6 6.6L8 22.6 9.4 24l6.6-6.6 6.6 6.6 1.4-1.4-6.6-6.6L24 9.4z"/></svg>');

        &.before::before {
            mask: var(--icon-close) no-repeat;
        }

        &.after::after {
            mask: var(--icon-close) no-repeat;
        }
    }
}
