.icon {
    --icon-align: var(--denim-icon-align, center);
    --icon-background-color: var(--denim-icon-background-color, rgb(29 29 29));
    --icon-justify: var(--denim-icon-justify, flex-start);
    --icon-size: var(--denim-icon-size, calc(var(--font-size) * 1.25));

    align-items: var(--icon-align);
    display: inline-flex;
    justify-content: var(--icon-justify);

    &.before {
        &::before {
            --icon-before-margin: var(--denim-icon-before-margin, 0.5em);

            background-color: var(--icon-background-color);
            background-size: var(--icon-size) var(--icon-size);
            content: " ";
            display: inline-block;
            margin-right: var(--icon-before-margin);
            min-height: var(--icon-size);
            min-width: var(--icon-size);
        }
    }

    &.after {
        &::after {
            --icon-after-margin: var(--denim-icon-after-margin, 0.5em);

            background-color: var(--icon-background-color);
            background-size: var(--icon-size) var(--icon-size);
            content: " ";
            display: inline-block;
            margin-left: var(--icon-after-margin);
            min-height: var(--icon-size);
            min-width: var(--icon-size);
        }
    }
}
