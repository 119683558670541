.about-genome-medical {
    --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-padding-bottom: 32px;
    --card-padding-top: 32px;
    --card-margin-bottom: 0;

    & .grid {
        grid-template-areas:
            "title title"
            "description description"
            "link link"
            "logo logo"
            "video video";

        & .video {
            display: flex;
            flex-direction: column;
            grid-area: video;
            margin: auto 0;

            & .video-description {
                color: var(--genomics-color-shade-black, rgb(0 0 0));
                margin-top: 8px;
                text-align: center;
            }
        }

        & .title {
            color: var(--genomics-color-primary-navy, rgb(17 0 53));
            grid-area: title;
            margin: 0;
        }

        & .description {
            --paragraph-bottom-margin: 24px;
            --paragraph-top-margin: 24px;

            color: var(--genomics-color-primary-navy, rgb(17 0 53));
            grid-area: description;
        }

        & .link {
            --link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));

            grid-area: link;

            &.icon {
                --icon-background-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
            }
        }

        & .genome-medical-logo {
            --about-genome-medical-logo-margin-bottom: 24px;
            --about-genome-medical-logo-margin-top: 32px;

            grid-area: logo;
            margin-bottom: var(--about-genome-medical-logo-margin-bottom);
            margin-top: var(--about-genome-medical-logo-margin-top);
        }
    }

    @media (--denim-breakpoint-desktop) {
        --card-padding-bottom: 80px;
        --card-padding-top: 64px;

        & .grid {
            grid-template-areas:
                "video video video video video video . title title title title title"
                "video video video video video video . description description description description description"
                "video video video video video video . link link link link link"
                "video video video video video video . logo logo logo logo logo";

            & .description {
                --paragraph-bottom-margin: 32px;
                --paragraph-top-margin: 32px;
            }

            & .genome-medical-logo {
                --about-genome-medical-logo-margin-top: 32px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --card-padding-bottom: 80px;
        --card-padding-top: 64px;

        & .grid {
            grid-template-areas:
                "video video video video video title title title title title"
                "video video video video video description description description description description"
                "video video video video video link link link link link"
                "video video video video video logo logo logo logo logo";

            & .description {
                --paragraph-bottom-margin: 32px;
                --paragraph-top-margin: 32px;
            }

            & .genome-medical-logo {
                --about-genome-medical-logo-margin-top: 32px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "title title title title title title"
                "description description description description description description"
                "link link link link link link"
                "logo logo logo logo logo logo"
                "video video video video video video";
        }
    }
}

.schedule-appointment-footer {
    --card-background-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
    --card-margin-bottom: 0;
    --card-padding-bottom: 32px;
    --card-padding-top: 32px;

    & .grid {
        & > div {
            grid-column: 1 / -1;
            text-align: center;
        }
    }
}
