.icon {
    &.help {
        --icon-help: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 2a14 14 0 1014 14A14 14 0 0016 2zm0 26a12 12 0 1112-12 12 12 0 01-12 12z"/><circle cx="16" cy="23.5" r="1.5"/><path d="M17 8h-1.5a4.49 4.49 0 00-4.5 4.5v.5h2v-.5a2.5 2.5 0 012.5-2.5H17a2.5 2.5 0 010 5h-2v4.5h2V17a4.5 4.5 0 000-9z"/></svg>');

        &.before::before {
            mask: var(--icon-help) no-repeat;
        }

        &.after::after {
            mask: var(--icon-help) no-repeat;
        }
    }
}
