.dashboard {
    grid-template-areas:
        "content content"
        "footer footer";
    grid-template-rows: 1fr auto;

    & .content {
        --content-margin-top: 32px;

        grid-area: content;
        margin-top: var(--content-margin-top);

        & .cancel-link {
            --cancel-link-margin-bottom: 32px;

            margin-bottom: var(--cancel-link-margin-bottom);
            margin-left: -7px;
        }
    }

    & .kit-info {
        align-items: center;

        & .icon {
            --icon-size: 32px;

            &.before::before {
                --icon-before-margin: 16px;
            }
        }
    }

    & .graphic {
        display: none;
        grid-area: graphic;
        justify-self: center;
        width: 100%;

        & img {
            width: 100%;
        }
    }

    & .text-based-input {
        margin: 0;

        & form {
            grid-area: form;
        }
    }

    & .tracking-info {
        & .icon {
            --icon-size: 32px;

            &.before::before {
                --icon-before-margin: 16px;
            }
        }

        & a {
            margin-top: 16px;
        }

        & h3 {
            margin-bottom: 0;
        }

        & p {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    & .footer {
        --dashboard-footer-color: var(--minerva-dashboard-footer-color, rgb(189 189 189));
        --dashboard-footer-bottom-margin: var(--minerva-dashboard-footer-vertical-margin, 32px);
        --dashboard-footer-top-margin: var(--minerva-dashboard-footer-vertical-margin, 32px);

        grid-area: footer;
        margin-bottom: var(--dashboard-footer-bottom-margin);
        margin-top: var(--dashboard-footer-top-margin);

        & nav {
            align-items: center;
            color: var(--dashboard-footer-color);
            display: flex;
            justify-content: flex-start;
        }
    }

    &.withdrawn,
    &.maintenance {
        grid-template-areas:
            "content content"
            "graphic graphic"
            "footer footer";
        grid-template-rows: auto auto auto;

        & .graphic {
            display: inline;
        }
    }

    @media (--denim-breakpoint-mobile) {
        & {
            & .footer {
                --dashboard-footer-bottom-margin: 48px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            grid-template-areas:
                "content content content content content content graphic graphic graphic graphic graphic graphic"
                "footer footer footer footer footer footer footer footer footer footer footer footer";

            &.withdrawn,
            &.maintenance {
                grid-template-areas:
                    "content content content content content content graphic graphic graphic graphic graphic graphic"
                    "footer footer footer footer footer footer footer footer footer footer footer footer";
                grid-template-rows: 1fr auto;
            }

            & .content {
                /* We don't want margin as we are centering vertically via align-self... */
                --content-margin-top: 0;

                align-self: center;
            }

            & .graphic {
                align-self: center;
                display: inline;
            }

            & .text-based-input {
                display: grid;
                grid-column-gap: 32px;
                grid-template-areas: "form form form form . .";
                grid-template-columns: repeat(6, minmax(0, 1fr));
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            grid-template-areas:
                "content content content content content graphic graphic graphic graphic graphic"
                "footer footer footer footer footer footer footer footer footer footer";

            &.withdrawn,
            &.maintenance {
                grid-template-areas:
                    "content content content content content graphic graphic graphic graphic graphic"
                    "footer footer footer footer footer footer footer footer footer footer";
                grid-template-rows: 1fr auto;
            }

            & .content {
                /* We don't want margin as we are centering vertically via align-self... */
                --content-margin-top: 0;

                align-self: center;
            }

            & .graphic {
                align-self: center;
                display: inline;
            }

            & .text-based-input {
                display: grid;
                grid-column-gap: 32px;
                grid-template-areas: "form form form form .";
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            grid-template-areas:
                ". content content content content ."
                ". footer footer footer footer .";

            &.withdrawn,
            &.maintenance {
                grid-template-areas:
                    ". content content content content ."
                    ". graphic graphic graphic graphic ."
                    ". footer footer footer footer .";
            }
        }
    }
}
