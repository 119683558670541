.report-trait-navigation {
    --card-background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
    --card-margin-bottom: 0;

    & .grid {
        grid-template-areas:
            "title title"
            "traits traits";

        & .title {
            --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            --heading-text-align: center;

            grid-area: title;
        }

        & .traits {
            display: flex;
            flex-direction: column;
            grid-area: traits;
            justify-content: center;

            & .trait-card {
                --trait-card-flex-direction: row;
                --trait-card-justify-content: center;
                --trait-card-margin-bottom: 16px;

                cursor: pointer;

                & h3 {
                    --heading-margin: 0;
                    --heading-text-align: left;
                    --trait-card-heading-flex: none;
                    --trait-card-heading-width: min(100%, 248px);
                }

                & .image {
                    --trait-card-image-height: 48px;
                    --trait-card-image-margin-right: 16px;
                    --trait-card-image-width: 48px;
                }
            }

            & .trait-card:last-child {
                --trait-card-margin-bottom: 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .grid {
            grid-template-areas:
                "title title title title title title title title title title title title"
                "traits traits traits traits traits traits traits traits traits traits traits traits";

            & .traits {
                flex-direction: row;

                & .trait-card {
                    --trait-card-justify-content: left;
                    --trait-card-margin-bottom: 0;
                    --trait-card-margin-left: 16px;
                    --trait-card-margin-right: 16px;
                    --trait-card-margin-top: 0;
                    --trait-card-padding-bottom: 24px;
                    --trait-card-padding-left: 24px;
                    --trait-card-padding-right: 32px;
                    --trait-card-padding-top: 24px;
                    --trait-card-width: 391px;

                    & h3 {
                        --trait-card-heading-flex: 1 0;
                        --trait-card-heading-width: inherit;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            grid-template-areas:
                "title title title title title title title title title title"
                "traits traits traits traits traits traits traits traits traits traits";

            & .traits {
                flex-direction: row;

                & .trait-card {
                    --trait-card-justify-content: left;
                    --trait-card-margin-bottom: 0;
                    --trait-card-margin-left: 16px;
                    --trait-card-margin-right: 16px;
                    --trait-card-margin-top: 0;
                    --trait-card-padding-bottom: 24px;
                    --trait-card-padding-left: 24px;
                    --trait-card-padding-right: 32px;
                    --trait-card-padding-top: 24px;
                    --trait-card-width: 391px;

                    & h3 {
                        --trait-card-heading-flex: 1 0;
                        --trait-card-heading-width: inherit;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "title title title title title title"
                "traits traits traits traits traits traits";
        }
    }
}
