/* Responsive breakpoints */
@custom-media --denim-breakpoint-desktop screen and (width >= 1136px);
@custom-media --denim-breakpoint-tablet-landscape screen and (900px <= width <= 1135px);
@custom-media --denim-breakpoint-tablet-portrait screen and (600px <= width <= 899px);
@custom-media --denim-breakpoint-mobile screen and (width <= 599px);
@custom-media --denim-breakpoint-max-tablet-portrait screen and (width <= 899px);

/* Fonts */
@font-face {
    font-display: swap;
    font-family: SK-Modernist;
    font-style: normal;
    font-weight: 400;
    src:
        url("/fonts/sk-modernist-regular-webfont.woff2") format("woff2"),
        url("/fonts/sk-modernist-regular-webfont.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    src:
        url("/fonts/Inter-Regular.woff2?v=3.15") format("woff2"),
        url("/fonts/Inter-Regular.woff?v=3.15") format("woff");
}

@font-face {
    font-display: swap;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    src:
        url("/fonts/Inter-SemiBold.woff2?v=3.15") format("woff2"),
        url("/fonts/Inter-SemiBold.woff?v=3.15") format("woff");
}

@font-face {
    font-display: swap;
    font-family: "Inter var";
    font-named-instance: "Regular";
    font-style: normal;
    font-weight: 100 900;
    src: url("/fonts/Inter-roman.var.woff2?v=3.15") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Inter var";
    font-style: italic;
    font-weight: 100 900;
    src: url("/fonts/Inter-italic.var.woff2?v=3.15") format("woff2");
}

:root {
    /* The current Genomics Plc branding colour scheme                                                                */
    /* These should /only/ be used by the custom properties within this file.                                         */
    /* ************************************************************************************************************** */

    /* Primary colours… */
    --genomics-color-primary-navy: rgb(17 0 53);
    --genomics-color-primary-light-grey: rgb(239 239 239);
    --genomics-color-primary-light-green: rgb(51 255 196);
    --genomics-color-primary-bright-blue: rgb(13 94 255);

    /* Secondary colours… */
    --genomics-color-secondary-purple: rgb(124 37 223);
    --genomics-color-secondary-pink: rgb(252 65 131);
    --genomics-color-secondary-yellow: rgb(255 216 0);
    --genomics-color-secondary-orange: rgb(255 83 1);

    /* Shades of grey… */
    --genomics-color-shade-white: rgb(255 255 255);
    --genomics-color-shade-off-white: rgb(249 249 249);
    --genomics-color-shade-light-grey: rgb(239 239 239);
    --genomics-color-shade-mid-grey: rgb(189 189 189);
    --genomics-color-shade-dark-grey: rgb(100 100 100);
    --genomics-color-shade-black: rgb(29 29 29);

    /* Gradient… */
    --genomics-color-gradient-light-green-blue:
        linear-gradient(
            to right,
            var(--genomics-color-primary-light-green),
            var(--genomics-color-primary-bright-blue)
        );

    /* Additional Minerva colors… */
    /* **************************** */
    --minerva-color-light-navy: rgb(50 0 156);
    --minerva-color-dark-navy: rgb(1 0 11);
    --minerva-color-mm-blue: rgb(0 47 108);
    --minerva-color-error-on-white: rgb(224 34 101);
    --minerva-color-error-on-navy: rgb(252 65 131);
    --minerva-color-typical-risk-start: rgb(0 191 31);
    --minerva-color-typical-risk-end: rgb(150 216 15);
    --minerva-color-increased-risk-start: rgb(231 195 0);
    --minerva-color-increased-risk-end: rgb(245 127 1);

    /* Intents */
    --minerva-intent-danger: rgb(252 65 131);
    --minerva-intent-info: rgb(23 162 184);
    --minerva-intent-success: rgb(40 167 69);
    --minerva-intent-warning: rgb(255 193 7);

    /* Trait Colors */
    --minerva-cvd-us-dark: rgb(124 37 223);
    --minerva-cvd-us-light: rgb(191 162 255);
    --minerva-t2d-dark: rgb(18 120 164);
    --minerva-t2d-light: rgb(80 209 217);
    --minerva-bc-dark: rgb(252 65 131);
    --minerva-bc-light: rgb(255 179 205);
    --minerva-pc-dark: rgb(13 94 255);
    --minerva-pc-light: rgb(95 188 255);

    /* Denim Design System variables…                                                                                 */
    /* NOTE: These should be specified mobile /and/ dark mode first…                                                  */
    /* ************************************************************************************************************** */
    --denim-outline-color: color-mod(var(--genomics-color-shade-white) alpha(50%));

    /* Base background and text color */
    --denim-background-color: var(--genomics-color-primary-navy);
    --denim-color: var(--genomics-color-shade-off-white);
    --denim-dark-color: var(--genomics-color-shade-mid-grey);

    /* Typography: Generic font settings */
    --denim-font-family: Inter, sans-serif;
    --denim-font-family-variable: "Inter var", sans-serif;
    --denim-font-family-alternate: SK-Modernist, sans-serif;
    --denim-font-weight-normal: 400;
    --denim-font-weight-semibold: 600;

    /* Typography: Base font settings */
    --denim-font-size: 16px;
    --denim-font-weight: var(--denim-font-weight-normal);
    --denim-letter-spacing: 0;
    --denim-line-height: 22px;

    /* Typography: Headings */
    --denim-heading-color: var(--denim-color);
    --denim-heading-font-weight: var(--denim-font-weight-normal);
    --denim-heading-margin: 0 0 0.75em;
    --denim-heading-padding: 0;

    /* The grid */
    --denim-grid-columns: 2;
    --denim-grid-column-gap: 16px;
    --denim-grid-column-min-width: 0;
    --denim-grid-horizontal-margin: 16px;
    --denim-grid-max-width: 1072px;
    --denim-grid-row-gap: 0;

    /* Auto Complete Text Input */
    --denim-auto-complete-text-input-background-color: var(--genomics-color-primary-light-green);
    --denim-auto-complete-text-input-border-radius: 4px;
    --denim-auto-complete-text-input-color: var(--genomics-color-primary-navy);
    --denim-auto-complete-text-input-filter: brightness(0.9);
    --denim-auto-complete-text-input-list-item-margin: 0;
    --denim-auto-complete-text-input-list-item-padding: 16px;
    --denim-auto-complete-text-input-list-item-width: 100%;
    --denim-auto-complete-text-input-list-margin: 0;
    --denim-auto-complete-text-input-list-padding: 0;
    --denim-auto-complete-text-input-margin-bottom: 0;
    --denim-auto-complete-text-input-margin-top: 1px;
    --denim-auto-complete-text-input-overlay-position: absolute;
    --denim-auto-complete-text-input-overlay-width: 100%;
    --denim-auto-complete-text-input-position: relative;
    --denim-auto-complete-text-input-z-index: 1;

    /* Buttons */
    --denim-button-background-color: transparent;
    --denim-button-border-color: var(--genomics-color-shade-white);
    --denim-button-border-width: 1px;
    --denim-button-color: var(--genomics-color-shade-white);
    --denim-button-filter: brightness(0.9);
    --denim-button-icon-size: 1.1111em;
    --denim-button-justify-content: center;
    --denim-button-min-height: 44px;
    --denim-button-min-width: 100%;
    --denim-button-outline-color: var(--denim-outline-color);
    --denim-button-outline-offset: 3px;
    --denim-button-outline-style: solid;
    --denim-button-outline-width: 3px;
    --denim-button-padding-left: 16px;
    --denim-button-padding-right: 16px;
    --denim-button-text-decoration: underline;

    /* Cards */
    --denim-card-background-color: var(--genomics-color-primary-light-grey);
    --denim-card-color: var(--genomics-color-shade-black);
    --denim-card-padding-bottom: 32px;
    --denim-card-padding-left: 24px;
    --denim-card-padding-right: 24px;
    --denim-card-padding-top: 32px;

    /* Footer */
    --denim-footer-property: var(--genomics-color-primary-navy);

    /* Links */
    --denim-link-color: var(--genomics-color-shade-white);
    --denim-link-hover-text-decoration-line: underline;
    --denim-link-hover-text-decoration-style: solid;
    --denim-link-hover-text-underline-offset: 6px;
    --denim-link-icon-size: 1.4444em;
    --denim-link-text-decoration-line: underline;
    --denim-link-text-decoration-style: dotted;
    --denim-link-text-underline-offset: 3px;

    /* Nav */
    --denim-nav-align-items: center;
    --denim-nav-background-color: transparent;
    --denim-nav-display: flex;
    --denim-nav-img-size: 1.375em;
    --denim-nav-justify-content: space-between;
    --denim-nav-margin-left: 2em;
    --denim-nav-padding-vertical: 1.625em;
    --denim-nav-width: 100%;
    --denim-nav-height: 76px;

    /* Nav Modal */
    --denim-nav-content-footer-bottom: 16px;
    --denim-nav-content-footer-position: absolute;
    --denim-nav-footer-height: 100%;
    --denim-nav-footer-margin-bottom: 16px;
    --denim-nav-footer-position: relative;
    --denim-nav-menu-list-font-size: 22px;
    --denim-nav-menu-list-style-type: none;
    --denim-nav-menu-margin-top: 28px;
    --denim-nav-menu-padding-left: 0;
    --denim-nav-menu-padding-bottom: 32px;
    --denim-nav-mobile-icon-size: 32px;
    --denim-nav-modal-outline: none;
    --denim-nav-overlay-background-color: var(--denim-background-color);
    --denim-nav-overlay-vertical: 0;

    /* Nav Header */
    --denim-nav-header-position: fixed;
    --denim-nav-header-position-properties: 0;
    --denim-nav-header-display: flex;

    /* Icons */
    --denim-icon-background-color: var(--denim-color);
    --denim-icon-size: calc(var(--font-size) * 1.25);
    --denim-icon-before-margin: calc(var(--font-size) * 0.5);
    --denim-icon-after-margin: calc(var(--font-size) * 0.5);

    /* Intents */
    --denim-intent-danger: rgb(220 53 69);
    --denim-intent-info: rgb(23 162 184);
    --denim-intent-success: rgb(40 167 69);
    --denim-intent-warning: rgb(255 193 7);

    /* ProgressBarDesktop */
    --denim-pbd-background-color: var(--genomics-color-primary-navy);
    --denim-pbd-font-highlight-color: var(--genomics-color-shade-white);
    --denim-pbd-font-mid-color: var(--genomics-color-shade-mid-grey);
    --denim-pbd-mask-bottom: color-mod(var(--denim-pbd-background-color) alpha(50%));
    --denim-pbd-steps-color: var(--genomics-color-shade-off-white);

    /* ProgressBarGradient */
    --denim-pbg-gradient-start: var(--genomics-color-primary-light-green);
    --denim-pbg-gradient-end: var(--genomics-color-primary-bright-blue);
    --denim-pbg-background-color: var(--genomics-color-primary-navy);

    /* ProgressBarMobile */
    --denim-pbm-overlay-color: color-mod(var(--genomics-color-shade-black) alpha(20%));
    --denim-pbm-background-color: var(--minerva-color-light-navy);
    --denim-pbm-font-mid-color: var(--genomics-color-shade-mid-grey);
    --denim-pbm-font-highlight-color: var(--genomics-color-shade-white);
    --denim-pbm-steps-color: var(--genomics-color-shade-light-grey);
    --denim-pbm-steps-opacity: 0.6;
    --denim-pbm-progressbar-background-color: color-mod(var(--genomics-color-shade-white) alpha(20%));
    --denim-pbm-progressbar-foreground: var(--genomics-color-gradient-light-green-blue);
    --denim-pbm-bar-color: var(--genomics-color-primary-light-green);
    --denim-pbm-spacing: var(--denim-grid-column-gap);
    --denim-pbm-tick-color: var(--minerva-color-light-navy);

    /* Minerva specific variables…                                                                                    */
    /* ************************************************************************************************************** */
    --minerva-skeleton-transition-duration: 1s;
    --minerva-skeleton-border-radius: 0;
    --minerva-skeleton-color-start: var(--genomics-color-shade-dark-grey);
    --minerva-skeleton-color-end: var(--genomics-color-shade-light-grey);
}
