.alert-risk {
    --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-margin-bottom: 32px;
    --card-padding-bottom: 16px;
    --card-padding-left: 16px;
    --card-padding-right: 16px;
    --card-padding-top: 16px;

    align-items: flex-start;
    display: flex;
    flex-direction: row;

    & img {
        --alert-risk-img-height: 32px;
        --alert-risk-img-margin-right: 8px;
        --alert-risk-img-width: 32px;

        height: var(--alert-risk-img-height);
        margin-right: var(--alert-risk-img-margin-right);
        width: var(--alert-risk-img-width);
    }

    @media (--denim-breakpoint-desktop) {
        --card-margin-bottom: 48px;

        & img {
            --alert-risk-img-height: 48px;
            --alert-risk-img-margin-right: 12px;
            --alert-risk-img-width: 48px;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --card-margin-bottom: 48px;

        & img {
            --alert-risk-img-height: 48px;
            --alert-risk-img-margin-right: 12px;
            --alert-risk-img-width: 48px;
        }
    }
}
