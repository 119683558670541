.icon {
    &.caret--up {
        --icon-caret--up: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve"><polygon points="8,20 16,10 24,20 "/></svg>');

        &.before::before {
            mask: var(--icon-caret--up) no-repeat;
        }

        &.after::after {
            mask: var(--icon-caret--up) no-repeat;
        }
    }
}
