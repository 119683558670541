.lifestyle-changes {
    & :--heading {
        --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    }

    & .preamble {
        & .summary {
            align-items: flex-start;
            display: flex;

            & .icon {
                --icon-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                background: var(--genomics-color-shade-white, rgb(255 255  255));
                border-radius: 50%;
                margin-right: 10px;
                padding: 2px;
                vertical-align: middle;

                &.before::before {
                    margin-right: 0;
                }
            }
        }
    }

    & .content {
        --gap: 16px;

        display: flex;
        flex-flow: column wrap;
        gap: var(--gap);
        margin-bottom: 32px;

        & .card {
            --card-margin-bottom: 0;
            --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
            --card-padding-right: 16px;
            --card-padding-top: 24px;
            --card-padding-bottom: 32px;
            --denim-link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
            --denim-icon-background-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));

            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 16px;

            & .title {
                margin-bottom: 16px;
            }

            & p {
                --paragraph-bottom-margin: 24px;
                --paragraph-top-margin: 0;
            }

            & .lifestyle-icon {
                min-width: 48px;

                /* stylelint-disable-next-line selector-class-pattern */
                &.lifestyle-CVD_US {
                    background-image: linear-gradient(to bottom left, var(--minerva-cvd-us-light), var(--minerva-cvd-us-dark));
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.lifestyle-T2D {
                    background-image: linear-gradient(to bottom left, var(--minerva-t2d-light), var(--minerva-t2d-dark));
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.lifestyle-BC {
                    background-image: linear-gradient(to bottom left, var(--minerva-bc-light), var(--minerva-bc-dark));
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.lifestyle-PC {
                    background-image: linear-gradient(to bottom left, var(--minerva-pc-light), var(--minerva-pc-dark));
                }
            }

            & .link {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }

            & :--list {
                --list-bottom-margin: 16px;
                --list-top-margin: 0;
                --list-padding-left: 0;
                --denim-list-item-vertical-margin: 0;

                list-style: none;

                & .icon {
                    align-items: flex-start;

                    /* stylelint-disable-next-line selector-class-pattern */
                    &.caret--down {
                        --icon-background-color: var(--minerva-cvd-us-dark);

                        &.before::before {
                            background-image: linear-gradient(225deg, var(--minerva-cvd-us-light), var(--minerva-cvd-us-dark));
                        }
                    }

                    &.close {
                        --icon-background-color: var(--minerva-color-error-on-white);
                    }
                }
            }
        }
    }

    & .footnote {
        --denim-paragraph-vertical-margin: 0;

        display: flex;
        flex-direction: column;
        gap: 32px;

        & .take-care {
            text-align: center;

            & img {
                height: 80px;
                margin-bottom: 16px;
                width: 80px;
            }
        }

        & .is-caption {
            --paragraph-color: var(--genomics-color-shade-dark-grey);
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .content {
            flex-direction: row;

            & .card {
                --card-padding-top: 32px;
                --card-padding-bottom: 32px;

                width: calc(50% - var(--gap) / 2);
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .content {
            --gap: 32px;

            flex-direction: row;

            & .card {
                --card-padding-top: 32px;
                --card-padding-bottom: 32px;

                flex-direction: row;
                width: calc(50% - var(--gap) / 2);
            }
        }

        & .footnote {
            & .take-care {
                align-self: center;
                width: calc(50% + 16px);
            }

            & .is-caption {
                order: -1;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .content {
            --gap: 32px;

            flex-direction: row;

            & .card {
                --card-padding-left: 32px;
                --card-padding-right: 32px;
                --card-padding-top: 32px;
                --card-padding-bottom: 48px;

                flex-direction: row;
                width: calc(50% - var(--gap) / 2);
            }
        }

        & .footnote {
            & .take-care {
                align-self: center;
                width: calc(50% + 16px);
            }

            & .is-caption {
                order: -1;
            }
        }
    }
}
