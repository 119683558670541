.icon {
    &.chevron--down {
        --icon-chevron--down: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 22L6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z"/></svg>');

        &.before::before {
            mask: var(--icon-chevron--down) no-repeat;
        }

        &.after::after {
            mask: var(--icon-chevron--down) no-repeat;
        }
    }
}
