.icon {
    &.arrow--right {
        --icon-arrow--right: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M18 6l-1.43 1.393L24.15 15H4v2h20.15l-7.58 7.573L18 26l10-10L18 6z"/></svg>');

        &.before::before {
            mask: var(--icon-arrow--right) no-repeat;
        }

        &.after::after {
            mask: var(--icon-arrow--right) no-repeat;
        }
    }
}
