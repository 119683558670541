.progress-bar-desktop {
    --pbd-grid-gap: var(--denim-pbd-grid-gap, 32px);
    --pbd-background-color: var(--denim-pbd-background-color, black);
    --pbd-font-highlight-color: var(--denim-pbd-font-highlight-color, white);
    --pbd-font-mid-color: var(--denim-pbd-font-mid-color, lightgrey);
    --pbd-mask-bottom: var(--denim-pbd-mask-bottom, rgba(0 0 0 / 50%));
    --pbd-steps-color: var(--denim-pbd-steps-color, white);
    --pbd-steps-spacing: 6px;
    --pbd-animation-timing: ease-in-out;
    --pbd-animation-duration: 0.3s;

    display: grid;
    gap: var(--pbd-grid-gap);
    grid-template-areas: "text text line";
    grid-template-columns: 1fr 1fr 1fr;
    height: 100vh;
    min-height: 700px;
    overflow: hidden;
    position: sticky;
    top: 0;
    user-select: none;
    width: 100%;

    & > .mask {
        background:
            linear-gradient(
                180deg,
                var(--pbd-background-color) 0,
                rgba(0 0 0 / 0%) 150px,
                var(--pbd-mask-bottom) 100%
            );
        bottom: 0;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    & > .topics-text {
        grid-area: text;
        justify-self: right;
        position: relative;
        width: 100%;

        & > .topic-text {
            color: var(--pbd-font-highlight-color);
            position: absolute;
            right: 0;
            text-align: right;

            /*
            This makes the 'top' value point to the center of
            the text, which means it'll nicely align with the center
            or the circles that we draw.
            */
            transform: translateY(-50%);

            /* Animate the text to move when the positions are changed */
            transition: top var(--pbd-animation-duration) var(--pbd-animation-timing);

            /*
            Position the steps absolutely so that they don't
            factor into the centering above, and always exist a
            set distance below the topic text
            */
            & > .steps-text {
                color: var(--pbd-steps-color);
                position: absolute;
                right: 0;
                top: calc(100% + var(--pbd-steps-spacing));
            }

            &:not(.current) {
                color: var(--pbd-font-mid-color);
            }
        }
    }

    & > .topics-line {
        grid-area: line;
        justify-self: center;

        /* Configure the gradient animation to match the topic text animation */
        --denim-pbg-animation-duration: var(--pbd-animation-duration);
        --denim-pbg-animation-timing: var(--pbd-animation-timing);
    }
}
