.icon {
    &.mobile {
        --icon-mobile: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M22 4H10a2.002 2.002 0 00-2 2v22a2.002 2.002 0 002 2h12a2.003 2.003 0 002-2V6a2.002 2.002 0 00-2-2zm0 2v2H10V6zM10 28V10h12v18z"/></svg>');

        &.before::before {
            mask: var(--icon-mobile) no-repeat;
        }

        &.after::after {
            mask: var(--icon-mobile) no-repeat;
        }
    }
}
