.screening-prevention-steps {
    --card-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    --card-margin-bottom: 16px;
    --card-padding-bottom: 32px;
    --card-padding-left: 16px;
    --card-padding-right: 16px;
    --card-padding-top: 16px;

    width: 100%;

    & * {
        color: var(--genomics-color-shade-white, rgb(255 255 255));
    }

    & + .prevention {
        --card-background-color: var(--minerva-color-light-navy, rgb(50 0 156));
    }

    & .screening-prevention-steps-top {
        --top-margin-bottom: 24px;

        display: flex;
        flex-direction: row;
        margin-bottom: var(--top-margin-bottom);

        & img {
            --top-img-margin-right: 12px;

            height: 48px;
            margin-right: var(--top-img-margin-right);
            width: 48px;
        }

        & h4 {
            --heading-margin: 0 0 0;
        }
    }

    & p {
        --paragraph-bottom-margin: 24px;
        --paragraph-top-margin: 0;
    }

    & .screening-prevention-step-container {
        --step-container-margin-bottom: 24px;

        margin-bottom: var(--step-container-margin-bottom);

        & .screening-prevention-step {
            --step-margin-bottom: 24px;

            align-items: flex-start;
            display: flex;
            flex-direction: row;
            margin-bottom: var(--step-margin-bottom);

            & img {
                --step-img-margin-right: 8px;

                margin-right: var(--step-img-margin-right);
            }

            & .screening-prevention-step-description {
                display: flex;
                flex-direction: column;

                & p {
                    --paragraph-bottom-margin: 8px;
                    --paragraph-top-margin: 0;

                    &:last-child {
                        --paragraph-bottom-margin: 0;
                    }
                }
            }

            &:last-child {
                --step-margin-bottom: 0;
            }
        }

        &:last-child {
            --step-container-margin-bottom: 0;
        }
    }

    & .screening-prevention-links-container {
        display: flex;
        flex-direction: column;

        & .link {
            --link-margin-bottom: 16px;

            margin-bottom: var(--link-margin-bottom);

            &:last-child {
                --link-margin-bottom: 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        --card-padding-bottom: 48px;
        --card-padding-left: 32px;
        --card-padding-right: 32px;
        --card-padding-top: 32px;

        & + .prevention {
            margin-left: 32px;
        }

        & .screening-prevention-steps-top {
            --top-margin-bottom: 32px;

            & img {
                --top-img-margin-right: 16px;
            }
        }

        & .screening-prevention-step-container {
            --step-container-margin-bottom: 32px;

            & .screening-prevention-step {
                --step-margin-bottom: 32px;

                & img {
                    --step-img-margin-right: 16px;
                }
            }

            &.horizontal {
                column-gap: 32px;
                display: grid;
                grid-auto-columns: 1fr;
                grid-auto-flow: column;

                & .screening-prevention-step {
                    --step-margin-bottom: 0;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --card-padding-bottom: 48px;
        --card-padding-left: 32px;
        --card-padding-right: 32px;
        --card-padding-top: 32px;

        & + .prevention {
            margin-left: 32px;
        }

        & .screening-prevention-steps-top {
            --top-margin-bottom: 32px;

            & img {
                --top-img-margin-right: 16px;
            }
        }

        & .screening-prevention-step-container {
            --step-container-margin-bottom: 32px;

            & .screening-prevention-step {
                --step-margin-bottom: 32px;

                & img {
                    --step-img-margin-right: 16px;
                }
            }

            &.horizontal {
                column-gap: 32px;
                display: grid;
                grid-auto-columns: 1fr;
                grid-auto-flow: column;

                & .screening-prevention-step {
                    --step-margin-bottom: 0;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        --card-padding-left: 32px;
        --card-padding-right: 32px;
        --card-padding-top: 32px;
    }
}
