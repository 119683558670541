.our-science {
    --our-science-border-radius: 4px;

    & .page-title-container {
        --page-title-background-position: top center;

        background-image: url("/images/our-science-background.png");
        background-position: var(--page-title-background-position);
        background-repeat: no-repeat;
        background-size: cover;

        & .card {
            --card-background-color: transparent;
        }
    }

    & .circular-icon {
        --circular-icon-background-color: transparent;
        --circular-icon-height: 48px;
        --circular-icon-margin: 0 16px 0 0;
        --circular-icon-width: 48px;

        align-items: center;
        background-color: var(--circular-icon-background-color);
        border-radius: 50%;
        display: inline-flex;
        height: var(--circular-icon-height);
        justify-content: center;
        margin: var(--circular-icon-margin);
        width: var(--circular-icon-width);

        & > img {
            --circular-icon-img-height: 32px;
            --circular-icon-img-width: 32px;

            height: var(--circular-icon-img-height);
            width: var(--circular-icon-img-width);
        }

        &.dna {
            --circular-icon-background-color: var(--genomics-color-primary-light-green, rgb(51 255 196));

            &.white-bg {
                --circular-icon-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
                --circular-icon-margin-right: 16px;
            }
        }

        &.people {
            --circular-icon-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
            --circular-icon-margin-right: 16px;
        }

        &.heart {
            --circular-icon-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
            --circular-icon-margin-right: 16px;
        }
    }

    & .vertical-joint {
        --vertical-joint-height: 24px;
        --vertical-joint-background-repeat: no-repeat;

        background-image: url("/images/our-science-vertical-joint.svg");
        background-position: center center;
        background-repeat: var(--vertical-joint-background-repeat);
        height: var(--vertical-joint-height);

        &.longer {
            --longer-vertical-joint-display: none;
            --vertical-joint-background-repeat: repeat-y;
            --vertical-joint-height: 96px;

            display: var(--longer-vertical-joint-display);
        }
    }

    & .left-to-right-vertical-joint {
        --left-to-right-vertical-joint-display: none;
        --left-to-right-vertical-joint-height: 121px;

        background-image: url("/images/our-science-left-to-right-vertical-joint.svg");
        background-position: center center;
        background-repeat: no-repeat;
        display: var(--left-to-right-vertical-joint-display);
        grid-column: 1 / -1;
        height: var(--left-to-right-vertical-joint-height);
    }

    & .info-box {
        --info-box-align-items: flex-start;
        --info-box-background-color: transparent;
        --info-box-border-radius: var(--our-science-border-radius, 4px);
        --info-box-flex-flow: row nowrap;
        --info-box-justify-content: flex-start;
        --info-box-margin: 0;
        --info-box-padding: 16px;

        align-items: var(--info-box-align-items);
        background-color: var(--info-box-background-color);
        border-radius: var(--info-box-border-radius);
        display: flex;
        flex-flow: var(--info-box-flex-flow);
        justify-content: var(--info-box-justify-content);
        margin: var(--info-box-margin);
        padding: var(--info-box-padding);

        &.vertical-to-horizontal {
            --info-box-align-items: flex-start;
            --info-box-flex-flow: column nowrap;
            --info-box-justify-content: center;
        }

        &.grey {
            --card-text-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            --info-box-background-color: var(--genomics-color-shade-light-grey, rgb(239 239 239));
        }

        &.navy {
            --card-text-color: var(--genomics-color-shade-white, rgb(255 255 255));
            --info-box-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        }
    }

    & .vertical-info-container {
        --vertical-info-container-align-items: flex-start;
        --vertical-info-container-border: none;
        --vertical-info-container-flex-wrap: nowrap;
        --vertical-info-container-justify-content: center;
        --vertical-info-container-margin: 0;
        --vertical-info-container-padding: 0;

        align-items: var(--vertical-info-container-align-items);
        border: var(--vertical-info-container-border);
        display: flex;
        flex-flow: column var(--vertical-info-container-flex-wrap);
        justify-content: var(--vertical-info-container-justify-content);
        margin: var(--vertical-info-container-margin);
        padding: var(--vertical-info-container-padding);
    }

    & .dna-infographic {
        --dna-infographic-margin-top: 32px;

        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        margin-top: var(--dna-infographic-margin-top);

        &:first-child {
            margin-bottom: 16px;
        }
    }

    & .step {
        --step-margin: 0 0 8px 0;

        align-items: flex-start;
        background-image: url("/images/our-science-step-joint.svg");
        background-position: 12px 0;
        background-repeat: repeat-y;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        margin: var(--step-margin);

        & .step-indicator {
            background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            margin-right: 7px;
            padding: 1px;
        }

        & .step-number {
            --step-number-margin: 0 0 8px 0;

            align-items: center;
            background-color: var(--genomics-color-shade-white, rgb(255 255 255));
            border-radius: 50%;
            color: var(--genomics-color-primary-navy, rgb(17 0 53));
            display: flex;
            height: 23px;
            justify-content: center;
            margin: var(--step-number-margin);
            width: 23px;
        }

        & .step-description {
            --step-description-padding: 0 0 var(--line-height) 0;

            padding: var(--step-description-padding);
        }

        &:last-of-type {
            --step-margin: 0;

            background-image: none;
        }
    }

    & .common-diseases {
        --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
        --card-margin-bottom: 0;
        --card-text-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        --card-vertical-padding: 32px;

        & :--heading {
            --heading-color: var(--card-text-color);
        }

        & .section-title {
            --heading-margin: 0;
        }

        & .section-sub-title {
            --heading-margin: 32px 0;
        }

        & p {
            --paragraph-color: var(--card-text-color);
            --paragraph-bottom-margin: 0;
            --paragraph-top-margin: 0;
        }

        & .icon {
            --icon-background-color: var(--card-text-color);
            --icon-size: 24px;
        }

        & .paragraph-with-icon {
            align-items: flex-start;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;

            & .icon {
                --denim-icon-before-margin: 16px;
            }
        }

        & .grid {
            grid-template-areas:
                "firstSectionText firstSectionText"
                "firstSectionImage firstSectionImage"
                "yourGenes yourGenes"
                "yourDNA yourDNA"
                ". ."
                "moreAboutDNA moreAboutDNA"
                "variationsInDNA variationsInDNA"
                "geneticsOfCommonDisease geneticsOfCommonDisease"
                "polygenicRiskScores polygenicRiskScores"
                "dnaCollected dnaCollected"
                "thingsToConsider thingsToConsider"
                "aboutGenomicsImage aboutGenomicsImage"
                "aboutGenomicsText aboutGenomicsText";

            & .first-section-text {
                align-self: center;
                grid-area: firstSectionText;
            }

            & .first-section-image {
                --first-section-image-margin-top: 32px;

                grid-area: firstSectionImage;
                margin-top: var(--first-section-image-margin-top);

                & > img {
                    border-radius: var(--our-science-border-radius, 4px);
                    width: 100%;
                }
            }

            & .your-genes-and-dna {
                --your-genes-and-dna-margin-top: 16px;

                grid-area: yourGenes;
                margin-top: var(--your-genes-and-dna-margin-top);
            }

            & .your-genes {
                --info-box-align-items: normal;
                --info-box-flex-flow: column nowrap;
                --info-box-justify-content: center;
                --your-genes-text-align: center;

                text-align: var(--your-genes-text-align);

                & .icon-and-title {
                    --icon-and-title-align-items: center;
                    --icon-and-title-flex-direction: column;
                    --icon-and-title-justify-content: center;

                    align-items: var(--icon-and-title-align-items);
                    display: flex;
                    flex-flow: var(--icon-and-title-flex-direction) nowrap;
                    justify-content: var(--icon-and-title-justify-content);
                }

                & .circular-icon {
                    --circular-icon-margin: 0 0 8px 0;
                }
            }

            & .your-dna {
                grid-area: yourDNA;
            }

            & .variations-in-dna {
                grid-area: variationsInDNA;
            }

            & .more-about-dna {
                --more-about-dna-margin-top: 32px;

                grid-area: moreAboutDNA;
                margin-top: var(--more-about-dna-margin-top);
            }

            & .genetics-of-common-disease {
                --genetics-of-common-disease-margin: 48px 0 0 0;

                grid-area: geneticsOfCommonDisease;
                margin: var(--genetics-of-common-disease-margin);

                & .vertical-info-container {
                    /* info-box has 16px padding, so only need half Figma designs worth of 32px margin... */
                    --vertical-info-container-margin: 16px 0;
                    --vertical-info-container-width: inherit;

                    flex-grow: 1;
                    width: var(--vertical-info-container-width);

                    &.environment {
                        --vertical-info-container-border: 1px solid var(--genomics-color-primary-light-green, rgb(51 255 196));
                        --vertical-info-container-padding: 24px 16px;

                        border-radius: var(--our-science-border-radius, 4px);
                    }
                }

                & .paragraph-with-icon {
                    --paragraph-with-icon-margin: 0 0 16px 0;

                    margin: var(--paragraph-with-icon-margin);

                    & .running-man-icon {
                        --running-man-icon-margin-right: 16px;

                        margin-right: var(--running-man-icon-margin-right);
                    }
                }
            }

            & .polygenic-risk-scores {
                --genetics-of-common-disease-padding: 0 0 32px 0;

                grid-area: polygenicRiskScores;
                padding: var(--genetics-of-common-disease-padding);

                & .content {
                    --genetics-of-common-disease-content-margin: 48px 0 0 0;

                    margin: var(--genetics-of-common-disease-content-margin);
                    text-align: center;
                }

                & .title {
                    --polygenic-risk-scores-title-margin: 0 0 32px 0;

                    margin: var(--polygenic-risk-scores-title-margin);
                }

                & .description {
                    --paragraph-bottom-margin: 32px;
                }
            }

            & .dna-collected {
                --info-box-align-items: flex-start;
                --info-box-border-radius: 0;
                --info-box-flex-flow: column nowrap;
                --info-box-justify-content: center;
                --info-box-margin: 0 -16px;

                grid-area: dnaCollected;

                & .title {
                    --dna-collected-title-margin: 0 0 32px 0;

                    margin: var(--dna-collected-title-margin);
                }

                & .content {
                    --dna-collected-content-align-items: center;
                    --dna-collected-content-flex-direction: column;
                    --dna-collected-content-justify-content: center;

                    align-items: var(--dna-collected-content-align-items);
                    display: flex;
                    flex-flow: var(--dna-collected-content-flex-direction) nowrap;
                    justify-content: var(--dna-collected-content-justify-content);
                }

                & .step-key {
                    --step-key-margin: 48px 0 0 0;

                    margin: var(--step-key-margin);
                }
            }

            & .things-to-consider {
                grid-area: thingsToConsider;

                & .vertical-joint {
                    --things-to-consider-vertical-joint-display: none;

                    display: var(--things-to-consider-vertical-joint-display);
                }

                & .content {
                    --info-box-align-items: flex-start;
                    --info-box-flex-flow: column nowrap;
                    --info-box-justify-content: center;
                    --info-box-margin: 32px 0;
                    --info-box-padding: 32px 32px 32px 16px;
                }

                & .points {
                    --things-to-consider-points-align-items: flex-start;
                    --things-to-consider-points-flex-flow: column nowrap;
                    --things-to-consider-points-justify-content: flex-start;
                    --things-to-consider-points-margin: 0;
                    --things-to-consider-points-padding: 0;

                    align-items: var(--things-to-consider-points-align-items);
                    display: flex;
                    flex-flow: var(--things-to-consider-points-flex-flow);
                    justify-content: var(--things-to-consider-points-justify-content);
                    margin: var(--things-to-consider-points-margin);
                    padding: var(--things-to-consider-points-padding);

                    & .point {
                        --things-to-consider-point-margin: 0 0 16px 0;

                        margin: var(--things-to-consider-point-margin);

                        & .icon {
                            --denim-icon-before-margin: 12px;
                        }
                    }
                }
            }

            & .about-genomics-image {
                --about-genomics-image-margin: 0;

                grid-area: aboutGenomicsImage;
                margin: var(--about-genomics-image-margin);

                & > img {
                    width: 100%;
                }
            }

            & .about-genomics-text {
                --about-genomics-text-margin: 24px 0 0 0;

                grid-area: aboutGenomicsText;
                margin: var(--about-genomics-text-margin);

                & p {
                    --paragraph-bottom-margin: 24px;

                    &:last-of-type {
                        --paragraph-bottom-margin: 0;
                    }
                }

                & a {
                    --link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
                }
            }
        }
    }

    & .scientific-team {
        --card-vertical-padding: 32px;

        & .grid {
            grid-template-areas:
                "title title"
                "subTitle subTitle"
                "teamMembers teamMembers"
                "quotes quotes";
        }

        & .section-title {
            --heading-margin: 0;

            grid-area: title;
            text-align: center;
        }

        & .section-sub-title {
            --heading-margin: 16px 16px 48px 16px;

            grid-area: subTitle;
            text-align: center;
        }

        & .team-members {
            --scientific-team-members-align-items: center;
            --scientific-team-members-flex-flow: column nowrap;
            --scientific-team-members-justify-content: center;

            align-items: var(--scientific-team-members-align-items);
            display: flex;
            flex-flow: var(--scientific-team-members-flex-flow);
            grid-area: teamMembers;
            justify-content: var(--scientific-team-members-justify-content);
        }

        & .team-member {
            --scientific-team-member-margin: 0 0 32px 0;

            align-items: center;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            margin: var(--scientific-team-member-margin);

            & .name {
                margin: 8px 0;
                text-align: center;
            }

            &:last-of-type {
                --scientific-team-member-margin: 0;
            }
        }

        & .quotes {
            --scientific-team-quotes-margin: 64px 0 0;

            align-items: center;
            display: flex;
            flex-flow: column nowrap;
            grid-area: quotes;
            justify-content: flex-start;
            margin: var(--scientific-team-quotes-margin);

            & .quote {
                text-align: center;
            }

            & blockquote {
                font-style: italic;
                margin: 8px 0;
            }

            & .divider {
                --divider-width: 64px;

                background-color: var(--genomics-color-primary-light-green, rgb(51 255 196));
                border: none;
                height: 1px;
            }

            & .wordmark {
                --scientific-team-wordmark-height: 26px;

                height: var(--scientific-team-wordmark-height);
                margin: 48px 0 0;
            }
        }
    }

    & .publications {
        --card-background-color: var(--genomics-color-shade-light-grey, rgb(239 239 239));
        --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        --card-vertical-padding: 32px;

        & :--heading {
            --heading-color: var(--card-color);
        }

        & :--list {
            --list-bottom-margin: 0;
            --list-top-margin: 0;
            --list-padding-left: 0;

            list-style: none;
        }

        & .external-link {
            --link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
        }

        & .grid {
            grid-template-areas:
                "title title"
                "description description"
                "peerReviwed peerReviwed"
                "blogs blogs"
                "disambiguation disambiguation";
        }

        & .section-title {
            --heading-margin: 0;

            grid-area: title;
        }

        & .section-description {
            --paragraph-bottom-margin: 0;
            --paragraph-color: var(--card-text-color);
            --paragraph-top-margin: 16px;

            grid-area: description;
        }

        & .peer-reviewed {
            grid-area: peerReviwed;
        }

        & .blogs {
            grid-area: blogs;
        }

        & .peer-reviewed-heading,
        & .blogs-heading {
            --heading-margin: 48px 0 16px 0;
        }

        & .article,
        & .external-link {
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        & .disambiguation {
            --paragraph-bottom-margin: 0;
            --paragraph-top-margin: 48px;

            grid-area: disambiguation;
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .page-title-container {
                --page-title-background-position: top right;
            }

            & .page-title {
                grid-column: span 6;
            }

            & .vertical-joint {
                &.longer {
                    --longer-vertical-joint-display: block;
                }
            }

            & .left-to-right-vertical-joint {
                --left-to-right-vertical-joint-display: block;
            }

            & .info-box {
                --info-box-padding: 24px;

                &.vertical-to-horizontal {
                    --info-box-align-items: flex-start;
                    --info-box-flex-flow: row nowrap;
                    --info-box-justify-content: flex-start;
                }
            }

            & .dna-infographic {
                --dna-infographic-margin-top: 48px;
            }

            & .common-diseases {
                --card-vertical-padding: 48px;

                & .grid {
                    grid-template-areas:
                        "firstSectionText firstSectionText firstSectionText firstSectionText firstSectionText . firstSectionImage firstSectionImage firstSectionImage firstSectionImage firstSectionImage firstSectionImage"
                        "yourGenes yourGenes yourGenes yourGenes yourGenes yourGenes yourDNA yourDNA yourDNA yourDNA yourDNA yourDNA"
                        ". . . . . . . . . . . ."
                        "variationsInDNA variationsInDNA variationsInDNA variationsInDNA variationsInDNA variationsInDNA moreAboutDNA moreAboutDNA moreAboutDNA moreAboutDNA moreAboutDNA moreAboutDNA"
                        "geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease"
                        "polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores"
                        "dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected"
                        "thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider"
                        "aboutGenomicsText aboutGenomicsText aboutGenomicsText aboutGenomicsText aboutGenomicsText . aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage";

                    & .first-section-image {
                        --first-section-image-margin-top: 0;
                    }

                    & .your-genes-and-dna {
                        --your-genes-and-dna-margin-top: 48px;
                    }

                    & .your-genes {
                        --your-genes-text-align: inherit;

                        & .icon-and-title {
                            --icon-and-title-align-items: center;
                            --icon-and-title-flex-direction: row;
                            --icon-and-title-justify-content: flex-start;

                            margin-bottom: 24px;

                            & :--heading {
                                --heading-margin: 0;
                            }
                        }

                        & .circular-icon {
                            --circular-icon-margin: 0 16px 0 0;
                        }
                    }

                    & .more-about-dna {
                        --more-about-dna-margin-top: 0;
                    }

                    & .variations-in-dna {
                        --dna-infographic-margin-top: 0;

                        align-self: start;
                    }

                    & .genetics-of-common-disease {
                        --genetics-of-common-disease-margin: 0;

                        & .vertical-info-container {
                            /* info-box has 24px padding, so only need half of the Figma designs worth of 48px
                               margin... */
                            --vertical-info-container-margin: 24px;
                            --vertical-info-container-width: 50%;

                            &.environment {
                                --vertical-info-container-margin: 24px;
                            }
                        }
                    }

                    & .polygenic-risk-scores {
                        --genetics-of-common-disease-padding: 0 0 48px 0;

                        background-image: url("/images/our-science-big-left-to-right-vertical-joint.svg");
                        background-position: bottom center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        display: flex;
                        justify-content: center;

                        & .content {
                            width: 50%;
                        }
                    }

                    & .dna-collected {
                        --info-box-border-radius: var(--our-science-border-radius, 4px);
                        --info-box-margin: 0;
                        --info-box-padding: 48px;

                        & .title {
                            --dna-collected-title-margin: 0 0 48px 0;
                        }

                        & .content {
                            --dna-collected-content-align-items: center;
                            --dna-collected-content-flex-direction: row;
                            --dna-collected-content-justify-content: flex-start;

                            & .step-key {
                                --step-key-margin: 0 0 0 64px;
                            }
                        }
                    }

                    & .things-to-consider {
                        & .vertical-joint {
                            --vertical-joint-background-repeat: repeat-y;
                            --vertical-joint-height: 48px;
                            --things-to-consider-vertical-joint-display: block;
                        }

                        & .content {
                            --info-box-margin: 0 0 64px 0;
                            --info-box-padding: 48px;
                        }

                        & .title {
                            --heading-margin: 0 0 32px 0;
                        }

                        & .points {
                            --things-to-consider-points-flex-flow: row nowrap;

                            & .left-column {
                                flex-grow: 1;
                                margin-right: 32px;
                                width: 50%;
                            }

                            & .right-column {
                                flex-grow: 1;
                                margin-left: 32px;
                                width: 50%;
                            }

                            & .point {
                                --things-to-consider-point-margin: 0 0 32px 0;

                                &:last-of-type {
                                    --things-to-consider-point-margin: 0;
                                }
                            }
                        }
                    }

                    & .about-genomics-image {
                        --about-genomics-image-margin: 0;
                    }

                    & .about-genomics-text {
                        --about-genomics-text-margin: 0;
                    }
                }
            }

            & .scientific-team {
                --card-vertical-padding: 48px;

                & .grid {
                    grid-template-areas:
                        ". . . title title title title title title . . ."
                        ". . . subTitle subTitle subTitle subTitle subTitle subTitle . . ."
                        "teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers"
                        ". . . quotes quotes quotes quotes quotes quotes . . .";

                    & .team-members {
                        --scientific-team-members-align-items: center;
                        --scientific-team-members-flex-flow: row nowrap;
                        --scientific-team-members-justify-content: space-between;

                        & .team-member {
                            --scientific-team-member-margin: 0;

                            width: 20%;
                        }
                    }
                }
            }

            & .publications {
                --card-vertical-padding: 48px;

                & .grid {
                    grid-template-areas:
                        "title title title title title title title title title title title title"
                        "description description description description description description description description description description description description"
                        "peerReviwed peerReviwed peerReviwed peerReviwed peerReviwed peerReviwed blogs blogs blogs blogs blogs blogs"
                        "disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation";

                    & .disambiguation {
                        --paragraph-bottom-margin: 16px;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            & .page-title-container {
                --page-title-background-position: top right;
            }

            & .page-title {
                grid-column: span 6;
            }

            & .vertical-joint {
                flex-grow: 3;

                &.longer {
                    --longer-vertical-joint-display: block;
                }
            }

            & .left-to-right-vertical-joint {
                --left-to-right-vertical-joint-display: block;
                --left-to-right-vertical-joint-height: 95px;

                background-size: 52%;
            }

            & .info-box {
                --info-box-padding: 24px;

                &.vertical-to-horizontal {
                    --info-box-align-items: flex-start;
                    --info-box-flex-flow: row nowrap;
                    --info-box-justify-content: flex-start;
                }
            }

            & .dna-infographic {
                --dna-infographic-margin-top: 48px;
            }

            & .common-diseases {
                --card-vertical-padding: 48px;

                & .grid {
                    grid-template-areas:
                        "firstSectionText firstSectionText firstSectionText firstSectionText firstSectionText firstSectionImage firstSectionImage firstSectionImage firstSectionImage firstSectionImage"
                        "yourGenes yourGenes yourGenes yourGenes yourGenes yourDNA yourDNA yourDNA yourDNA yourDNA"
                        ". . . . . . . . . ."
                        "variationsInDNA variationsInDNA variationsInDNA variationsInDNA variationsInDNA moreAboutDNA moreAboutDNA moreAboutDNA moreAboutDNA moreAboutDNA"
                        "geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease"
                        "polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores"
                        "dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected dnaCollected"
                        "thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider thingsToConsider"
                        "aboutGenomicsText aboutGenomicsText aboutGenomicsText aboutGenomicsText aboutGenomicsText aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage";

                    & .first-section-image {
                        --first-section-image-margin-top: 0;
                    }

                    & .your-genes-and-dna {
                        --your-genes-and-dna-margin-top: 48px;

                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    & .your-genes {
                        --your-genes-text-align: inherit;

                        & .icon-and-title {
                            --icon-and-title-align-items: center;
                            --icon-and-title-flex-direction: row;
                            --icon-and-title-justify-content: flex-start;

                            margin-bottom: 24px;

                            & :--heading {
                                --heading-margin: 0;
                            }
                        }

                        & .circular-icon {
                            --circular-icon-margin: 0 16px 0 0;
                        }
                    }

                    & .more-about-dna {
                        --more-about-dna-margin-top: 0;
                    }

                    & .variations-in-dna {
                        --dna-infographic-margin-top: 0;

                        align-self: start;
                    }

                    & .genetics-of-common-disease {
                        --genetics-of-common-disease-margin: 0;

                        & .vertical-info-container {
                            /* info-box has 24px padding, so only need half of the Figma designs worth of 48px
                               margin... */
                            --vertical-info-container-margin: 24px;
                            --vertical-info-container-width: 50%;

                            &.environment {
                                --vertical-info-container-margin: 24px;
                            }
                        }
                    }

                    & .polygenic-risk-scores {
                        --genetics-of-common-disease-padding: 0 0 48px 0;

                        background-image: url("/images/our-science-big-left-to-right-vertical-joint.svg");
                        background-position: bottom center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        display: flex;
                        justify-content: center;

                        & .content {
                            width: 50%;
                        }
                    }

                    & .dna-collected {
                        --info-box-border-radius: var(--our-science-border-radius, 4px);
                        --info-box-margin: 0;

                        & .title {
                            --dna-collected-title-margin: 0 0 48px 0;
                        }

                        & .content {
                            --dna-collected-content-align-items: center;
                            --dna-collected-content-flex-direction: row;
                            --dna-collected-content-justify-content: flex-start;

                            & .step-key {
                                --step-key-margin: 0 0 0 64px;
                            }
                        }
                    }

                    & .things-to-consider {
                        & .vertical-joint {
                            --vertical-joint-background-repeat: repeat-y;
                            --vertical-joint-height: 48px;
                            --things-to-consider-vertical-joint-display: block;
                        }

                        & .content {
                            --info-box-margin: 0 0 64px 0;
                            --info-box-padding: 48px;
                        }

                        & .title {
                            --heading-margin: 0 0 32px 0;
                        }

                        & .points {
                            --things-to-consider-points-flex-flow: row nowrap;

                            & .left-column {
                                margin-right: 32px;
                            }

                            & .right-column {
                                margin-left: 32px;
                            }

                            & .point {
                                --things-to-consider-point-margin: 0 0 32px 0;

                                &:last-of-type {
                                    --things-to-consider-point-margin: 0;
                                }
                            }
                        }
                    }

                    & .about-genomics-image {
                        --about-genomics-image-margin: 0;
                    }

                    & .about-genomics-text {
                        --about-genomics-text-margin: 0;
                    }
                }
            }

            & .scientific-team {
                --card-vertical-padding: 48px;

                & .grid {
                    grid-template-areas:
                        ". . title title title title title title . ."
                        ". . subTitle subTitle subTitle subTitle subTitle subTitle . ."
                        "teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers teamMembers"
                        ". . quotes quotes quotes quotes quotes quotes . .";

                    & .team-members {
                        --scientific-team-members-align-items: center;
                        --scientific-team-members-flex-flow: row nowrap;
                        --scientific-team-members-justify-content: space-between;

                        & .team-member {
                            --scientific-team-member-margin: 0;

                            width: 20%;
                        }
                    }
                }
            }

            & .publications {
                --card-vertical-padding: 48px;

                & .grid {
                    grid-template-areas:
                        "title title title title title title title title title title"
                        "description description description description description description description description description description"
                        "peerReviwed peerReviwed peerReviwed peerReviwed peerReviwed blogs blogs blogs blogs blogs"
                        "disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation disambiguation";

                    & .disambiguation {
                        --paragraph-bottom-margin: 16px;
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .page-title {
                grid-column: 2 / 6;
            }

            & .common-diseases {
                & .grid {
                    grid-template-areas:
                        ". firstSectionText firstSectionText firstSectionText firstSectionText ."
                        ". firstSectionImage firstSectionImage firstSectionImage firstSectionImage ."
                        ". yourGenes yourGenes yourGenes yourGenes ."
                        ". yourDNA yourDNA yourDNA yourDNA ."
                        ". . . . . ."
                        ". moreAboutDNA moreAboutDNA moreAboutDNA moreAboutDNA ."
                        ". variationsInDNA variationsInDNA variationsInDNA variationsInDNA ."
                        ". geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease geneticsOfCommonDisease ."
                        ". polygenicRiskScores polygenicRiskScores polygenicRiskScores polygenicRiskScores ."
                        ". dnaCollected dnaCollected dnaCollected dnaCollected ."
                        ". thingsToConsider thingsToConsider thingsToConsider thingsToConsider ."
                        ". aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage aboutGenomicsImage ."
                        ". aboutGenomicsText aboutGenomicsText aboutGenomicsText aboutGenomicsText .";

                    & .dna-collected {
                        --info-box-border-radius: var(--our-science-border-radius, 4px);
                        --info-box-margin: 0;
                    }

                    & .things-to-consider {
                        & .vertical-joint {
                            --things-to-consider-vertical-joint-display: block;
                        }

                        & .content {
                            --info-box-margin: 0 0 32px 0;
                        }
                    }
                }
            }

            & .scientific-team {
                & .grid {
                    grid-template-areas:
                        ". title title title title ."
                        ". subTitle subTitle subTitle subTitle ."
                        ". teamMembers teamMembers teamMembers teamMembers ."
                        ". quotes quotes quotes quotes .";
                }
            }

            & .publications {
                & .grid {
                    grid-template-areas:
                        ". title title title title ."
                        ". description description description description ."
                        ". peerReviwed peerReviwed peerReviwed peerReviwed ."
                        ". blogs blogs blogs blogs ."
                        ". disambiguation disambiguation disambiguation disambiguation .";
                }
            }
        }
    }
}
