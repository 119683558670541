.clinical-action-plan-container {
    --card-background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
    --card-margin-bottom: 0;

    & > .grid {
        grid-template-areas:
            "capinfo capinfo"
            "gminfo gminfo"
            "cap cap";

        & .clinical-action-plan-info {
            grid-area: capinfo;
            scroll-margin-top: calc(var(--nav-height) + var(--subnav-height) + var(--card-padding-top));

            & * {
                color: var(--genomics-color-primary-navy, rgb(17 0 53));
            }
        }

        & .genome-medical-info {
            --genome-medical-info-margin-bottom: 48px;

            align-items: center;
            display: flex;
            flex-direction: column;
            grid-area: gminfo;
            margin: auto;
            margin-bottom: var(--genome-medical-info-margin-bottom);

            & * {
                color: var(--genomics-color-primary-navy, rgb(17 0 53));
            }

            & .divider {
                --divider-vertical-margin: 18px;
                --divider-width: 100%;

                border: 1px;
                border-color: var(--genomics-color-shade-mid-grey, rgb(189 189 189));
                border-top-style: solid;
                margin: 18px 0;
            }

            & .genome-medical-logo {
                margin: 10px 24px 0;
            }

            & .link {
                color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));

                & span {
                    color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
                }
            }
        }

        & .clinical-action-plan {
            --clinical-action-plan-border-color: rgb(13 94 255);
            --clinical-action-plan-padding-bottom: 32px;
            --clinical-action-plan-padding-horizontal: 16px;
            --clinical-action-plan-padding-top: 24px;

            border: 1px solid var(--clinical-action-plan-border-color);
            border-radius: 4px;
            grid-area: cap;
            padding: var(--clinical-action-plan-padding-top) var(--clinical-action-plan-padding-horizontal) var(--clinical-action-plan-padding-bottom);

            & > * {
                color: var(--genomics-color-primary-navy, rgb(17 0 53));
            }

            & h2 {
                --heading-margin: 0 0 32px;
            }

            &.high-risk {
                --clinical-action-plan-border-color: rgb(255 83 1);
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & > .grid {
            grid-template-areas:
                "capinfo capinfo capinfo capinfo capinfo capinfo . . gminfo gminfo gminfo gminfo"
                "cap cap cap cap cap cap cap cap cap cap cap cap";

            & .genome-medical-info {
                --genome-medical-info-margin-bottom: auto;
            }

            & .clinical-action-plan {
                --clinical-action-plan-padding-bottom: 48px;
                --clinical-action-plan-padding-horizontal: 32px;
                --clinical-action-plan-padding-top: 32px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & > .grid {
            grid-template-areas:
                "capinfo capinfo capinfo capinfo capinfo gminfo gminfo gminfo gminfo gminfo"
                "cap cap cap cap cap cap cap cap cap cap";

            & .genome-medical-info {
                --genome-medical-info-margin-bottom: auto;
            }

            & .clinical-action-plan {
                --clinical-action-plan-padding-bottom: 48px;
                --clinical-action-plan-padding-horizontal: 32px;
                --clinical-action-plan-padding-top: 32px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & > .grid {
            grid-template-areas:
                "capinfo capinfo capinfo capinfo capinfo capinfo"
                "gminfo gminfo gminfo gminfo gminfo gminfo"
                "cap cap cap cap cap cap";

            & .genome-medical-info {
                --genome-medical-info-margin-bottom: 32px;
            }
        }
    }
}
