.risk-visualization {
    --card-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    --card-padding-left: 16px;
    --card-padding-right: 16px;

    text-align: center;

    & * {
        color: var(--genomics-color-shade-white: rgb(255 255 255));
    }

    & .title {
        --paragraph-bottom-margin: 24px;
        --paragraph-top-margin: 0;
    }

    & .visualization-container {
        --visualization-container-margin-bottom: 24px;

        display: flex;
        flex-direction: row;
        margin-bottom: var(--visualization-container-margin-bottom);

        & img {
            --risk-visualization-button-visibility: hidden;
            --risk-visualization-button-width: 0;

            align-self: center;
            cursor: pointer;
            height: fit-content;
            visibility: var(--risk-visualization-button-visibility);
            width: var(--risk-visualization-button-width);

            &.disabled {
                cursor: default;
            }
        }

        & .visualization {
            & .compare-risk {
                display: grid;
                grid-column-gap: 20px;
                grid-template-areas:
                    "yourResult averageResult"
                    "yourDescription averageDescription"
                    "yourOneHundredPeople averageOneHundredPeople";
                grid-template-columns: repeat(2, minmax(0, 1fr));
                text-align: left;

                & .your-result {
                    grid-area: yourResult;
                    margin: 0;
                }

                & .average-result {
                    color: var(--genomics-color-shade-mid-grey, rgb(189 189 189));
                    grid-area: averageResult;
                    margin: 0;
                }

                & .your-description {
                    grid-area: yourDescription;
                    margin: 0 0 16px;
                }

                & .average-description {
                    color: var(--genomics-color-shade-mid-grey, rgb(189 189 189));
                    grid-area: averageDescription;
                    margin: 0 0 16px;

                    & b {
                        color: var(--genomics-color-shade-mid-grey, rgb(189 189 189));
                    }
                }

                & .your-one-hundred-people {
                    grid-area: yourOneHundredPeople;
                }

                & .average-one-hundred-people {
                    grid-area: averageOneHundredPeople;
                }
            }

            & h2 {
                --heading-margin: 0 0 16px;
            }

            & p {
                --paragraph-bottom-margin: 0;
                --paragraph-top-margin: 12px;
            }

            & .one-hundred-people {
                --one-hundred-people-width: min(100%, 311px);

                width: var(--one-hundred-people-width);
            }
        }
    }

    & .toggle {
        background-color: color-mod(var(--genomics-color-shade-white, rgb(255 255 255)) alpha(10%));
        border-radius: 60px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: auto;
        width: fit-content;

        & .toggle-option {
            padding: 6px 12px;

            &.enabled {
                background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
                border: 1px solid white;
                border-radius: 60px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .title {
            padding: 0 32px;
        }

        & .visualization-container {
            --visualization-container-margin-bottom: 16px;

            & img {
                --risk-visualization-button-visibility: visible;
                --risk-visualization-button-width: inherit;

                &.disabled {
                    opacity: 0.5;
                }
            }

            & .visualization {
                margin: 0 16px;

                & .one-hundred-people {
                    --one-hundred-people-width: min(100%, 361px);
                }

                & p {
                    --paragraph-top-margin: 16px;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .title {
            padding: 0 32px;
        }

        & .visualization-container {
            --visualization-container-margin-bottom: 16px;

            & img {
                --risk-visualization-button-visibility: visible;
                --risk-visualization-button-width: inherit;

                &.disabled {
                    opacity: 0.5;
                }
            }

            & .visualization {
                margin: 0 16px;

                & .one-hundred-people {
                    --one-hundred-people-width: min(100%, 361px);
                }

                & p {
                    --paragraph-top-margin: 16px;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .title {
            padding: 0 32px;
        }

        & .visualization-container {
            --visualization-container-margin-bottom: 16px;

            & img {
                --risk-visualization-button-visibility: visible;
                --risk-visualization-button-width: inherit;

                &.disabled {
                    opacity: 0.5;
                }
            }

            & .visualization {
                margin: 0 16px;

                & .one-hundred-people {
                    --one-hundred-people-width: min(100%, 361px);
                }
            }
        }
    }
}
