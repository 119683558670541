.error-notice-grid {
    --card-background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
    --card-margin-bottom: 0;
    --card-padding-bottom: 32px;
    --card-padding-top: 32px;
    --denim-grid-row-gap: 32px;

    & .grid {
        grid-template-areas:
            "description description"
            "image image";
    }

    & .error-notice-content {
        align-items: flex-start;
        color: var(--genomics-color-primary-navy, rgb(17 0 53));
        display: flex;
        flex-direction: column;
        grid-area: description;
        justify-content: center;
        justify-self: left;

        & .button {
            --button-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            --button-border-color: var(--genomics-color-primary-navy, rgb(17 0 53));

            margin-bottom: 32px;
        }

        & .contact-link {
            margin-bottom: 32px;

            & .link {
                --link-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            }
        }

        & .divider {
            --divider-vertical-margin: 0 0 32px;
        }

        & .error-code {
            color: var(--genomics-color-shade-dark-grey, rgb(100 100 100));
        }

        & h3 {
            --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            --heading-margin: 0 0 32px;
        }

        & p {
            --paragraph-top-margin: 0;
        }

        & .useful-links {
            display: flex;
            margin-top: 16px;

            & .link {
                --link-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            }

            & :not(:first-child) {
                margin-left: 4px;
            }

            & :not(:last-child) {
                margin-right: 4px;
            }

            & .useful-links-separator {
                color: var(--genomics-color-shade-mid-grey, rgb(189 189 189));
            }
        }
    }

    & img {
        display: none;
        grid-area: image;
        height: 100%;
        width: 100%;
    }

    @media (--denim-breakpoint-desktop) {
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;

        & .grid {
            grid-template-areas: "description description description description description description image image image image image image";
        }

        & img {
            display: block;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;

        & .grid {
            grid-template-areas: "description description description description description image image image image image";
        }

        & img {
            display: block;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "description description description description description description"
                "image image image image image image";
        }
    }
}
