.trait-result {
    --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    --card-link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
    --card-margin-bottom: 0;
    --denim-card-padding-bottom: 48px;
    --denim-card-padding-top: 48px;
    --denim-heading-margin: 0 0 32px;

    & :--heading {
        --heading-color: var(--card-color);
    }

    & .grid {
        grid-template-areas:
            "result result"
            "visualization visualization"
            "info info";

        & .result {
            --result-margin-bottom: 32px;

            align-self: end;
            grid-area: result;
            margin-bottom: var(--result-margin-bottom);

            & .risk {
                color: var(--trait-color);

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-CVD_US {
                    --trait-color: var(--minerva-cvd-us-dark);
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-T2D {
                    --trait-color: var(--minerva-t2d-dark);
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-BC {
                    --trait-color: var(--minerva-bc-dark);
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-PC {
                    --trait-color: var(--minerva-pc-dark);
                }
            }

            & .link {
                --link-color: var(--card-link-color);

                font-size: 14px;

                &.icon {
                    --icon-background-color: var(--card-link-color);
                }
            }
        }

        & .info {
            align-self: start;
            grid-area: info;

            & .branding {
                margin: 48px 0;

                & .is-caption {
                    --paragraph-bottom-margin: 8px;
                    --paragraph-top-margin: 0;
                }

                & img {
                    height: 25px;
                }
            }

            & .scroll-prompt {
                align-items: center;
                cursor: pointer;
                display: flex;

                & .icon {
                    --icon-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                    background: var(--genomics-color-primary-light-grey, rgb(239 239 239));
                    border-radius: 50%;
                    margin-right: 8px;
                    padding: 8px;

                    &.after::after {
                        --icon-size: 24px;

                        margin-left: 0;
                    }
                }
            }
        }

        & .risk-visualization {
            --card-margin-bottom: 0;

            grid-area: visualization;
        }
    }

    @media (--denim-breakpoint-max-tablet-portrait) {
        & .grid {
            & .info {
                display: flex;
                justify-content: center;

                & .branding {
                    margin: 16px 0 0;
                }

                & .scroll-prompt {
                    display: none;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "result result result result result result"
                "visualization visualization visualization visualization visualization visualization"
                "info info info info info info";
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            grid-template-areas:
                "result result result result result visualization visualization visualization visualization visualization"
                "info info info info info visualization visualization visualization visualization visualization";

            & .result {
                --result-margin-bottom: 0;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .grid {
            grid-template-areas:
                "result result result result result . visualization visualization visualization visualization visualization visualization"
                "info info info info info . visualization visualization visualization visualization visualization visualization";

            & .result {
                --result-margin-bottom: 0;

                & p {
                    width: calc(100% + 1ch);
                }

                & .link {
                    font-size: 18px;
                }
            }
        }
    }
}
