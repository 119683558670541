.intercom-widget {
    --button-background-color: var(--genomics-color-primary-bright-blue);
    --button-border-color: var(--genomics-color-primary-bright-blue);
    --button-outline-color: rgba(13 94 255 / 50%);
    --button-min-width: fit-content(150px);
    --intercom-widget-bottom: 16px;
    --intercom-widget-right: 16px;

    bottom: var(--intercom-widget-bottom);
    padding-left: var(--button-padding-left);
    padding-right: var(--button-padding-right);
    position: fixed;
    right: var(--intercom-widget-right);
    z-index: 2147483000;

    @media (--denim-breakpoint-desktop) {
        --intercom-widget-bottom: 32px;
        --intercom-widget-right: 32px;
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --intercom-widget-bottom: 32px;
        --intercom-widget-right: 32px;
    }

    @media (--denim-breakpoint-tablet-portrait) {
        --intercom-widget-bottom: 32px;
        --intercom-widget-right: 32px;
    }
}
