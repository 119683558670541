.download-reports-overlay {
    background-color: var(--minerva-color-dark-navy, rgb(1 0 11));
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}

.download-reports-modal {
    bottom: 0;
    left: 0;
    margin: 24px 16px;
    position: absolute;
    right: 0;
    top: 0;
}

.download-reports-modal-grid {
    --card-vertical-padding: 0;

    background-color: transparent;

    & .grid {
        grid-template-areas:
            ". close"
            "reports reports";
    }

    & .link {
        grid-area: close;
        justify-self: end;
    }

    & .report-box {
        background-color: white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        grid-area: reports;
        margin: 24px 0;
        padding: 32px 16px;
        width: 100%;

        & * {
            color: var(--genomics-color-primary-navy, rgb(17 0 53));
        }

        & .report {
            margin: 16px 0;
        }

        & h2 {
            margin-bottom: 0;
        }

        & p {
            margin: 24px 0;
        }

        & .icon {
            --icon-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
            --icon-size: 26px;
        }

        & .divider {
            margin: 16px 0;
            width: 100%;
        }
    }

    @media (--denim-breakpoint-desktop) {
        --card-vertical-padding: 24px;

        & .grid {
            grid-template-areas:
                ". . . . . . . . . close . ."
                ". . reports reports reports reports reports reports reports reports . .";
        }

        & .report-box {
            padding: 48px;

            & .report {
                margin: 12px 0;
            }

            & p {
                margin: 32px 0;
            }

            & .divider {
                margin: 24px 0;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            grid-template-areas:
                ". . . . . . . . close ."
                ". reports reports reports reports reports reports reports reports .";
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                ". . . . . close"
                "reports reports reports reports reports reports";
        }
    }
}
