.icon {
    &.search {
        --icon-search: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M29 27.586l-7.552-7.552a11.018 11.018 0 10-1.414 1.414L27.586 29zM4 13a9 9 0 119 9 9.01 9.01 0 01-9-9z"/></svg>');

        &.before::before {
            mask: var(--icon-search) no-repeat;
        }

        &.after::after {
            mask: var(--icon-search) no-repeat;
        }
    }
}
