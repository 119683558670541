.icon {
    &.copy {
        --icon-copy: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M28 10v18H10V10h18m0-2H10a2 2 0 00-2 2v18a2 2 0 002 2h18a2 2 0 002-2V10a2 2 0 00-2-2z"/><path d="M4 18H2V4a2 2 0 012-2h14v2H4z"/></svg>');

        &.before::before {
            mask: var(--icon-copy) no-repeat;
        }

        &.after::after {
            mask: var(--icon-copy) no-repeat;
        }
    }
}
