.lifestyle-icon {
    height: 48px;
    mask: var(--icon);
    width: 48px;
}

/* stylelint-disable-next-line selector-class-pattern */
.lifestyle-DIET-icon {
    --icon: url("images/lifestyle-DIET-icon.svg");
}

/* stylelint-disable-next-line selector-class-pattern */
.lifestyle-EXERCISE-icon {
    --icon: url("images/lifestyle-EXERCISE-icon.svg");
}

/* stylelint-disable-next-line selector-class-pattern */
.lifestyle-SMOKING-icon {
    --icon: url("images/lifestyle-SMOKING-icon.svg");
}

/* stylelint-disable-next-line selector-class-pattern */
.lifestyle-WEIGHT-icon {
    --icon: url("images/lifestyle-WEIGHT-icon.svg");
}
