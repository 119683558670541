.grid {
    /* 0 — 599px (Phone)                                     */
    /* 2 cols, gutter: 16px, margin: 16px, Mobile text sizes */
    --grid-columns: var(--denim-grid-columns, 2);
    --grid-column-gap: var(--denim-grid-column-gap, 16px);
    --grid-column-min-width: var(--denim-grid-column-min-width, 0);
    --grid-horizontal-margin: var(--denim-grid-horizontal-margin, 16px);
    --grid-max-width: var(--denim-grid-max-width, 1072px);
    --grid-row-gap: var(--denim-grid-row-gap, 0);

    display: grid;
    gap: var(--grid-row-gap) var(--grid-column-gap);
    grid-template-columns: repeat(var(--grid-columns), minmax(var(--grid-column-min-width), 1fr));
    margin-left: var(--grid-horizontal-margin);
    margin-right: var(--grid-horizontal-margin);
    max-width: var(--grid-max-width);

    @media (--denim-breakpoint-desktop) {
        & {
            /* 1136px + (Desktop)                                           */
            /* 12 cols, max-width: 1072px, margin: 32px, Desktop text sizes */
            --grid-columns: 12;
            --grid-column-gap: 32px;
            --grid-horizontal-margin: auto;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            /* 900 — 1135px (Tablet Landscape)                       */
            /* 10 cols, gutter: 32px, margin: 32px, Mobile text sizes */
            --grid-columns: 10;
            --grid-column-gap: 32px;
            --grid-horizontal-margin: 32px;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            /* 600 — 899px (Tablet Portrait)                         */
            /* 6 cols, gutter: 32px, margin: 32px, Mobile text sizes */
            --grid-columns: 6;
            --grid-column-gap: 32px;
            --grid-horizontal-margin: 32px;
        }
    }
}
