.access-code-issue {
    & .back-link {
        margin-bottom: 32px;
        margin-left: -7px;
    }

    & .access-code-input {
        --access-code-issue-access-code-input-width: inherit;

        width: var(--access-code-issue-access-code-input-width);

        @media (--denim-breakpoint-desktop) {
            & {
                --access-code-issue-access-code-input-width: 70%;
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            & {
                --access-code-issue-access-code-input-width: 75%;
            }
        }
    }

    @nest
    .footer & {
        --access-code-issue-footer-bottom-margin: var(--minerva-access-code-issue-footer-bottom-margin, 32px);
        --access-code-issue-footer-top-margin: var(--minerva-access-code-issue-footer-top-margin, 32px);
        --access-code-issue-footer-horizontal-margin: var(--denim-grid-horizontal-margin, 16px);
        --access-code-issue-footer-max-width: var(--denim-grid-max-width, 1072px);
        --access-code-issue-footer-content-align-items: flex-start;
        --access-code-issue-footer-content-flex-flow: column;
        --access-code-issue-footer-content-justify-content: center;

        align-items: var(--access-code-issue-footer-content-align-items);
        display: flex;
        flex-flow: var(--access-code-issue-footer-content-flex-flow);
        justify-content: var(--access-code-issue-footer-content-justify-content);
        margin: var(--access-code-issue-footer-top-margin) var(--access-code-issue-footer-horizontal-margin) var(--access-code-issue-footer-bottom-margin) var(--access-code-issue-footer-horizontal-margin);
        max-width: var(--access-code-issue-footer-max-width);

        @media (--denim-breakpoint-desktop) {
            & {
                --access-code-issue-footer-horizontal-margin: auto;
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            & {
                --access-code-issue-footer-horizontal-margin: 32px;
            }
        }

        @media (--denim-breakpoint-tablet-portrait) {
            & {
                --access-code-issue-footer-horizontal-margin: 32px;
            }
        }

        @media (--denim-breakpoint-mobile) {
            & {
                --access-code-issue-footer-bottom-margin: 48px;
            }
        }
    }
}

.landing-page {
    & .hero {
        --card-background-image: linear-gradient(250deg, rgb(17 0 53 / 35%) 0%, rgb(17 0 53 / 87%) 72%), url("/images/landing-hero.jpg");
        --card-background-position: 75% 50%;
        --card-margin-bottom: 0;
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;

        background-image: var(--card-background-image);
        background-position: var(--card-background-position);
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;

        @media (--denim-breakpoint-tablet-portrait) {
            --card-background-position: 50% 50%;
        }

        @media (--denim-breakpoint-tablet-landscape) {
            --card-background-image: linear-gradient(233deg, rgb(17 0 53 / 3%) 0%, rgb(17 0 53 / 87%) 68%), url("/images/landing-hero.jpg");
            --card-background-position: 50% 50%;
        }

        @media (--denim-breakpoint-desktop) {
            --card-background-image: linear-gradient(233deg, rgb(17 0 53 / 3%) 0%, rgb(17 0 53 / 87%) 68%), url("/images/landing-hero.jpg");
            --card-background-position: 50% 50%;
        }

        @media screen and (min-width: 1600px) {
            --card-padding-bottom: 96px;
            --card-padding-top: 96px;
        }

        @media screen and (min-width: 1800px) {
            --card-padding-bottom: 160px;
            --card-padding-top: 160px;
        }

        & .grid {
            grid-template-areas:
                "title title"
                "subTitle subTitle"
                "accessCode accessCode";

            & h1 {
                grid-area: title;
            }

            & h3 {
                grid-area: subTitle;
                margin-bottom: 0;
            }

            & .is-caption {
                margin-bottom: 8px;
            }

            & .access-code-container {
                display: flex;
                flex-direction: column;
                gap: 32px;
                grid-area: accessCode;
                margin-top: 32px;

                &.go-to-account {
                    gap: 16px;

                    & .is-small {
                        display: none;
                    }
                }
            }

            & .access-code-input {
                flex-grow: 1;
            }

            & .learn-more {
                align-self: center;
                justify-self: center;
                margin-bottom: calc(var(--line-height) / 2);
                white-space: nowrap;
            }
        }

        &.study-closed {
            & .info {
                grid-area: subTitle;
                margin-bottom: 0;
            }

            & .go-to-account {
                grid-area: accessCode;
            }
        }
    }

    & .hero-statements {
        --card-margin-bottom: 0;
        --card-padding-bottom: 24px;
        --card-padding-top: 24px;

        & .grid {
            grid-template-areas: "statements statements";
        }

        & .statements {
            display: flex;
            flex-direction: column;
            grid-area: statements;
        }

        & .statement {
            display: grid;
            grid-column-gap: 1rem;
            grid-template-areas:
                "image title"
                "image description";
            grid-template-columns: 24px 1fr;

            & .image {
                grid-area: image;
            }

            & .title {
                grid-area: title;
                margin-bottom: 4px;
            }

            & .description {
                align-self: start;
                grid-area: description;
            }
        }

        & .statement:not(:last-child) {
            margin-bottom: 32px;
        }
    }

    & .get-started {
        --card-background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
        --card-margin-bottom: 0;

        background-image: url("/images/navy-slash-background-right-to-left.svg");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 0;

        & .grid {
            --grid-padding: 32px;

            background-color: var(--genomics-color-shade-white, rgb(255 255 255));
            border-radius: 4px;
            grid-template-areas:
                "title title"
                "description description"
                "accessCode accessCode"
                "assurances assurances";
            padding: var(--grid-padding);

            & .is-caption {
                margin-bottom: 8px;
            }

            & .title {
                --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                grid-area: title;
                margin-bottom: 32px;
            }

            & .description {
                --color: var(--genomics-color-primary-navy, rgb(17 0 53));

                grid-area: description;

                & :first-child {
                    --paragraph-top-margin: 0;
                }
            }

            & .access-code-container {
                color: var(--genomics-color-primary-navy, rgb(17 0 53));
                grid-area: accessCode;
                margin-bottom: 24px;
            }

            & .assurances {
                color: var(--genomics-color-primary-navy, rgb(17 0 53));
                display: flex;
                flex-direction: column;
                grid-area: assurances;

                & .assurance {
                    display: flex;
                    flex-direction: row;

                    & p {
                        margin: 4px 0 4px 8px;
                    }
                }
            }
        }
    }

    & .many-common-diseases {
        --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
        --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        --card-link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
        --card-margin-bottom: 0;

        background-image: url("/images/navy-slash-background-left-to-right.svg");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        scroll-margin-top: 76px;

        & .grid {
            grid-template-areas:
                "title title"
                "description description"
                "traits traits"
                "invited invited"
                "points points";

            & .title {
                --heading-color: var(--card-color);
                --heading-margin: 0;
                --heading-text-align: center;

                grid-area: title;
                justify-self: center;
                text-align: var(--heading-text-align);
            }

            & .description {
                --paragraph-color: var(--card-color);
                --paragraph-text-align: center;

                grid-area: description;
                justify-self: center;
                text-align: var(--paragraph-text-align);
            }

            & .traits {
                --traits-flex-direction: column;

                display: flex;
                flex-flow: var(--traits-flex-direction) nowrap;
                grid-area: traits;

                & .trait-card {
                    --trait-card-width: auto;

                    width: var(--trait-card-width);
                }

                & .trait-card + .trait-card {
                    --trait-card-margin-top: 32px;
                    --trait-card-margin-left: 0;

                    margin-left: var(--trait-card-margin-left);
                    margin-top: var(--trait-card-margin-top);
                }
            }

            & .why-your-invited {
                --margin-top: 64px;

                grid-area: invited;
                margin-top: var(--margin-top);
                text-align: center;

                & .sub-title {
                    --heading-color: var(--card-color);
                    --heading-margin: 32px 0 0 0;
                }

                & .details {
                    --paragraph-color: var(--card-color);
                }

                & p:last-child {
                    --paragraph-bottom-margin: 0;
                }
            }

            & .salient-points {
                /* Works out the column width against the max-width of hte grid, which is always 1072px, so it's not
                  overly accurate when the browser is down near 900px wide... */
                --approximate-column-width: calc((var(--grid-max-width) - ((var(--grid-columns) - 1) * var(--grid-column-gap))) / var(--grid-columns));
                --margin-top: 64px;

                grid-area: points;
                margin-top: var(--margin-top);
                position: relative;

                & .progress-bar-salient-points {
                    --progress-bar-gradient-visibility: hidden;

                    left: 0;
                    position: absolute;
                    top: 0;
                    visibility: var(--progress-bar-gradient-visibility);
                    z-index: 0;

                    & .background {
                        --pbg-background-color: var(--card-background-color);
                    }
                }

                & .points {
                    position: relative;
                }

                & .point {
                    --point-align-items: flex-start;
                    --point-flex-direction: column;
                    --point-justify-content: center;
                    --point-padding-bottom: 64px;

                    align-items: var(--point-align-items);
                    display: flex;
                    flex-flow: var(--point-flex-direction) nowrap;
                    justify-content: var(--point-justify-content);
                    padding-bottom: var(--point-padding-bottom);

                    & .media {
                        --media-border-radius: 4px;
                        --media-margin-left: unset;
                        --media-margin-right: unset;
                        --media-width: 100%;

                        margin-left: var(--media-margin-left);
                        margin-right: var(--media-margin-right);
                        min-width: var(--media-width);
                        order: 0;

                        & .media-img {
                            border-radius: var(--media-border-radius);
                            width: 100%;
                        }
                    }

                    & .player-wrapper {
                        padding-top: 100%;
                    }

                    & .details {
                        --details-margin-left: unset;
                        --details-margin-right: unset;
                        --details-margin-top: 32px;

                        margin-left: var(--details-margin-left);
                        margin-right: var(--details-margin-right);
                        margin-top: var(--details-margin-top);
                        order: 1;

                        & .sub-title {
                            --heading-color: var(--card-color);
                        }

                        & .description {
                            --paragraph-color: var(--card-color);
                            --paragraph-text-align: unset;
                        }

                        & .link {
                            --link-color: var(--card-link-color);
                        }

                        & .icon {
                            --icon-background-color: var(--card-link-color);
                        }

                        & .wordmark {
                            --wordmark-height: 26px;

                            height: var(--wordmark-height);
                        }
                    }
                }
            }
        }
    }

    & .helpful-resources {
        --card-background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));

        margin: 0;
        padding: 0;

        & .grid {
            --grid-padding: 32px 0;

            color: var(--genomics-color-shade-dark-grey, rgb(100 100 100));
            grid-template-areas:
                "title title"
                "resources resources"
                "contact contact";
            padding: var(--grid-padding);
            text-align: center;

            /* stylelint-disable no-descending-specificity */
            & .link {
                --link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
            }
            /* stylelint-enable no-descending-specificity */

            & .title {
                & h3 {
                    --heading-color: var(--genomics-color-shade-dark-grey, rgb(100 100 100));

                    margin: 4px 0 0;
                }

                grid-area: title;
                margin-bottom: 32px;
            }

            & .resources {
                --link-flex-direction: column;

                display: flex;
                flex-flow: var(--link-flex-direction) nowrap;
                grid-area: resources;
                text-align: left;

                & .link-card {
                    --link-card-width: auto;

                    background-color: white;
                    border-radius: 4px;
                    margin-bottom: 16px;
                    padding: 16px;
                    width: var(--link-card-width);

                    & img {
                        margin-right: 16px;
                    }
                }
            }

            & .contact {
                grid-area: contact;
                margin-top: 16px;

                & :not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }
    }

    & .what-to-expect {
        & .grid {
            & .title {
                grid-column: 1 / -1;
                grid-row: 1 / 2;
                justify-self: left;
                margin-bottom: 64px;
            }

            & .progress-step {
                grid-column: 1 / 2;
                grid-row: 2 / 8;
                justify-self: left;
            }

            & [class^="step-"] {
                grid-column: 1 / 3;
                margin-left: 32px;

                /* stylelint-disable no-descending-specificity */
                & img {
                    margin-bottom: 16px;
                    margin-top: 0;
                    width: 100%;
                }
                /* stylelint-enable no-descending-specificity */

                & .step {
                    & .number {
                        color: var(--genomics-color-primary-light-grey, rgb(239 239 239));

                        & .optional {
                            color: var(--genomics-color-primary-light-green: rgb(51 255 196));
                        }
                    }

                    & .title {
                        color: var(--genomics-color-shade-white, rgb(255 255 255));
                        margin-bottom: 16px;
                    }

                    & .description-step {
                        color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
                        margin-bottom: 32px;
                    }
                }
            }

            & .step-1 {
                grid-row: 2 / 3;
            }

            & .step-2 {
                grid-row: 3 / 4;
            }

            & .step-3 {
                grid-row: 4 / 5;
            }

            & .step-4 {
                grid-row: 5 / 6;
            }

            & .step-5 {
                grid-row: 6 / 7;
            }

            & .step-6 {
                grid-row: 7 / 8;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .hero-statements {
                & .grid {
                    grid-template-areas: "statements statements statements statements statements statements statements statements statements statements statements statements";

                    & .statements {
                        flex-direction: row;
                    }

                    & .statement {
                        margin-bottom: 0;
                        width: 33%;
                    }

                    & .statement:not(:last-child) {
                        margin-right: 32px;
                    }
                }
            }

            & .many-common-diseases {
                & .grid {
                    grid-template-areas:
                        ". . title title title title title title title title . . "
                        ". . description description description description description description description description . ."
                        "traits traits traits traits traits traits traits traits traits traits traits traits"
                        ". . . invited invited invited invited invited invited . . ."
                        "points points points points points points points points points points points points";

                    & .traits {
                        --traits-flex-direction: row;

                        & .trait-card {
                            --trait-card-width: 336px;
                        }

                        & .trait-card + .trait-card {
                            --trait-card-margin-top: 0;
                            --trait-card-margin-left: 32px;
                        }
                    }

                    & .salient-points {
                        & .progress-bar-salient-points {
                            --progress-bar-gradient-visibility: visible;

                            left: calc(var(--approximate-column-width) / 2);
                        }

                        & .point {
                            --point-align-items: center;
                            --point-flex-direction: row;
                            --point-justify-content: flex-start;

                            & .media {
                                --media-margin-right: 124px;
                                --media-width: 428px;
                            }

                            & .details {
                                --details-margin-value: calc(var(--approximate-column-width) + var(--grid-column-gap));
                                --details-margin-right: var(--details-margin-value);
                                --details-margin-top: 0;
                            }

                            &.right {
                                & .media {
                                    --media-margin-left: 124px;
                                    --media-margin-right: unset;

                                    order: 1;
                                }

                                & .details {
                                    --details-margin-left: var(--details-margin-value);
                                    --details-margin-right: unset;

                                    order: 0;
                                }
                            }
                        }

                        & .wordmark {
                            --workmark-width: 163px;

                            width: var(--workmark-width);
                        }
                    }
                }
            }

            & .get-started {
                & .grid {
                    --grid-padding: 64px 0;

                    background-color: var(--genomics-color-shade-white, rgb(255 255 255));
                    grid-template-areas:
                        ". title title title title title title title title title title ."
                        ". description description description description description accessCode accessCode accessCode accessCode accessCode ."
                        ". description description description description description assurances assurances assurances assurances assurances .";
                }

                & .description {
                    & :last-child {
                        --paragraph-bottom-margin: 0;
                    }
                }
            }

            & .what-to-expect {
                & .grid {
                    & .progress-step {
                        grid-column: 4 / 5;
                        justify-self: right;
                    }

                    & [class^="step-"] {
                        display: contents;

                        & img {
                            grid-column: 1 / 4;
                            margin-bottom: 32px;
                            width: calc(100% + var(--grid-column-gap));
                        }

                        & .step {
                            grid-column: 5 / 13;
                        }
                    }
                }
            }

            & .helpful-resources {
                & .grid {
                    --grid-padding: 32px 0;

                    grid-template-areas:
                        "title title title title title title title title title title title title"
                        "resources resources resources resources resources resources resources resources resources resources resources resources"
                        "contact contact contact contact contact contact contact contact contact contact contact contact";

                    & .resources {
                        --link-flex-direction: row;

                        & .link-card {
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-right: 32px;
                            padding: 24px;
                            width: 33%;

                            & img {
                                margin: 0 0 16px;
                                width: 32px;
                            }
                        }

                        & .link-card:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            & .hero {
                & .grid {
                    grid-template-areas:
                        "title title title title title . . . . . . ."
                        "subTitle subTitle subTitle subTitle subTitle subTitle . . . . . ."
                        "accessCode accessCode accessCode accessCode accessCode accessCode accessCode accessCode . . . .";

                    & .access-code-container {
                        flex-direction: row;

                        &.go-to-account {
                            & .is-small {
                                display: initial;
                            }

                            & .link {
                                margin-left: 16px;
                            }
                        }
                    }

                    & .learn-more {
                        align-self: flex-end;
                        justify-self: unset;
                    }
                }

                &.study-closed {
                    & .grid {
                        grid-template-areas:
                            "title title title title title title title . . . . ."
                            "subTitle subTitle subTitle subTitle subTitle subTitle . . . . . ."
                            "accessCode accessCode accessCode accessCode accessCode accessCode accessCode accessCode . . . .";
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            & .hero-statements {
                & .grid {
                    grid-template-areas: "statements statements statements statements statements statements statements statements statements statements";

                    & .statements {
                        flex-direction: row;
                    }

                    & .statement {
                        margin-bottom: 0;
                        width: 33%;
                    }

                    & .statement:not(:last-child) {
                        margin-right: 32px;
                    }
                }
            }

            & .many-common-diseases {
                & .grid {
                    grid-template-areas:
                        ". title title title title title title title title ."
                        ". description description description description description description description description ."
                        "traits traits traits traits traits traits traits traits traits traits"
                        ". . invited invited invited invited invited invited . ."
                        "points points points points points points points points points points";

                    & .traits {
                        --traits-flex-direction: row;

                        & .trait-card {
                            --trait-card-width: 298px;
                        }

                        & .trait-card + .trait-card {
                            --trait-card-margin-top: 0;
                            --trait-card-margin-left: 32px;
                        }
                    }

                    & .salient-points {
                        & .progress-bar-salient-points {
                            --progress-bar-gradient-visibility: visible;

                            left: calc(var(--approximate-column-width) / 2);
                        }

                        & .point {
                            --point-align-items: center;
                            --point-flex-direction: row;
                            --point-justify-content: flex-start;

                            & .media {
                                --media-margin-right: 130px;
                                --media-width: 365px;
                            }

                            & .details {
                                --details-margin-value: calc(var(--approximate-column-width) + var(--grid-column-gap));
                                --details-margin-right: var(--details-margin-value);
                                --details-margin-top: 0;
                            }

                            &.right {
                                & .media {
                                    --media-margin-left: 130px;
                                    --media-margin-right: unset;

                                    order: 1;
                                }

                                & .details {
                                    --details-margin-left: var(--details-margin-value);
                                    --details-margin-right: unset;

                                    order: 0;
                                }
                            }
                        }
                    }
                }
            }

            & .get-started {
                & .grid {
                    --grid-padding: 64px 0;

                    background-color: var(--genomics-color-shade-white, rgb(255 255 255));
                    grid-template-areas:
                        ". title title title title title title title title ."
                        ". description description description description accessCode accessCode accessCode accessCode ."
                        ". description description description description assurances assurances assurances assurances .";
                }

                & .description {
                    & :last-child {
                        --paragraph-bottom-margin: 0;
                    }
                }
            }

            & .what-to-expect {
                & .grid {
                    & .progress-step {
                        grid-column: 3 / 4;
                        justify-self: right;
                    }

                    & [class^="step-"] {
                        display: contents;

                        & img {
                            grid-column: 1 / 3;
                            margin-bottom: 32px;
                            width: calc(100% + var(--grid-column-gap));
                        }

                        & .step {
                            grid-column: 4 / 11;
                        }
                    }
                }
            }

            & .helpful-resources {
                & .grid {
                    --grid-padding: 32px 32px;

                    grid-template-areas:
                        "title title title title title title title title title title"
                        "resources resources resources resources resources resources resources resources resources resources"
                        "contact contact contact contact contact contact contact contact contact contact";

                    & .resources {
                        --link-flex-direction: row;

                        & .link-card {
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            margin-right: 32px;
                            padding: 24px;
                            width: 33%;

                            & img {
                                margin: 0 0 16px;
                                width: 32px;
                            }
                        }

                        & .link-card:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            & .hero {
                & .grid {
                    grid-template-areas:
                        "title title title title . . . . . ."
                        "subTitle subTitle subTitle subTitle subTitle . . . . ."
                        "accessCode accessCode accessCode accessCode accessCode accessCode accessCode . . .";

                    & .access-code-container {
                        flex-direction: row;

                        &.go-to-account {
                            & .is-small {
                                display: initial;
                            }

                            & .link {
                                margin-left: 16px;
                            }
                        }
                    }

                    & .learn-more {
                        align-self: flex-end;
                        justify-self: unset;
                    }
                }

                &.study-closed {
                    & .grid {
                        grid-template-areas:
                            "title title title title title title . . . ."
                            "subTitle subTitle subTitle subTitle subTitle . . . . ."
                            "accessCode accessCode accessCode accessCode accessCode accessCode accessCode . . .";
                    }
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            & .hero {
                & .grid {
                    grid-template-areas:
                        ". title title title title ."
                        ". subTitle subTitle subTitle subTitle ."
                        ". accessCode accessCode accessCode accessCode .";
                }
            }

            & .hero-statements {
                & .grid {
                    grid-template-areas: ". statements statements statements statements .";
                }
            }

            & .many-common-diseases {
                & .grid {
                    grid-template-areas:
                        ". title title title title ."
                        ". description description description description ."
                        ". traits traits traits traits ."
                        ". invited invited invited invited ."
                        ". points points points points .";
                }
            }

            & .get-started {
                & .grid {
                    --grid-padding: 64px 0;

                    background-color: var(--genomics-color-shade-white, rgb(255 255 255));
                    grid-template-areas:
                        ". title title title title  ."
                        ". description description description description ."
                        ". accessCode accessCode accessCode accessCode ."
                        ". assurances assurances assurances assurances .";
                }
            }

            & .what-to-expect {
                & .grid {
                    & .progress-step {
                        grid-column: 3 / 4;
                        justify-self: right;
                    }

                    & [class^="step-"] {
                        display: contents;

                        & img {
                            grid-column: 1 / 3;
                            margin-bottom: 32px;
                            width: calc(100% + var(--grid-column-gap));
                        }

                        & .step {
                            grid-column: 4 / 7;
                        }
                    }
                }
            }

            & .helpful-resources {
                & .grid {
                    grid-template-areas:
                        "title title title title title title"
                        "resources resources resources resources resources resources"
                        "contact contact contact contact contact contact";

                    & .resources {
                        margin: 0 16px 16px;
                    }
                }
            }
        }
    }
}

.landing-page + .footer {
    & .legal-footer-section {
        --legal-footer-section-margin-top: 32px;

        @media (--denim-breakpoint-desktop) {
            & {
                --legal-footer-section-margin-top: calc(146px - var(--denim-list-item-vertical-margin));
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            & {
                --legal-footer-section-margin-top: calc(146px - var(--denim-list-item-vertical-margin));
            }
        }
    }
}
