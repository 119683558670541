.trait-card {
    --trait-card-background-color: var(--minerva-trait-card-background-color, var(--genomics-color-shade-white, rgb(255 255 255)));
    --trait-card-color: var(--minerva-trait-card-color, var(--genomics-color-primary-navy, rgb(17 0 53)));
    --trait-card-border-radius: var(--minerva-trait-card-border-radius, 4px);
    --trait-card-box-shadow: var(--minerva-trait-card-box-shadow, 0 3px 20px color-mod(var(--genomics-color-shade-black, rgb(29 29 29)) alpha(12%)));
    --trait-card-align-items: var(--minerva-trait-card-align-items, center);
    --trait-card-flex-direction: var(--minerva-trait-card-flex-direction, column);
    --trait-card-justify-content: var(--minerva-trait-card-justify-content, flex-start);
    --trait-card-margin-bottom: var(--minerva-trait-card-margin-bottom, 0);
    --trait-card-margin-left: var(--minerva-trait-card-margin-left, 0);
    --trait-card-margin-right: var(--minerva-trait-card-margin-right, 0);
    --trait-card-margin-top: var(--minerva-trait-card-margin-top, 0);
    --trait-card-padding-bottom: var(--minerva-trait-card-padding-bottom, 24px);
    --trait-card-padding-left: var(--minerva-trait-card-padding-left, 40px);
    --trait-card-padding-right: var(--minerva-trait-card-padding-right, 40px);
    --trait-card-padding-top: var(--minerva-trait-card-padding-top, 24px);
    --trait-card-width: var(--minerva-trait-card-width, inherit);

    align-items: var(--trait-card-align-items);
    background-color: var(--trait-card-background-color);
    border-radius: var(--trait-card-border-radius);
    box-shadow: var(--trait-card-box-shadow);
    color: var(--trait-card-color);
    display: flex;
    flex-flow: var(--trait-card-flex-direction) nowrap;
    justify-content: var(--trait-card-justify-content);
    margin: var(--trait-card-margin-top) var(--trait-card-margin-right) var(--trait-card-margin-bottom) var(--trait-card-margin-left);
    padding: var(--trait-card-padding-top) var(--trait-card-padding-right) var(--trait-card-padding-bottom) var(--trait-card-padding-left);
    width: var(--trait-card-width);

    & .image {
        --trait-card-image-height: var(--minerva-trait-card-image-height, 64px);
        --trait-card-image-margin-bottom: var(--minerva-trait-card-image-margin-bottom, 0);
        --trait-card-image-margin-left: var(--minerva-trait-card-image-margin-left, 0);
        --trait-card-image-margin-right: var(--minerva-trait-card-image-margin-right, 0);
        --trait-card-image-margin-top: var(--minerva-trait-card-image-margin-top, 0);
        --trait-card-image-width: var(--minerva-trait-card-image-width, 64px);

        flex: 0 1;
        height: var(--trait-card-image-height);
        margin: var(--trait-card-image-margin-top) var(--trait-card-image-margin-right) var(--trait-card-image-margin-bottom) var(--trait-card-image-margin-left);
        width: var(--trait-card-image-width);
    }

    & .heading {
        --heading-color: inherit;
        --heading-margin: 16px 0 0.75em;
        --heading-text-align: center;
        --trait-card-heading-flex: var(--minerva-trait-card-heading-flex, 1 0);
        --trait-card-heading-width: var(--minerva-trait-card-heading-width, 100%);

        flex: var(--trait-card-heading-flex);
        width: var(--trait-card-heading-width);
    }

    &.large {
        --trait-card-padding-bottom: 48px;
        --trait-card-padding-top: 48px;

        & .image {
            --trait-card-image-height: 96px;
            --trait-card-image-width: 96px;
        }

        & .heading {
            --font-size: 30px;
            --line-height: 32px;

            @media (--denim-breakpoint-desktop) {
                --font-size: 32px;
                --line-height: 36px;
            }
        }
    }

    & .description {
        --paragraph-color: inherit;

        flex: 1 0;
    }

    & .link {
        --link-color: var(--trait-card-color);
    }

    & .icon {
        --icon-background-color: var(--trait-card-color);
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --trait-card-max-height: 192px;
        }
    }
}
