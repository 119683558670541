.icon {
    &.menu {
        --icon-menu: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M4 6h24v2H4zm0 18h24v2H4zm0-12h24v2H4zm0 6h24v2H4z"/></svg>');

        &.before::before {
            mask: var(--icon-menu) no-repeat;
        }

        &.after::after {
            mask: var(--icon-menu) no-repeat;
        }
    }
}
