.icon {
    &.arrow--down {
        --icon-arrow--down: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M24.59 16.59L17 24.17V4h-2v20.17l-7.59-7.58L6 18l10 10 10-10-1.41-1.41z"/></svg>');

        &.before::before {
            mask: var(--icon-arrow--down) no-repeat;
        }

        &.after::after {
            mask: var(--icon-arrow--down) no-repeat;
        }
    }
}
