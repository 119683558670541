.genetic-counseling {
    --about-overlay: 320px;

    @media (--denim-breakpoint-tablet-landscape) {
        --about-overlay: 368px;
    }

    @media (--denim-breakpoint-desktop) {
        --about-overlay: 400px;
    }

    & .grid-image {
        --card-background-image:
            linear-gradient(60deg, rgb(17 0 53 / 85%) 40%, rgb(17 0 53 / 35%) 100%),
            linear-gradient(0deg, rgb(17 0 53 / 100%) 5%, rgb(17 0 53 / 0%) 45%),
            url("images/hero-genetic-counseling.jpg");
        --card-background-position: 50% 50%;
        --card-padding-top: 64px;
        --card-padding-bottom: var(--about-overlay);

        background-image: var(--card-background-image);
        background-position: var(--card-background-position);
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;

        & .grid {
            grid-template-areas: "main main";
        }

        & .main {
            grid-area: main;

            & :not(:last-child) {
                margin-bottom: 32px;
            }
        }

        @media (--denim-breakpoint-tablet-portrait) {
            --card-background-image:
                linear-gradient(65deg, rgb(17 0 53 / 75%) 50%, rgb(17 0 53 / 15%) 100%),
                linear-gradient(0deg, rgb(17 0 53 / 100%) 5%, rgb(17 0 53 / 0%) 45%),
                url("images/hero-genetic-counseling.jpg");

            & .grid {
                grid-template-areas: ". main main main main .";
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            --card-background-image:
                linear-gradient(70deg, rgb(17 0 53 / 75%) 35%, rgb(17 0 53 / 0%) 100%),
                linear-gradient(0deg, rgb(17 0 53 / 100%) 5%, rgb(17 0 53 / 0%) 45%),
                url("images/hero-genetic-counseling.jpg");
            --card-background-position: 50% 50%;

            & .grid {
                grid-template-areas: "main main main main main . . . . .";
            }
        }

        @media (--denim-breakpoint-desktop) {
            --card-background-position: 50% 50%;
            --card-background-image:
                linear-gradient(75deg, rgb(17 0 53 / 75%) 35%, rgb(17 0 53 / 0%) 100%),
                linear-gradient(0deg, rgb(17 0 53 / 100%) 5%, rgb(17 0 53 / 0%) 45%),
                url("images/hero-genetic-counseling.jpg");

            & .grid {
                grid-template-areas: "main main main main main main . . . . . .";
            }
        }

        @media screen and (min-width: 1600px) {
            --card-padding-top: 96px;
        }

        @media screen and (min-width: 1800px) {
            --card-padding-top: 160px;
        }
    }

    & .grid-about {
        --card-background-color: none;
        --card-padding-top: 64px;
        --card-padding-bottom: 32px;

        margin-top: calc(var(--about-overlay) * -1);

        & .grid {
            grid-template-areas: "about about";
        }

        & .about {
            grid-area: about;

            & .subtitle {
                --heading-margin: 0 auto 32px;

                max-width: 450px;
                text-align: center;
            }

            & .help-info {
                --denim-paragraph-vertical-margin: 0;
                --denim-heading-margin: 0 0 16px;

                & img {
                    height: 64px;
                    margin-bottom: 24px;
                    width: 64px;
                }

                & .item {
                    padding: 32px;
                    text-align: center;
                }

                @media (--denim-breakpoint-tablet-landscape) {
                    display: flex;

                    & .item {
                        width: 33.33%;
                    }
                }

                @media (--denim-breakpoint-desktop) {
                    display: flex;

                    & .item {
                        width: 33.33%;
                    }
                }
            }
        }

        @media (--denim-breakpoint-tablet-portrait) {
            & .grid {
                grid-template-areas: ". about about about about .";
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            & .grid {
                grid-template-areas: "about about about about about about about about about about";
            }
        }

        @media (--denim-breakpoint-desktop) {
            & .grid {
                grid-template-areas: "about about about about about about about about about about about about";
            }
        }

        @media screen and (min-width: 1600px) {
            --card-padding-bottom: 96px;
        }
    }
}
