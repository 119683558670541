.legal-footer-section {
    --legal-footer-section-margin-top: 16px;

    margin-top: var(--legal-footer-section-margin-top);

    & .legal-links {
        --legal-footer-section-legal-links-margin-bottom: 8px;

        margin-bottom: var(--legal-footer-section-legal-links-margin-bottom);
    }

    & .copyright {
        --paragraph-bottom-margin: 0;
        --paragraph-top-margin: 0;
    }

    @media (--denim-breakpoint-desktop) {
        --legal-footer-section-margin-top: 32px;
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --legal-footer-section-margin-top: 32px;
    }
}
