.icon {
    &.chevron--left {
        --icon-chevron--left: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M10 16L20 6l1.4 1.4-8.6 8.6 8.6 8.6L20 26z"/></svg>');

        &.before::before {
            mask: var(--icon-chevron--left) no-repeat;
        }

        &.after::after {
            mask: var(--icon-chevron--left) no-repeat;
        }
    }
}
