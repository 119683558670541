.schedule-appointment {
    --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    --card-link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
    --card-margin-bottom: 0;
    --card-padding-bottom: 64px;
    --card-padding-top: 64px;

    & .grid {
        align-items: center;
        grid-template-areas:
            "right right"
            "left left";

        & .left {
            grid-area: left;

            & :--heading {
                --heading-color: var(--card-color);
            }

            & .genome-medical-logo {
                display: block;
                margin-top: 32px;
            }

            & p:first-of-type {
                --paragraph-top-margin: 0;
            }

            & .icon {
                --icon-background-color: var(--card-link-color);
            }
        }

        & .right {
            --background-position: center center;
            --content-width: calc(100vw - (2 * var(--grid-horizontal-margin)));
            --container-width: var(--content-width); /* We want to display a square image for most screens. */

            background-image: url("/images/telehealth-gm.jpg");
            background-position: var(--background-position);
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 4px;
            grid-area: right;
            height: var(--container-width);
        }

        & .link {
            --link-color: var(--card-link-color);
        }

        & .button {
            --button-border-color: var(--card-color);
            --button-color: var(--card-color);
            --button-outline-color: color-mod(var(--genomics-color-primary-navy) alpha(50%));

            margin-bottom: 24px;

            &.icon {
                --icon-background-color: var(--card-color);
            }
        }
    }

    @media (--denim-breakpoint-max-tablet-portrait) {
        & .grid {
            & .right {
                margin-bottom: 32px;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "right right right right right right"
                "left left left left left left";

            & .right {
                --background-position: 0 -48px;
                --container-width: calc(100vw * 0.65);
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            grid-template-areas: "left left left left . right right right right right";

            & .right {
                --container-width: calc((var(--content-width) / 2) - (var(--grid-column-gap) / 2));
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .grid {
            grid-template-areas: "left left left left left . right right right right right right";

            & .right {
                --content-width: var(--grid-max-width);
                --container-width: calc((var(--content-width) / 2) - (var(--grid-column-gap) / 2));
            }
        }
    }
}
