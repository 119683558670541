.report-overview {
    & .hero {
        --card-margin-bottom: 0;

        background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
        background-image: url("/images/intro-bg-wide.svg");
        background-position-x: center;
        background-position-y: -128px;
        background-repeat: no-repeat;
        padding-bottom: 32px;
        padding-top: 64px;

        & .grid {
            grid-template-areas:
                "title title"
                "video video"
                "scrollPrompt scrollPrompt";

            & .title {
                --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                grid-area: title;
                margin-bottom: 48px;
                text-align: center;
            }

            & .video {
                grid-area: video;

                & .video-description {
                    --paragraph-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                    margin-bottom: 24px;
                    margin-top: 8px;
                    text-align: center;
                }

                & iframe {
                    border-radius: 4px;
                }
            }

            & .scroll-prompt {
                cursor: pointer;
                grid-area: scrollPrompt;
                text-align: center;

                & .icon {
                    --icon-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                    background: white;
                    border-radius: 50%;
                    padding: 10px;

                    &.after::after {
                        margin-left: 0;
                    }
                }

                & .is-caption {
                    --paragraph-color: var(--genomics-color-primary-navy, rgb(17 0 53));

                    margin: 0;
                    margin-top: 8px;
                }
            }
        }
    }

    & .tests-summary {
        --card-margin-bottom: 0;

        background-color: var(--genomics-color-primary-light-grey, rgb(239 239 239));
        padding-bottom: 64px;
        scroll-margin-top: 120px;

        & .grid {
            grid-template-areas:
                "title title"
                "traits traits"
                "excluded excluded";

            & .title {
                --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));
                --letter-spacing: initial;

                grid-area: title;
                margin-bottom: 32px;
                text-align: center;
            }

            & .traits {
                --traits-flex-direction: column;

                display: flex;
                flex-flow: var(--traits-flex-direction) nowrap;
                grid-area: traits;

                & .trait-card {
                    cursor: pointer;
                }

                & .trait-card + .trait-card {
                    --trait-card-margin-top: 32px;
                    --trait-card-margin-left: 0;

                    margin-left: var(--trait-card-margin-left);
                    margin-top: var(--trait-card-margin-top);
                }
            }

            & .excluded-traits {
                grid-area: excluded;
                padding: 16px;

                & p {
                    --paragraph-bottom-margin: 0;
                    --paragraph-top-margin: 0;

                    color: var(--genomics-color-primary-navy, rgb(17 0 53));
                }

                &.icon {
                    --icon-align: start;
                    --icon-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
                }
            }
        }
    }

    & .our-science {
        --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
        --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        --card-link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
        --card-margin-bottom: 0;
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;

        padding-top: 0;

        & .grid {
            align-items: center;
            grid-template-areas:
                "left left"
                "right right";

            & .left {
                --background-position: center center;
                --content-width: calc(100vw - (2 * var(--grid-horizontal-margin)));
                --container-width: var(--content-width); /* We want to display a square image for most screens. */

                background-image: url("/images/landing-square-testing.png");
                background-position: var(--background-position);
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 4px;
                grid-area: left;
                height: var(--container-width);
            }

            & .right {
                grid-area: right;

                & :--heading {
                    --heading-color: var(--card-color);
                }

                & .icon {
                    --icon-background-color: var(--card-link-color);
                }
            }

            & .link {
                --link-color: var(--card-link-color);
            }
        }
    }

    @media (--denim-breakpoint-max-tablet-portrait) {
        & .our-science {
            & .grid {
                & .left {
                    margin-bottom: 32px;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .hero {
            background-position-y: -160px;

            & .grid {
                grid-template-areas:
                    "title title title title title title"
                    "video video video video video video"
                    "scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt";
            }
        }

        & .tests-summary {
            & .grid {
                grid-template-areas:
                    "title title title title title title"
                    "traits traits traits traits traits traits"
                    "excluded excluded excluded excluded excluded excluded";
            }
        }

        & .our-science {
            & .grid {
                grid-template-areas:
                    "left left left left left left"
                    "right right right right right right";

                & .left {
                    --background-position: 0 -48px;
                    --container-width: calc(100vw * 0.65);
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .hero {
            background-position-y: 0;

            & .grid {
                grid-template-areas:
                    ". title title title title title title title title ."
                    ". video video video video video video video video ."
                    ". scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt .";
            }
        }

        & .tests-summary {
            & .grid {
                grid-template-areas:
                    ". . title title title title title title . ."
                    "traits traits traits traits traits traits traits traits traits traits"
                    ". excluded excluded excluded excluded excluded excluded excluded excluded .";

                & .traits {
                    --traits-flex-direction: row;

                    justify-content: center;

                    & .trait-card {
                        width: 33%;
                    }

                    & .trait-card + .trait-card {
                        --trait-card-margin-top: 0;
                        --trait-card-margin-left: 32px;
                    }
                }
            }
        }

        & .our-science {
            & .grid {
                grid-template-areas: "left left left left left . right right right right";

                & .left {
                    --container-width: calc((var(--content-width) / 2) - (var(--grid-column-gap) / 2));
                }
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .hero {
            background-position-y: 0;

            & .grid {
                grid-template-areas:
                    ". . title title title title title title title title . ."
                    ". . video video video video video video video video . ."
                    ". . scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt scrollPrompt . .";
            }
        }

        & .tests-summary {
            & .grid {
                grid-template-areas:
                    ". . . title title title title title title . . ."
                    "traits traits traits traits traits traits traits traits traits traits traits traits"
                    ". . excluded excluded excluded excluded excluded excluded excluded excluded . .";

                & .traits {
                    --traits-flex-direction: row;

                    justify-content: center;

                    & .trait-card {
                        width: 33%;
                    }

                    & .trait-card + .trait-card {
                        --trait-card-margin-top: 0;
                        --trait-card-margin-left: 32px;
                    }
                }
            }
        }

        & .our-science {
            & .grid {
                grid-template-areas: "left left left left left left . right right right right right";

                & .left {
                    --content-width: var(--grid-max-width);
                    --container-width: calc((var(--content-width) / 2) - (var(--grid-column-gap) / 2));
                }
            }
        }
    }
}
