.main-footer-content {
    --denim-list-item-vertical-margin: 0;
    --main-footer-content-padding-bottom: 48px;
    --main-footer-content-padding-top: 48px;

    grid-template-areas:
        "left left"
        "right right"
        "logo logo"
        "disclaimer disclaimer"
        "bottom bottom";
    padding-bottom: var(--main-footer-content-padding-bottom);
    padding-top: var(--main-footer-content-padding-top);

    & :--list {
        --list-padding-left: 0;
    }

    & .left {
        grid-area: left;
    }

    & .right {
        grid-area: right;
    }

    & .disclaimer {
        --paragraph-bottom-margin: 0;

        grid-area: disclaimer;
    }

    & .legal-footer-section {
        grid-area: bottom;
    }

    & .logo {
        grid-area: logo;
    }

    & .trait-link-icon {
        margin-right: 8px;
        width: 32px;
    }

    @media (--denim-breakpoint-mobile) {
        & {
            & .disclaimer {
                &.before::before {
                    align-self: flex-start;
                    margin-top: 1px;
                }
            }

            & .right {
                margin-top: 32px;
            }

            & .logo {
                margin-top: 32px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            grid-template-areas:
                "left left left left left left right right right right right logo"
                "disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer"
                "bottom bottom bottom bottom bottom bottom bottom bottom bottom bottom bottom bottom";

            & .logo {
                justify-self: end;
                transform: rotate(-90deg) translateX(32px);
                transform-origin: bottom right;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            grid-template-areas:
                "left left left left left right right right right logo"
                "disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer"
                "bottom bottom bottom bottom bottom bottom bottom bottom bottom bottom";

            & .logo {
                justify-self: end;
                transform: rotate(-90deg) translateX(32px);
                transform-origin: bottom right;
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            grid-template-areas:
                "left left left right right logo"
                "disclaimer disclaimer disclaimer disclaimer disclaimer disclaimer"
                "bottom bottom bottom bottom bottom bottom";

            & .logo {
                justify-self: end;
                transform: rotate(-90deg) translateX(32px);
                transform-origin: bottom right;
            }

            & .disclaimer {
                &.before::before {
                    align-self: flex-start;
                    margin-top: 1px;
                }
            }
        }
    }
}

.footnote {
    --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-margin-bottom: 0;
    --card-padding-bottom: 16px;
    --card-padding-top: 16px;

    & .grid {
        & p {
            --paragraph-color: var(--genomics-color-shade-black, rgb(29 29 29));

            grid-column: 1 / -1;
            margin: 0;
        }
    }
}
