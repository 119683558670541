.registration {
    /* Height by which we offset the registration secion so the progressbar fades in from the top.
    It is equal to navbar height, except on mobile where we set it to zero, since we don't need an offset here */
    --offset-height: 0;

    grid-template-areas:
        "content content"
        "footer footer";
    grid-template-rows: 1fr auto;

    /* negative offset top margin by nav height to make the progressbar fade in from the navbar */
    margin-top: calc(var(--offset-height) * -1);

    & .loading-indicator {
        grid-column: 1 / -1;
    }

    & .progress {
        grid-area: progress;
    }

    & .content {
        grid-area: content;
    }

    & .footer {
        --registration-footer-bottom-margin: var(--minerva-registration-footer-vertical-margin, 32px);
        --registration-footer-top-margin: var(--minerva-registration-footer-vertical-margin, 32px);

        grid-area: footer;
        margin-bottom: var(--registration-footer-bottom-margin);
        margin-top: var(--registration-footer-top-margin);
    }

    & .footer-content {
        --footer-content-align-items: flex-start;
        --footer-content-flex-flow: column;
        --footer-content-justify-content: center;

        align-items: var(--footer-content-align-items);
        display: flex;
        flex-flow: var(--footer-content-flex-flow);
        justify-content: var(--footer-content-justify-content);

        & .disclaimer {
            margin-bottom: var(--font-size);

            & a {
                margin-left: calc(var(--font-size) / 2);
            }
        }
    }

    & .inline-form-group {
        margin-top: 32px;

        @media (--denim-breakpoint-desktop) {
            display: flex;
            gap: 16px;

            & .form-group {
                align-self: flex-start;
                flex-grow: 1;
                margin-top: 0;
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            display: flex;
            gap: 16px;

            & .form-group {
                align-self: flex-start;
                flex-grow: 1;
                margin-top: 0;
            }
        }
    }

    & .question-container {
        --question-container-margin-top: 32px;

        margin-top: var(--question-container-margin-top);

        & .back-link {
            --back-link-display: none;

            display: var(--back-link-display);
            margin-left: -7px;
            margin-top: 30px;

            & ~ .question {
                margin-top: 16px;
            }
        }

        @media (--denim-breakpoint-desktop) {
            & {
                --question-container-margin-top: 76px;

                & .back-link {
                    --back-link-display: inline-flex;
                }

                &.no-back-link {
                    --question-container-margin-top: 154px;
                }
            }
        }

        @media (--denim-breakpoint-tablet-landscape) {
            & {
                --question-container-margin-top: 76px;

                & .back-link {
                    --back-link-display: inline-flex;
                }

                &.no-back-link {
                    --question-container-margin-top: 154px;
                }
            }
        }
    }

    & .question-options {
        & > p:first-of-type {
            --paragraph-top-margin: 0;
        }
    }

    & .text-based-input {
        margin: 0;

        & form {
            grid-area: form;
        }
    }

    @media (--denim-breakpoint-mobile) {
        & {
            & .footer {
                --registration-footer-bottom-margin: 48px;
            }
        }
    }

    @media (--denim-breakpoint-desktop) {
        & {
            --offset-height: 76px;

            grid-template-areas:
                "progress progress progress content content content content content content content . ."
                "progress progress progress footer footer footer footer footer footer footer footer footer";

            & .loading-indicator {
                grid-column: 4 / -1;
            }

            & .text-based-input {
                display: grid;
                grid-column-gap: 32px;
                grid-template-areas: "form form form form . . .";
                grid-template-columns: repeat(7, minmax(0, 1fr));
            }

            & .footer-content {
                --footer-content-align-items: flex-start;
                --footer-content-justify-content: flex-start;

                & .disclaimer {
                    margin-bottom: 0;
                }

                & .legal-footer-section {
                    min-width: 50%;
                    text-align: left;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            --offset-height: 65px;

            grid-template-areas:
                "progress progress progress content content content content content . ."
                "progress progress progress footer footer footer footer footer footer footer";

            & .loading-indicator {
                grid-column: 4 / -1;
            }

            & .text-based-input {
                display: grid;
                grid-column-gap: 32px;
                grid-template-areas: "form form form form .";
                grid-template-columns: repeat(5, minmax(0, 1fr));
            }

            & .footer-content {
                --footer-content-align-items: flex-start;
                --footer-content-justify-content: flex-start;

                & .disclaimer {
                    margin-bottom: 0;
                }

                & .legal-footer-section {
                    min-width: 50%;
                    text-align: left;
                }
            }
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            grid-template-areas:
                ". content content content content ."
                ". footer footer footer footer .";
        }
    }
}
