/* Contains all the layouts that are used in Minerva… */

.page-layout {
    --nav-height: var(--denim-nav-height, 76px);
    /* stylelint-disable-next-line length-zero-no-unit */
    --subnav-height: 0px;

    display: grid;

    /* We need to "hard code" the nav-height because our navs are fixed positioned which remove them from the page flow
       resulting in height "0". */
    grid-template:
        "header" calc(var(--nav-height) + var(--subnav-height))
        "content" 1fr
        "footer" auto
        / 1fr;
    min-height: 100vh;
    width: 100%;

    & > .page-header {
        grid-area: header;

        /* So we can click through transparent nav on registration pages */
        pointer-events: none;
    }

    & > .page-content {
        grid-area: content;
    }

    & > .page-footer {
        grid-area: footer;
    }

    &.loading-indicator {
        grid-template-areas: "content";
        grid-template-rows: 100vh;
    }

    @media (--denim-breakpoint-max-tablet-portrait) {
        &.registration-page {
            --nav-height: 104px;
        }
    }

    @media (--denim-breakpoint-desktop) {
        --nav-height: 76px;

        & > .page-content {
            width: 100%;
        }

        &.report-page {
            --subnav-height: 72px;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --nav-height: 65px;

        &.report-page {
            --subnav-height: 64px;
        }
    }
}

.is-hidden {
    display: none;
}
