.sub-nav {
    pointer-events: auto;

    & ul {
        flex-shrink: 0;
    }

    & li:first-child {
        margin-left: 0;
    }
}

.separator {
    border-right: 1px solid var(--genomics-color-shade-mid-grey);
    margin-left: 32px;

    @media (--denim-breakpoint-mobile) {
        & {
            display: none;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & {
            display: none;
        }
    }
}

.trait {
    & a {
        &::before {
            background: linear-gradient(225deg, var(--top-color) 0%, var(--bottom-color) 100%);
            border-radius: 50%;
            content: " ";
            display: inline-block;
            height: 16px;
            margin-right: 6px;
            vertical-align: middle;
            width: 16px;
        }
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-CVD_US {
        --bottom-color: var(--minerva-cvd-us-dark);
        --top-color: var(--minerva-cvd-us-light);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-T2D {
        --bottom-color: var(--minerva-t2d-dark);
        --top-color: var(--minerva-t2d-light);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-BC {
        --bottom-color: var(--minerva-bc-dark);
        --top-color: var(--minerva-bc-light);
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-PC {
        --bottom-color: var(--minerva-pc-dark);
        --top-color: var(--minerva-pc-light);
    }
}

.reporting {
    & .loading-indicator {
        height: 100vh;
    }

    & + .footer {
        & .legal-footer-section {
            --legal-footer-section-margin-top: 32px;

            @media (--denim-breakpoint-desktop) {
                & {
                    --legal-footer-section-margin-top: calc(90px - var(--denim-list-item-vertical-margin));
                }
            }

            @media (--denim-breakpoint-tablet-landscape) {
                & {
                    --legal-footer-section-margin-top: calc(90px - var(--denim-list-item-vertical-margin));
                }
            }
        }
    }
}
