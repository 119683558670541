.trait-title {
    --card-margin-bottom: 0;
    --circle-offset: 24px;
    --card-padding-bottom: 48px;
    --card-padding-top: 48px;
    --circle-width: 645px;

    position: relative;

    &::before {
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        top: 0;
        transform: rotate(180deg);
        width: 100%;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-CVD_US {
        --card-background-image: url("/images/trait-title-bg-CVD_US.png");
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-T2D {
        --card-background-image: url("/images/trait-title-bg-T2D.png");
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-BC {
        --card-background-image: url("/images/trait-title-bg-BC.png");
    }

    /* stylelint-disable-next-line selector-class-pattern */
    &.trait-PC {
        --card-background-image: url("/images/trait-title-bg-PC.png");
    }

    & .grid {
        grid-template-areas:
            "title title"
            "summary summary";
        text-align: center;

        & .title {
            grid-area: title;

            & h1 {
                --heading-margin: 8px 0 24px 0;
            }

            & .trait-icon {
                width: 96px;
            }
        }

        & .summary {
            grid-area: summary;
            margin: 0;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        &::before {
            background-image: var(--card-background-image);
            background-position-x: calc(100% + (var(--circle-width) / 2) + var(--circle-offset));
            background-position-y: 130%;
            background-size: auto 224px;
            height: 100%;
        }

        & .grid {
            grid-template-areas:
                ". title title title title ."
                "summary summary summary summary summary summary";
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        &::before {
            background-image: var(--card-background-image);
            background-position-x: calc(100% + (var(--circle-width) / 2) + var(--circle-offset));
            background-position-y: 130%;
            background-size: auto 70%;
            height: 100%;
        }

        & .grid {
            grid-template-areas:
                ". . . title title title title . . ."
                ". summary summary summary summary summary summary summary summary .";
        }
    }

    @media (--denim-breakpoint-desktop) {
        --grid-max-width: 1072px;
        --card-padding-bottom: 64px;
        --card-padding-top: 64px;

        background-image: var(--card-background-image);
        background-position-x: calc(((100% - var(--grid-max-width)) / 2) - (var(--circle-width) / 2) + var(--circle-offset));
        background-repeat: no-repeat;
        background-size: contain;
        max-height: 256px;

        & .grid {
            grid-template-areas: "title title title title title title summary summary summary summary summary summary";
            text-align: initial;

            & .title {
                align-items: center;
                display: flex;

                & h1 {
                    --heading-margin: 0 0 0 24px;
                }
            }

            & .summary {
                border-image: linear-gradient(to bottom, var(--top-color), var(--bottom-color)) 1 100%;
                border-left: 1px solid;
                padding: 12px 0 12px 64px;

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-CVD_US {
                    --top-color: var(--minerva-cvd-us-dark);
                    --bottom-color: var(--minerva-cvd-us-light);
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-T2D {
                    --top-color: var(--minerva-t2d-dark);
                    --bottom-color: var(--minerva-t2d-light);
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-BC {
                    --top-color: var(--minerva-bc-dark);
                    --bottom-color: var(--minerva-bc-light);
                }

                /* stylelint-disable-next-line selector-class-pattern */
                &.trait-PC {
                    --top-color: var(--minerva-pc-dark);
                    --bottom-color: var(--minerva-pc-light);
                }
            }
        }
    }
}
