.nav-overlay {
    --nav-overlay-background-color: var(--denim-nav-overlay-background-color, rgb(17 0 53));
    --nav-overlay-vertical: var(--denim-nav-overlay-vertical, 0);

    background-color: var(--nav-overlay-background-color);
    bottom: var(--nav-overlay-vertical);
    overflow-y: scroll;
    z-index: 3;

    &.variant-light {
        --nav-overlay-background-color: var(--genomics-color-shade-white, rgb(255 255 255));

        & .icon {
            --icon-background-color: var(--genomics-color-shade-black, rgb(29 29 29));
        }

        & .link {
            --link-color: var(--genomics-color-shade-black, rgb(29 29 29));
        }

        & span {
            color: var(--genomics-color-shade-black, rgb(29 29 29));
        }

        & .is-dark {
            --color: var(--genomics-color-shade-black, rgb(29 29 29));
        }
    }

    & .nav-modal {
        --font-family: var(--denim-font-family-alternate, SK-Modernist, sans-serif);
        --nav-modal-outline: var(--denim-nav-modal-outline, none);

        display: flex;
        flex-direction: column;
        font-family: var(--font-family);
        height: 100%;
        outline: var(--nav-modal-outline);

        & .nav-footer {
            --nav-footer-height: var(--denim-nav-footer-height, 100%);
            --nav-footer-margin-top: var(--denim-nav-footer-margin-top, 32px);
            --nav-footer-position: var(--denim-nav-footer-position, relative);

            height: var(--nav-footer-height);
            margin-top: var(--nav-footer-margin-top);
            position: var(--nav-footer-position);

            & .nav-footer-content {
                --nav-footer-content-bottom: var(--denim-nav-content-footer-bottom, 16px);
                --nav-footer-content-position: var(--denim-nav-content-footer-position, absolute);

                bottom: var(--nav-footer-content-bottom);
                position: var(--nav-footer-content-position);
            }
        }

        & .nav-menu {
            --nav-menu-list-font-size: var(--denim-nav-menu-list-font-size, 22px);
            --nav-menu-list-style-type: var(--denim-nav-menu-list-style-type, none);
            --nav-menu-margin-top: var(--denim-nav-menu-margin-top, 28px);
            --nav-menu-padding-bottom: var(--denim-nav-menu-padding-bottom, 32px);
            --nav-menu-padding-left: var(--denim-nav-menu-padding-left, 0);

            margin-top: var(--nav-menu-margin-top);

            & ul {
                list-style-type: var(--nav-menu-list-style-type);
                padding-left: var(--nav-menu-padding-left);

                & li {
                    font-size: var(--nav-menu-list-font-size);
                    padding-bottom: var(--nav-menu-padding-bottom);
                }
            }
        }
    }
}
