.icon {
    &.caret--down {
        --icon-caret--down: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve"><polygon points="24,12 16,22 8,12 "/></svg>');

        &.before::before {
            mask: var(--icon-caret--down) no-repeat;
        }

        &.after::after {
            mask: var(--icon-caret--down) no-repeat;
        }
    }
}
