.icon {
    &.hamburger {
        --icon-hamburger: url('data:image/svg+xml;utf8,<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><rect x="0.799805" width="30.4" height="2" /><rect x="0.799805" y="15" width="30.4" height="2" /><rect x="0.799805" y="30" width="30.4" height="2" /></svg>');

        &.before::before {
            mask: var(--icon-hamburger) no-repeat;
        }

        &.after::after {
            mask: var(--icon-hamburger) no-repeat;
        }
    }
}
