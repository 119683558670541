.docusign {
    --docusign-background-color: var(--denim-background-color, rgb(17 0 53));

    background-color: var(--docusign-background-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px;
    display: grid;
    grid-template-areas:
        "nav"
        "docusign"
        "footer";
    grid-template-rows: auto 1fr auto;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;

    & header {
        grid-area: nav;

        & nav {
            position: static;
        }
    }

    & iframe {
        grid-area: docusign;
        height: 100%;
        width: 100%;
    }

    & .frame-loading {
        display: grid;
        grid-area: docusign;
        height: 100%;
        width: 100%;
    }

    & footer {
        grid-area: footer;

        &.footer {
            margin-top: 0;
        }

        & .footer-content {
            justify-content: flex-end;
            margin-left: var(--grid-horizontal-margin);
            margin-right: var(--grid-horizontal-margin);
            max-width: var(--denim-grid-max-width);
        }

        & .legal-footer-section {
            --legal-footer-section-margin-top: 16px;
        }
    }
}
