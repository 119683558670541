.card {
    --card-background-color: var(--denim-card-background-color, rgb(239 239 239));
    --card-border-radius: var(--denim-card-border-radius, 8px);
    --card-color: var(--denim-card-color, rgb(29 29 29));
    --card-margin-bottom: var(--denim-card-margin-bottom, 1rem);
    --card-padding-bottom: var(--denim-card-padding-bottom, 32px);
    --card-padding-left: var(--denim-card-padding-left, 24px);
    --card-padding-right: var(--denim-card-padding-right, 24px);
    --card-padding-top: var(--denim-card-padding-top, 32px);

    background-color: var(--card-background-color);
    border-radius: var(--card-border-radius);
    color: var(--card-color);
    margin-bottom: var(--card-margin-bottom);
    padding: var(--card-padding-top) var(--card-padding-right) var(--card-padding-bottom) var(--card-padding-left);

    &.no-bottom-margin {
        --card-margin-bottom: 0;
    }

    &.intent-danger {
        --card-background-color: var(--denim-intent-danger);
        --card-color: rgb(255 255 255);

        & a {
            color: var(--card-color);
        }
    }

    &.intent-info {
        --card-background-color: var(--denim-intent-info);
        --card-color: rgb(255 255 255);
    }

    &.intent-success {
        --card-background-color: var(--denim-intent-success);
        --card-color: rgb(255 255 255);
    }

    &.intent-warning {
        --card-background-color: var(--denim-intent-warning);
        --card-color: rgb(29 29 29);

        & :--heading {
            --heading-color: rgb(29 29 29);
        }
    }

    &.intent-primary {
        --card-background-color: var(--minerva-color-light-navy, rgb(50 0 156));
        --card-color: var(--genomics-color-shade-white, rgb(255 255 255));
    }

    &.no-background {
        background: none;
        border: 3px solid var(--card-background-color);
    }
}

.error-card {
    margin-bottom: 32px;
    padding-top: 24px;

    & .title {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-left: calc(-1 * var(--card-padding-left));

        & .indicator {
            align-self: flex-start;
            background-color: var(--minerva-color-error-on-white);
            border-radius: 0 4px 4px 0;
            flex-shrink: 1;

            & .icon {
                --icon-size: 32px;

                padding: 8px;

                &.before::before {
                    --icon-before-margin: 0;
                }
            }
        }

        & .text {
            color: var(--minerva-color-error-on-white);
            flex-grow: 1;
            margin-bottom: 0;
            margin-left: 16px;
        }
    }

    & .error-details {
        margin: 16px 0 0 40px;

        & p:first-of-type {
            margin-top: 0;
        }

        & p:last-of-type {
            margin-bottom: 0;
        }

        & a {
            --link-color: var(--card-color);

            &.icon {
                --icon-background-color: var(--card-color);
            }
        }
    }
}

.info-card {
    align-items: flex-start;
    display: flex;
}
