.grid-container {
    --card-background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
    --card-border-radius: 0;
    --card-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-padding-left: 0;
    --card-padding-right: 0;

    &.variant-light {
        --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
        --card-color: var(--genomics-color-primary-navy, rgb(17 0 53));

        & :--heading {
            --heading-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        }

        & .link {
            --link-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
        }
    }
}
