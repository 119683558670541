.access-code-input {
    --authorization-code-input-background-color: var(--minerva-authorization-code-input-background-color, rgb(51 255 196));
    --authorization-code-input-border-color: var(--minerva-authorization-code-input-border-color, rgb(51 255 196));
    --authorization-code-input-border-radius: var(--minerva-authorization-code-input-border-radius, 0);
    --authorization-code-input-border-style: var(--minerva-authorization-code-input-border-style, solid);
    --authorization-code-input-border-width: var(--minerva-authorization-code-input-border-width, 2px);
    --authorization-code-input-button-color: var(--minerva-authorization-code-input-button-color, rgb(17 0 53));
    --authorization-code-input-label-color: var(--minerva-authorization-code-input-label-color, var(--genomics-color-shade-white, rgb(255 255 255)));

    & .label {
        --form-group-label-color: var(--authorization-code-input-label-color);
    }

    & .form-group {
        & .is-error {
            margin-top: 8px;
        }
    }

    & .controls {
        --authorization-code-input-controls-align-items: flex-start;
        --authorization-code-input-controls-flex-direction: column;
        --authorization-code-input-controls-justify-content: center;

        align-items: var(--authorization-code-input-controls-align-items);
        display: flex;
        flex-flow: var(--authorization-code-input-controls-flex-direction) nowrap;
        justify-content: var(--authorization-code-input-controls-justify-content);
        margin-bottom: 0;
    }

    & .text-input {
        --text-input-border-color: var(--authorization-code-input-border-color);
        --text-input-border-radius: var(--authorization-code-input-border-radius);
        --text-input-border-style: var(--authorization-code-input-border-style);
        --text-input-border-width: var(--authorization-code-input-border-width);
        --text-input-box-shadow-blur-radius: 0;
        --text-input-box-shadow-spread-radius: 0;

        /** Without this, the text input is 4px to big vertically. */
        line-height: 22px;
    }

    & .button {
        --button-background-color: var(--authorization-code-input-background-color, rgb(51 255 196));
        --button-border-color: var(--authorization-code-input-border-color);
        --button-border-width: var(--authorization-code-input-border-width);
        --button-color: var(--authorization-code-input-button-color, rgb(17 0 53));
        --button-margin-top: 8px;
        --button-min-width: 100%;

        margin-top: var(--button-margin-top);
        min-width: var(--button-min-width);
        outline: none;
        word-break: keep-all;
    }

    &.variant-secondary {
        --authorization-code-input-background-color: var(--minerva-authorization-code-input-secondary-background-color, rgb(13 94 255));
        --authorization-code-input-border-color: var(--minerva-authorization-code-input-secondary-border-color, rgb(13 94 255));
        --authorization-code-input-button-color: var(--minerva-authorization-code-input-secondary-button-color, rgb(255 255 255));
        --authorization-code-input-label-color: var(--minerva-authorization-code-input-secondary-label-color, var(--genomics-color-primary-navy, rgb(17 0 53)));
    }

    @media (--denim-breakpoint-desktop) {
        & {
            & .controls {
                --authorization-code-input-controls-align-items: center;
                --authorization-code-input-controls-flex-direction: row;
                --authorization-code-input-controls-justify-content: flex-start;
            }

            & .button {
                --button-margin-top: 0;
                --button-min-width: fit-content;
            }
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & {
            & .controls {
                --authorization-code-input-controls-align-items: center;
                --authorization-code-input-controls-flex-direction: row;
                --authorization-code-input-controls-justify-content: flex-start;
            }

            & .button {
                --button-margin-top: 0;
                --button-min-width: fit-content;
            }
        }
    }
}
