.icon {
    &.screen {
        --icon-screen: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M28 4H4a2 2 0 00-2 2v16a2 2 0 002 2h8v4H8v2h16v-2h-4v-4h8a2 2 0 002-2V6a2 2 0 00-2-2zM18 28h-4v-4h4zm10-6H4V6h24z"/></svg>');

        &.before::before {
            mask: var(--icon-screen) no-repeat;
        }

        &.after::after {
            mask: var(--icon-screen) no-repeat;
        }
    }
}
