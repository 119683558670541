.download-reports-grid {
    --card-background-color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
    --card-margin-bottom: 0;
    --card-padding-bottom: 32px;
    --card-padding-top: 32px;
    --denim-grid-row-gap: 32px;

    & .grid {
        grid-template-areas:
            "description description"
            "button button";
    }

    & .download-reports-content {
        grid-area: description;

        & .description {
            flex-direction: column;
            justify-self: left;

            & * {
                margin: 0;
            }
        }
    }

    & .download-reports-button {
        grid-area: button;
    }

    & .icon {
        --icon-align-items: start;
        --icon-size: 40px;

        align-items: var(--icon-align-items);
    }

    @media (--denim-breakpoint-desktop) {
        & .grid {
            grid-template-areas: "description description description description description description description description . button button button";
        }

        & .download-reports-button {
            align-self: center;
            height: fit-content;
            position: relative;
            right: var(--grid-column-gap);
            width: calc(100% + var(--grid-column-gap));
        }

        & .icon {
            --icon-align-items: center;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            grid-template-areas: "description description description description description description . button button button";
        }

        & .download-reports-button {
            align-self: center;
            height: fit-content;
            position: relative;
            right: var(--grid-column-gap);
            width: calc(100% + var(--grid-column-gap));
        }

        & .icon {
            --icon-align-items: center;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas:
                "description description description description description description"
                "button button button button button button";
        }
    }
}
