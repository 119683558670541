.icon {
    &.download {
        --icon-download: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M26 24v4H6v-4H4v4a2 2 0 002 2h20a2 2 0 002-2v-4zm0-10l-1.41-1.41L17 20.17V2h-2v18.17l-7.59-7.58L6 14l10 10 10-10z"/></svg>');

        &.before::before {
            mask: var(--icon-download) no-repeat;
        }

        &.after::after {
            mask: var(--icon-download) no-repeat;
        }
    }
}
