.icon {
    &.trash-can {
        --icon-trash-can: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M12 12h2v12h-2zm6 0h2v12h-2z"/><path d="M4 6v2h2v20a2 2 0 002 2h16a2 2 0 002-2V8h2V6zm4 22V8h16v20zm4-26h8v2h-8z"/></svg>');

        &.before::before {
            mask: var(--icon-trash-can) no-repeat;
        }

        &.after::after {
            mask: var(--icon-trash-can) no-repeat;
        }
    }
}
