.icon {
    &.warning {
        --icon-warning: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 2a14 14 0 1014 14A14 14 0 0016 2zm0 26a12 12 0 1112-12 12 12 0 01-12 12z"/><path d="M15 8h2v11h-2zm1 14a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 22z"/></svg>');

        &.before::before {
            mask: var(--icon-warning) no-repeat;
        }

        &.after::after {
            mask: var(--icon-warning) no-repeat;
        }
    }
}
