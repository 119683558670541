.form-group {
    --form-group-align-self: stretch;
    --form-group-description-color: var(--denim-form-group-description-color, inherit);
    --form-group-label-color: var(--denim-form-group-label-color, inherit);

    align-items: flex-start;
    align-self: var(--form-group-align-self);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    & .labels {
        & :not(:first-child) {
            margin-top: 4px;
        }
    }

    & .label {
        color: var(--form-group-label-color);
    }

    & .description {
        color: var(--form-group-description-color);
    }

    & .controls {
        margin: 8px 0;
        position: relative;
        width: 100%;

        &.inline {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
        }

        & .prefix {
            color: color-mod(var(--genomics-color-shade-black) alpha(50%));
            left: 10px;
            line-height: 0;
            position: absolute;
            top: 50%;
        }
    }

    &.remove-controls-bottom-margin {
        & .controls {
            margin-bottom: 0;
        }
    }

    & .is-error {
        --indicator-height: 26px;

        align-items: center;
        display: flex;
        justify-content: flex-start;
        width: 100%;

        & .indicator {
            align-self: flex-start;
            flex-shrink: 1;

            /* For some reason it's rendering with a height of 30px, which is throwing everything else off... */
            height: var(--indicator-height);

            & .icon {
                --icon-size: var(--indicator-height);

                &.before::before {
                    --icon-before-margin: 4px;
                }
            }
        }
    }

    &:not(:first-of-type),
    & ~ .button {
        margin-top: 32px;
    }

    &.conditional-reveal {
        margin-left: 32px;
        margin-top: 0;
    }

    & .option-divider {
        padding: 6px 4px;
    }
}

.text-input {
    --text-input-background-color: var(--denim-input-background-color, rgb(255 255 255));
    --text-input-color: var(--denim-input-color, rgb(29 29 29));
    --text-input-border-color: var(--denim-input-border-color, rgb(189 189 189));
    --text-input-border-radius: var(--denim-input-border-radius, 4px);
    --text-input-border-style: var(--denim-input-border-style, solid);
    --text-input-border-width: var(--denim-input-border-width, 2px);
    --text-input-box-shadow-offset: var(--denim-input-box-shadow-offset, 0 0);
    --text-input-box-shadow-blur-radius: var(--denim-input-box-shadow-blur-radius, 0);
    --text-input-box-shadow-spread-radius: var(--denim-input-box-shadow-spread-radius, 2px);
    --text-input-box-shadow-inner-color: var(--denim-input-box-shadow-inner-color, transparent);
    --text-input-box-shadow-outter-color: var(--denim-input-box-shadow-outter-color, transparent);
    --text-input-min-height: var(--denim-text-input-min-height, 44px);
    --text-input-padding-horizontal: var(--denim-text-input-padding-horizontal, 8px);
    --text-input-padding-vertical: var(--denim-text-input-padding-vertical, 9px);
    --text-input-width: 100%;

    background-color: var(--text-input-background-color);
    border: var(--text-input-border-color) var(--text-input-border-style) var(--text-input-border-width);
    border-radius: var(--text-input-border-radius);
    box-shadow:
        var(--text-input-box-shadow-offset) var(--text-input-box-shadow-blur-radius) var(--text-input-box-shadow-spread-radius) var(--text-input-box-shadow-inner-color),
        var(--text-input-box-shadow-offset) var(--text-input-box-shadow-blur-radius) calc(var(--text-input-box-shadow-spread-radius) * 2) var(--text-input-box-shadow-outter-color);
    color: var(--text-input-color);
    min-height: var(--text-input-min-height);
    padding: var(--text-input-padding-vertical) var(--text-input-padding-horizontal);
    width: var(--text-input-width);

    &.invalid {
        --text-input-border-color: var(--minerva-intent-danger, rgb(252 65 131));
        --text-input-box-shadow-inner-color: var(--denim-input-invalid-focused-box-shadow-inner-color, rgb(17 0 53));
        --text-input-box-shadow-outter-color: var(--denim-input-invalid-focused-box-shadow-outter-color, rgb(252 65 131 / 50%));
    }

    &.has-prefix {
        padding-left: calc(3ch + var(--text-input-padding-horizontal));
    }

    &:focus {
        --text-input-border-color: var(--denim-focus-border-color, rgb(51 255 196));
        --text-input-box-shadow-inner-color: var(--denim-input-focused-box-shadow-inner-color, rgb(17 0 53));
        --text-input-box-shadow-outter-color: var(--denim-input-focused-box-shadow-outter-color, rgb(51 255 196 / 50%));

        outline: none;
    }
}

.auto-complete-text-input {
    --auto-complete-text-input-background-color: var(--denim-auto-complete-text-input-background-color, rgb(51 255 196));
    --auto-complete-text-input-border-radius: var(--denim-auto-complete-text-input-border-radius, 4px);
    --auto-complete-text-input-color: var(--denim-auto-complete-text-input-color, rgb(17 0 53));
    --auto-complete-text-input-filter: var(--denim-auto-complete-text-input-filter, brightness(0.9));
    --auto-complete-text-input-list-item-margin: var(--denim-auto-complete-text-input-list-item-margin, 0);
    --auto-complete-text-input-list-item-padding: var(--denim-auto-complete-text-input-list-item-padding, 16px);
    --auto-complete-text-input-list-item-width: var(--denim-auto-complete-text-input-list-item-width, 100%);
    --auto-complete-text-input-list-margin: var(--denim-auto-complete-text-input-list-margin, 0);
    --auto-complete-text-input-list-padding: var(--denim-auto-complete-text-input-list-padding, 0);
    --auto-complete-text-input-margin-bottom: var(--denim-auto-complete-text-input-margin-bottom, 0);
    --auto-complete-text-input-margin-top: var(--denim-auto-complete-text-input-margin-top, 1px);
    --auto-complete-text-input-overlay-position: var(--denim-auto-complete-text-input-overlay-position, relative);
    --auto-complete-text-input-overlay-width: var(--denim-auto-complete-text-input-overlay-width, 100%);
    --auto-complete-text-input-position: var(--denim-auto-complete-text-input-position, relative);
    --auto-complete-text-input-z-index: var(--denim-auto-complete-text-input-z-index, 1);

    position: var(--auto-complete-text-input-position);

    & .auto-complete-text-input-overlay {
        background-color: var(--auto-complete-text-input-background-color);
        border-radius: var(--auto-complete-text-input-border-radius);
        margin-top: var(--auto-complete-text-input-margin-top);
        position: var(--auto-complete-text-input-overlay-position);
        width: var(--auto-complete-text-input-overlay-width);
        z-index: var(--auto-complete-text-input-z-index);

        & ul {
            list-style-type: none;
            margin: var(--auto-complete-text-input-list-margin);
            padding: var(--auto-complete-text-input-list-padding);

            & li {
                background-color: var(--auto-complete-text-input-background-color);
                border-radius: var(--auto-complete-text-input-border-radius);
                color: var(--auto-complete-text-input-color);
                margin: var(--auto-complete-text-input-list-item-margin);
                padding: var(--auto-complete-text-input-list-item-padding);
                width: var(--auto-complete-text-input-list-item-width);

                &.highlighted {
                    filter: var(--auto-complete-text-input-filter);
                }
            }

            & .no-options-found {
                --auto-complete-text-input-background-color: rgb(189 189 189);
                --icon-background-color: var(--auto-complete-text-input-color);
            }
        }
    }

    & ~ .button {
        margin-top: 32px;
    }
}

.date-layout {
    align-items: flex-end;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    & .date-portion {
        align-items: flex-start;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin-right: var(--font-size);

        & .text-input {
            margin-top: 4px;

            &.day,
            &.month {
                width: calc(var(--font-size) * 3);
            }

            &.year {
                width: calc(var(--font-size) * 5);
            }
        }
    }

    &.day-month-year {
        & .date-portion {
            &.day {
                order: -1;
            }
        }
    }

    &.year-month-day {
        & .date-portion {
            &.day {
                order: 3;
            }

            &.month {
                order: 2;
            }

            &.year {
                order: 1;
            }
        }
    }
}

.checkbox {
    --checkbox-background-color: var(--denim-input-background-color, transparent);
    --checkbox-border-color: var(--denim-input-border-color, rgb(189 189 189));
    --checkbox-border-radius: var(--denim-input-border-radius, 4px);
    --checkbox-border-style: var(--denim-input-border-style, solid);
    --checkbox-border-width: var(--denim-input-border-width, 2px);
    --checkbox-box-shadow-offset: var(--denim-input-box-shadow-offset, 0 0);
    --checkbox-box-shadow-blur-radius: var(--denim-input-box-shadow-blur-radius, 0);
    --checkbox-box-shadow-spread-radius: var(--denim-input-box-shadow-spread-radius, 2px);
    --checkbox-box-shadow-inner-color: var(--denim-input-box-shadow-inner-color, transparent);
    --checkbox-box-shadow-outter-color: var(--denim-input-box-shadow-outter-color, transparent);
    --checkbox-height: var(--denim-checkbox-height, 24px);
    --checkbox-width: var(--denim-checkbox-width, 24px);
    --checkbox-label-margin: var(--denim-checkbox-label-margin, 8px);
    --checkbox-check-width: var(--denim-checkbox-check-width, 8px);
    --checkbox-check-height: var(--denim-checkbox-check-height, 16px);
    --checkbox-check-color: var(--denim-checkbox-check-color, rgb(17 0 53));
    --checkbox-check-border-width: var(--denim-checkbox-check-border-width, 3px);
    --checkbox-indeterminate-width: var(--denim-checkbox-indeterminate-width, 16px);

    padding: 9px 0;
    position: relative;

    & label {
        cursor: pointer;
        display: inline-block;
        padding: 2px calc(var(--checkbox-width) + var(--checkbox-label-margin));
        position: relative;
        user-select: none;

        &::before {
            /* style `::before` to appear like checkbox */
            background-color: var(--checkbox-background-color);
            border: var(--checkbox-border-color) var(--checkbox-border-style) var(--checkbox-border-width);
            border-radius: var(--checkbox-border-radius);
            box-shadow:
                var(--checkbox-box-shadow-offset) var(--checkbox-box-shadow-blur-radius) var(--checkbox-box-shadow-spread-radius) var(--checkbox-box-shadow-inner-color),
                var(--checkbox-box-shadow-offset) var(--checkbox-box-shadow-blur-radius) calc(var(--checkbox-box-shadow-spread-radius) * 2) var(--checkbox-box-shadow-outter-color);
            content: "";
            display: block;
            height: var(--checkbox-height);
            left: 0;
            position: absolute;
            top: 0;
            transition: background-color 0.2s ease-out;
            width: var(--checkbox-width);
        }
    }

    & input[type="checkbox"] {
        height: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 0;

        &:focus + label::before {
            --checkbox-border-color: var(--denim-focus-border-color, rgb(51 255 196));
            --checkbox-box-shadow-inner-color: var(--denim-input-focused-box-shadow-inner-color, rgb(17 0 53));
            --checkbox-box-shadow-outter-color: var(--denim-input-focused-box-shadow-outter-color, rgb(51 255 196 / 50%));

            outline: none;
        }

        &:checked + label::before,
        &[aria-checked] + label::before,
        &:indeterminate + label::before {
            --checkbox-background-color: var(--denim-focus-border-color, rgb(51 255 196));
            --checkbox-border-color: var(--denim-focus-border-color, rgb(51 255 196));
        }

        &:checked + label::after,
        &[aria-checked] + label::after {
            /* style `::after` to look like a check mark */
            border: solid var(--checkbox-check-color);
            border-width: 0 var(--checkbox-check-border-width) var(--checkbox-check-border-width) 0;
            content: "";
            display: block;
            height: var(--checkbox-check-height);
            left: 11px;
            position: absolute;
            top: 13px;
            transform: rotate(45deg) translate(-50%, -50%);
            transform-origin: 50% 0;
            width: var(--checkbox-check-width);
        }

        &:indeterminate + label::after {
            /* style `::after` to look like a hyphen */
            background-color: var(--checkbox-check-color);
            content: "";
            display: block;
            height: var(--checkbox-check-border-width);
            left: calc(var(--checkbox-width) / 2);
            position: absolute;
            top: 0%;
            transform: translate(-50%, 50%);
            width: var(--checkbox-indeterminate-width);
        }
    }

    & .description {
        margin-left: calc(var(--checkbox-width) + var(--checkbox-label-margin));
        margin-top: 2px;
    }
}

.radio-button {
    --radio-background-color: var(--denim-input-background-color, transparent);
    --radio-border-color: var(--denim-input-border-color, rgb(189 189 189));
    --radio-border-radius: var(--denim-input-border-radius, 50%);
    --radio-border-style: var(--denim-input-border-style, solid);
    --radio-border-width: var(--denim-input-border-width, 2px);
    --radio-box-shadow-offset: var(--denim-input-box-shadow-offset, 0 0);
    --radio-box-shadow-blur-radius: var(--denim-input-box-shadow-blur-radius, 0);
    --radio-box-shadow-spread-radius: var(--denim-input-box-shadow-spread-radius, 2px);
    --radio-box-shadow-inner-color: var(--denim-input-box-shadow-inner-color, transparent);
    --radio-box-shadow-outter-color: var(--denim-input-box-shadow-outter-color, transparent);
    --radio-height: var(--denim-radio-height, 24px);
    --radio-width: var(--denim-radio-width, 24px);
    --radio-label-margin: var(--denim-radio-label-margin, 8px);
    --radio-check-background-color: var(--denim-radio-check-color, rgb(51 255 196));
    --radio-check-border-radius: var(--denim-radio-check-border-width, 50%);
    --radio-check-height: var(--denim-radio-check-height, 12px);
    --radio-check-width: var(--denim-radio-check-width, 12px);

    padding: 9px 0;
    position: relative;

    & label {
        cursor: pointer;
        padding: 2px calc(var(--radio-width) + var(--radio-label-margin));
        position: relative;
        user-select: none;

        /* stylelint-disable-next-line no-descending-specificity */
        &::before {
            /* style `::before` to look like a radio button */
            background-color: var(--radio-background-color);
            border: var(--radio-border-color) var(--radio-border-style) var(--radio-border-width);
            border-radius: var(--radio-border-radius);
            box-shadow:
                var(--radio-box-shadow-offset) var(--radio-box-shadow-blur-radius) var(--radio-box-shadow-spread-radius) var(--radio-box-shadow-inner-color),
                var(--radio-box-shadow-offset) var(--radio-box-shadow-blur-radius) calc(var(--radio-box-shadow-spread-radius) * 2) var(--radio-box-shadow-outter-color);
            content: "";
            display: block;
            height: var(--radio-height);
            left: 0;
            position: absolute;
            top: 0;
            transition: background-color 0.2s ease-out;
            width: var(--radio-width);
        }
    }

    & .description {
        margin-left: calc(var(--radio-width) + var(--radio-label-margin));
        margin-top: 2px;
    }

    & input[type="radio"] {
        /* visually hide input */
        height: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 0;

        &:focus + label::before {
            --radio-border-color: var(--denim-focus-border-color, rgb(51 255 196));
            --radio-box-shadow-inner-color: var(--denim-input-focused-box-shadow-inner-color, rgb(17 0 53));
            --radio-box-shadow-outter-color: var(--denim-input-focused-box-shadow-outter-color, rgb(51 255 196 / 50%));

            outline: none;
        }

        &:checked + label::before,
        &[aria-checked] + label::before {
            --radio-background-color: var(--denim-focus-border-color, rgb(17 0 53));
            --radio-border-color: var(--denim-focus-border-color, rgb(51 255 196));
        }

        &:checked + label::after,
        &[aria-checked] + label::after {
            /* style `::after` to look like radio button fill */
            background-color: var(--radio-check-background-color);
            border-radius: var(--radio-check-border-radius);
            content: "";
            display: block;
            height: var(--radio-check-height);
            left: calc(var(--radio-width) / 2);
            position: absolute;
            top: 0%;
            transform: translate(-50%, 50%);
            width: var(--radio-check-width);
        }
    }
}

.radio-button,
.checkbox {
    & .conditional-reveal {
        border-left: 2px solid rgb(189 189 189);
        margin-left: 12px;
        margin-top: 8px;
        padding: 8px 20px;
    }

    & input[type="radio"],
    & input[type="checkbox"] {
        & ~ .conditional-reveal {
            display: none;
        }

        &:checked ~ .conditional-reveal {
            display: block;
        }
    }
}
