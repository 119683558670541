.icon {
    &.report--data {
        --icon-report--data: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M15 20h2v4h-2zm5-2h2v6h-2zm-10-4h2v10h-2z"/><path d="M25 5h-3V4a2 2 0 00-2-2h-8a2 2 0 00-2 2v1H7a2 2 0 00-2 2v21a2 2 0 002 2h18a2 2 0 002-2V7a2 2 0 00-2-2zM12 4h8v4h-8zm13 24H7V7h3v3h12V7h3z"/></svg>');

        &.before::before {
            mask: var(--icon-report--data) no-repeat;
        }

        &.after::after {
            mask: var(--icon-report--data) no-repeat;
        }
    }
}
