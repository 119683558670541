.icon {
    &.arrows--vertical {
        --icon-arrows--vertical: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M27.6 20.6L24 24.2V4h-2v20.2l-3.6-3.6L17 22l6 6 6-6zM9 4l-6 6 1.4 1.4L8 7.8V28h2V7.8l3.6 3.6L15 10z"/></svg>');

        &.before::before {
            mask: var(--icon-arrows--vertical) no-repeat;
        }

        &.after::after {
            mask: var(--icon-arrows--vertical) no-repeat;
        }
    }
}
