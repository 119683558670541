.button {
    --button-background-color: var(--denim-button-background-color, transparent);
    --button-border-color: var(--denim-button-border-color, rgb(255 255 255));
    --button-border-width: var(--denim-button-border-width, 1px);
    --button-color: var(--denim-button-color, rgb(255 255 255));
    --button-filter: var(--denim-button-filter, brightness(0.9));
    --button-font-family: var(--denim-font-family, Inter, sans-serif);
    --button-icon-size: var(--denim-button-icon-size, 1.1111em);
    --button-justify-content: var(--denim-button-justify-content, center);
    --button-min-height: var(--denim-button-min-height, 44px);
    --button-min-width: var(--denim-button-min-width, 100%);
    --button-outline-color: var(--denim-button-outline-color, rgba(255 255 255 / 50%));
    --button-outline-offset: var(--denim-button-outline-offset, 3px);
    --button-outline-style: var(--denim-button-outline-style, solid);
    --button-outline-width: var(--denim-button-outline-width, 3px);
    --button-padding-left: var(--denim-button-padding-left, 16px);
    --button-padding-right: var(--denim-button-padding-right, 16px);
    --button-text-decoration: var(--denim-button-text-decoration, underline);

    background-color: var(--button-background-color);
    border-color: var(--button-border-color);
    border-width: var(--button-border-width);
    color: var(--button-color);
    cursor: pointer;
    font-family: var(--button-font-family);
    min-height: var(--button-min-height);
    min-width: var(--button-min-width);

    @media (--denim-breakpoint-desktop) {
        --button-min-width: fit-content(150px);

        padding-left: var(--button-padding-left);
        padding-right: var(--button-padding-right);
    }

    @media (--denim-breakpoint-tablet-landscape) {
        --button-min-width: fit-content(150px);

        padding-left: var(--button-padding-left);
        padding-right: var(--button-padding-right);
    }

    &:focus {
        outline: var(--button-outline-color) var(--button-outline-style) var(--button-outline-width);
        outline-offset: var(--button-outline-offset);
    }

    &:hover {
        filter: var(--button-filter);
        text-decoration: var(--button-text-decoration);
    }

    &.icon {
        --icon-background-color: rgb(255 255 255);
        --icon-size: var(--button-icon-size);

        justify-content: var(--button-justify-content);
    }

    &.primary {
        --button-background-color: rgb(51 255 196);
        --button-border-color: transparent;
        --button-color: rgb(17 0 53);
        --button-outline-color: rgba(51 255 196 / 50%);

        &.icon {
            --icon-background-color: rgb(17 0 53);
            --icon-size: var(--button-icon-size);

            justify-content: var(--button-justify-content);
        }
    }

    &.secondary {
        --button-background-color: var(--genomics-color-primary-bright-blue);
        --button-border-color: transparent;
        --button-color: rgb(255 255 255);
        --button-outline-color: color-mod(var(--genomics-color-primary-bright-blue) alpha(50%));
    }

    &.is-loading {
        align-items: center;
        display: inline-flex;
        justify-content: center;

        & .label {
            margin-right: 8px;
        }

        & .spinner {
            animation: spinner-rotate 0.75s steps(8) infinite;
        }
    }
}

@keyframes spinner-rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
