.medical-actions {
    margin-bottom: 32px;

    & .description {
        margin-bottom: 8px;

        & h3 {
            --heading-margin: 0 0 24px;

            color: var(--genomics-color-primary-navy, rgb(17 0 53));
        }

        & .medical-action-step {
            align-items: flex-start;
            display: flex;

            & img {
                margin-right: 8px;
            }

            & p {
                --paragraph-bottom-margin: 24px;
                --paragraph-top-margin: 0;

                & .link {
                    color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));

                    & span {
                        color: var(--genomics-color-primary-bright-blue, rgb(13 94 255));
                    }
                }
            }
        }
    }

    & .actions {
        display: flex;
        flex-direction: column;
    }

    @media (--denim-breakpoint-desktop) {
        & .actions {
            flex-direction: row;
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .actions {
            flex-direction: row;
        }
    }
}
