.risk-thermometer {
    margin-bottom: 32px;

    & .chart-container {
        --high-risk-threshold: 0;
        --integrated-risk-score: 0;
        --chart-container-height: auto;
        --max-chart-percentage: 40;

        /* 100 / 40 because we are only respresnting up to 40% on the chart */
        --high-risk-threshold-marker-position: min(calc(var(--high-risk-threshold) * 100% / var(--max-chart-percentage)), 100%);
        --integrated-risk-marker-position: min(calc(var(--integrated-risk-score) * 100% / var(--max-chart-percentage)), 100%);

        height: var(--chart-container-height);

        & .top-label {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: -22px;
            width: 100%;
        }

        & .chart {
            --chart-gradient-direction: 90deg;

            display: flex;
            flex-wrap: nowrap;
            position: relative;
            width: 100%;

            & .high-risk-threshold-marker {
                --marker-position: var(--high-risk-threshold-marker-position);
                --marker-vertical-offset: 5px;
                --marker-width: 10px;

                align-items: center;
                display: flex;
                flex-direction: column;
                height: calc(100% + var(--marker-vertical-offset));
                left: calc(var(--marker-position) - var(--marker-width) / 2);
                position: absolute;
                top: calc(-1 * var(--marker-vertical-offset));

                & .knob {
                    background: black;
                    border-radius: 50%;
                    height: 10px;
                    width: var(--marker-width);
                }

                & .neck {
                    background: black;
                    height: calc(100% - 10px);
                    width: 2px;
                }
            }

            & .integrated-risk-marker {
                --marker-position: var(--integrated-risk-marker-position);
                --marker-width: 24px;

                background: white;
                border: 6px solid black;
                border-radius: 50%;
                box-sizing: content-box;
                height: 12px;
                left: calc(var(--marker-position) - var(--marker-width) / 2);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 12px;
            }

            & .section {
                height: 48px;
            }

            & .typical-risk {
                background: linear-gradient(var(--chart-gradient-direction), var(--minerva-color-typical-risk-start) 0%, var(--minerva-color-typical-risk-end) 100%);
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
                width: var(--high-risk-threshold-marker-position);
            }

            & .increased-risk {
                background: linear-gradient(var(--chart-gradient-direction), var(--minerva-color-increased-risk-start) 0%, var(--minerva-color-increased-risk-end) 100%);
                width: calc(100% - var(--high-risk-threshold-marker-position));

                /* Jagged edge */
                &::after {
                    background:
                        linear-gradient(45deg, var(--minerva-color-increased-risk-end) 6px, transparent 0),
                        linear-gradient(135deg, var(--minerva-color-increased-risk-end) 6px, transparent 0);
                    background-position-x: 0;
                    background-position-y: 6px;
                    background-repeat: repeat-y;
                    background-size: 12px 12px;
                    content: "";
                    height: 100%;
                    position: absolute;
                    right: -9px;
                    width: 10px;
                }
            }
        }

        & .bottom-label {
            bottom: -4px;
            position: absolute;
            width: 100%;

            & p {
                position: absolute;
                transform: translateX(-50%);

                &:nth-child(1) {
                    transform: translateX(0%);
                }

                &:nth-child(2) {
                    left: 25%;
                }

                &:nth-child(3) {
                    left: 50%;
                }

                &:nth-child(4) {
                    left: 75%;
                }

                &:nth-child(5) {
                    left: 100%;
                    text-align: right;
                    transform: translateX(-100%);
                    width: 190px;
                }
            }
        }

        & .tooltip {
            --border-bottom-color: transparent;
            --border-left-color: transparent;
            --border-right-color: transparent;
            --border-top-color: var(--tooltip-background-color);
            --bottom-position: auto;
            --left-position: 50%;
            --margin: 0 0 var(--tooltip-height);
            --right-position: auto;
            --tooltip-background-color: white;
            --tooltip-height: 18px;
            --top-position: 100%;

            background: var(--tooltip-background-color);
            border-radius: 5px;
            display: inline-block;
            left: var(--pointer-position);
            margin: var(--margin);
            padding: 8px 16px;
            position: relative;
            transform: translateX(-50%);

            &::after {
                border-color: var(--border-top-color) var(--border-right-color) var(--border-bottom-color) var(--border-left-color);
                border-style: solid;
                border-width: var(--tooltip-height);
                bottom: var(--bottom-position);
                content: "";
                left: var(--left-position);
                position: absolute;
                right: var(--right-position);
                top: var(--top-position);
                transform: translateX(-50%);
            }

            &.high-risk-threshold {
                --margin: 0 0 calc(var(--tooltip-height) + 12px);
                --pointer-position: var(--high-risk-threshold-marker-position);
                --lower-bound-offset: calc(132px - (20px * var(--high-risk-threshold)));
                --diff: calc(var(--max-chart-percentage) - var(--high-risk-threshold));
                --upper-bound-offset: calc(132px - (20px * max(var(--diff), 0)));

                &.lower-bound {
                    transform: translateX(calc(-50% + var(--lower-bound-offset)));

                    &::after {
                        transform: translateX(calc(-50% - var(--lower-bound-offset)));
                    }
                }

                &.upper-bound {
                    transform: translateX(calc(-50% - var(--upper-bound-offset)));

                    &::after {
                        transform: translateX(calc(-50% + var(--upper-bound-offset)));
                    }
                }
            }

            &.integrated-risk {
                --denim-paragraph-color: white;
                --border-bottom-color: var(--tooltip-background-color);
                --border-top-color: transparent;
                --bottom-position: 100%;
                --margin: calc(var(--tooltip-height) + 8px) 0 0;
                --pointer-position: var(--integrated-risk-marker-position);
                --top-position: auto;
                --tooltip-background-color: var(--genomics-color-primary-navy);
                --lower-bound-offset: calc(48px - (20px * var(--integrated-risk-score)));
                --diff: calc(var(--max-chart-percentage) - var(--integrated-risk-score));
                --upper-bound-offset: calc(48px - (20px * max(var(--diff), 0)));

                &.lower-bound {
                    transform: translateX(calc(-50% + var(--lower-bound-offset)));

                    &::after {
                        transform: translateX(calc(-50% - var(--lower-bound-offset)));
                    }
                }

                &.upper-bound {
                    transform: translateX(calc(-50% - var(--upper-bound-offset)));

                    &::after {
                        transform: translateX(calc(-50% + var(--upper-bound-offset)));
                    }
                }

                & .percentage {
                    align-items: center;
                    background: var(--genomics-color-shade-white);
                    border-radius: 50%;
                    color: var(--genomics-color-primary-navy);
                    display: inline-flex;
                    height: 40px;
                    justify-content: center;
                    margin: 4px;
                    width: 40px;
                }
            }

            & .is-caption {
                max-width: 284px;
            }
        }
    }

    & .legend {
        display: none;
        width: 222px;
    }

    @media (--denim-breakpoint-max-tablet-portrait) {
        margin-bottom: 64px;

        & .chart-container {
            --chart-container-height: 433px;

            display: flex;
            gap: 18px;
            justify-content: center;

            & .top-label {
                flex-direction: column-reverse;
                height: calc(100% + 64px);
                left: 50%;
                text-align: center;
                top: -32px;
                transform: translateX(-50%);
                width: 100vw;
            }

            & .chart {
                --chart-gradient-direction: 0deg;

                flex-direction: column-reverse;
                flex-shrink: 0;
                height: var(--chart-container-height);
                width: 65px;

                & .high-risk-threshold-marker {
                    bottom: calc(var(--marker-position) - var(--marker-width) / 2);
                    flex-direction: row;
                    height: auto;
                    left: calc(-1 * var(--marker-vertical-offset));
                    top: auto;
                    width: calc(100% + var(--marker-vertical-offset));

                    & .knob {
                        height: var(--marker-width);
                        width: 10px;
                    }

                    & .neck {
                        height: 2px;
                        width: calc(100% - 10px);
                    }
                }

                & .integrated-risk-marker {
                    bottom: calc(var(--marker-position) - var(--marker-width) / 2);
                    left: 50%;
                    top: auto;
                    transform: translateX(-50%);
                }

                & .typical-risk {
                    border-bottom-right-radius: 5px;
                    border-top-left-radius: 0;
                    height: var(--high-risk-threshold-marker-position);
                    width: 100%;
                }

                & .increased-risk {
                    height: calc(100% - var(--high-risk-threshold-marker-position));
                    width: 100%;

                    /* Jagged edge */
                    &::after {
                        background:
                            linear-gradient(45deg, transparent 33.333%, var(--minerva-color-increased-risk-end) 33.333%, var(--minerva-color-increased-risk-end) 66.667%, transparent 66.667%),
                            linear-gradient(135deg, transparent 33.333%, var(--minerva-color-increased-risk-end) 33.333%, var(--minerva-color-increased-risk-end) 66.667%, transparent 66.667%);
                        background-position-x: 4px;
                        background-position-y: 0;
                        background-repeat: repeat-x;
                        background-size: 8px 16px;
                        height: 10px;
                        right: 0;
                        top: -8px;
                        width: 100%;
                    }
                }
            }

            & .bottom-label {
                bottom: auto;
                height: 100%;
                right: -4px;
                width: auto;

                & p {
                    transform: translate(0, 50%);

                    &:nth-child(1) {
                        bottom: 0;
                    }

                    &:nth-child(2) {
                        bottom: 25%;
                    }

                    &:nth-child(3) {
                        bottom: 50%;
                    }

                    &:nth-child(4) {
                        bottom: 75%;
                    }

                    &:nth-child(5) {
                        display: none;
                    }
                }
            }

            & .tooltip {
                --border-bottom-color: transparent;
                --border-left-color: var(--tooltip-background-color);
                --border-right-color: transparent;
                --border-top-color: transparent;
                --left-position: 100%;
                --margin: 0;
                --top-position: 50%;
                --tooltip-height: 12px;

                height: fit-content;
                left: 0;
                top: calc(100% - var(--pointer-position));
                transform: translateX(0) translateY(-50%);

                &::after {
                    transform: translateY(-50%);
                }

                &.high-risk-threshold {
                    --denim-font-weight-semibold: normal;
                    --lower-bound-offset: calc(48px - (10px * var(--high-risk-threshold)));
                    --diff: calc(var(--max-chart-percentage) - var(--high-risk-threshold));
                    --upper-bound-offset: calc(48px - (10px * max(var(--diff), 0)));

                    &.lower-bound {
                        transform: translateX(0) translateY(calc(-50% - var(--lower-bound-offset)));

                        &::after {
                            transform: translateX(0) translateY(calc(-50% + var(--lower-bound-offset)));
                        }
                    }

                    &.upper-bound {
                        transform: translateX(0) translateY(calc(-50% + var(--upper-bound-offset)));

                        &::after {
                            transform: translateX(0) translateY(calc(-50% - var(--upper-bound-offset)));
                        }
                    }
                }

                &.integrated-risk {
                    --border-bottom-color: transparent;
                    --border-left-color: transparent;
                    --border-right-color: var(--tooltip-background-color);
                    --left-position: auto;
                    --margin: 0;
                    --right-position: 100%;
                    --top-position: 50%;

                    text-align: center;

                    &.lower-bound {
                        transform: translateX(0) translateY(-50%);

                        &::after {
                            transform: translateY(-50%);
                        }
                    }

                    &.upper-bound {
                        transform: translateX(0) translateY(-50%);

                        &::after {
                            transform: translateY(-50%);
                        }
                    }
                }

                & .is-caption {
                    width: 80px;
                }
            }
        }

        & .legend {
            display: block;
            margin-bottom: 48px;
        }
    }
}
