.icon {
    &.information {
        --icon-information: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M17 22v-8h-4v2h2v6h-3v2h8v-2h-3zM16 8a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 8z"/><path d="M16 30a14 14 0 1114-14 14 14 0 01-14 14zm0-26a12 12 0 1012 12A12 12 0 0016 4z"/></svg>');

        &.before::before {
            mask: var(--icon-information) no-repeat;
        }

        &.after::after {
            mask: var(--icon-information) no-repeat;
        }
    }
}
