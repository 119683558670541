/* stylelint-disable declaration-no-important */
.skeleton {
    --skeleton-animation: var(--minerva-skeleton-transition-duration, 1s) linear infinite alternate skeleton-pulse;
    --skeleton-border-radius: var(--minerva-skeleton-border-radius, 0);
    --skeleton-color-start: var(--minerva-skeleton-color-start, rgb(239 239 239));
    --skeleton-color-end: var(--minerva-skeleton-color-end, rgb(100 100 100));

    animation: var(--skeleton-animation);
    background: var(--skeleton-color-start);
    background-clip: padding-box !important;
    border-color: var(--skeleton-color-start);
    border-radius: var(--skeleton-border-radius);
    box-shadow: none !important;
    color: transparent !important;
    cursor: default;
    pointer-events: none;
    user-select: none;

    /* Make pseudo-elements (CSS icons) and children invisible */
    &::before,
    &::after,
    & * {
        visibility: hidden !important;
    }
}
/* stylelint-enable declaration-no-important */

@keyframes skeleton-pulse {
    from {
        background: var(--skeleton-color-start);
        border-color: var(--skeleton-color-start);
    }

    to {
        background: var(--skeleton-color-end);
        border-color: var(--skeleton-color-end);
    }
}
