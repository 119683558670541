.icon {
    &.play--filled--alt {
        --icon-play--filled--alt: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M7 28a1 1 0 01-1-1V5a1 1 0 011.482-.876l20 11a1 1 0 010 1.752l-20 11A1 1 0 017 28z"/></svg>');

        &.before::before {
            mask: var(--icon-play--filled--alt) no-repeat;
        }

        &.after::after {
            mask: var(--icon-play--filled--alt) no-repeat;
        }
    }
}
