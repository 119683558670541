.survey {
    --card-background-color: var(--genomics-color-shade-white, rgb(255 255 255));
    --card-margin-bottom: 0;
    --card-padding-bottom: 64px;
    --card-padding-top: 0;
    --denim-heading-color: var(--genomics-color-primary-light-green, rgb(51 255 196));

    & .grid {
        background-color: var(--genomics-color-primary-navy, rgb(17 0 53));
        background-image: url("/images/speech-circle.svg");
        background-position-x: calc(100% - 32px);
        border-radius: 5px;
        grid-template-areas: "content content";
        padding: 32px;

        & .content {
            grid-area: content;
        }
    }

    @media (--denim-breakpoint-max-tablet-portrait) {
        & .grid {
            --grid-horizontal-margin: 0;

            background-size: 0;
            border-radius: 0;
        }
    }

    @media (--denim-breakpoint-tablet-portrait) {
        & .grid {
            grid-template-areas: "content content content content content content";
        }
    }

    @media (--denim-breakpoint-tablet-landscape) {
        & .grid {
            background-size: 400px;
            grid-template-areas: "content content content content content . . . . .";
        }
    }

    @media (--denim-breakpoint-desktop) {
        & .grid {
            background-size: auto;
            grid-template-areas: "content content content content content content . . . . . .";
        }
    }
}
