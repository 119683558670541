.icon {
    &.chevron--up {
        --icon-chevron--up: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M 16,10.6 6,20.6 7.4,22 16,13.4 24.6,22 26,20.6 Z"/></svg>');

        &.before::before {
            mask: var(--icon-chevron--up) no-repeat;
        }

        &.after::after {
            mask: var(--icon-chevron--up) no-repeat;
        }
    }
}
