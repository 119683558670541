.nav {
    --nav-align-items: var(--denim-nav-align-items, center);
    --nav-display: var(--denim-nav-display, flex);
    --nav-img-size: var(--denim-nav-img-size, 1.375em);
    --nav-justify-content: var(--denim-nav-justify-content, space-between);
    --nav-margin-left: var(--denim-nav-margin-left, 2em);
    --nav-padding-vertical: var(--denim-nav-padding-vertical, 1.625em);
    --nav-width: var(--denim-nav-width, 100%);
    --nav-mobile-icon-size: var(--denim-nav-mobile-icon-size, 32px);

    align-items: var(--nav-align-items);
    background-color: transparent;
    display: var(--nav-display);
    justify-content: var(--nav-justify-content);
    padding-bottom: var(--nav-padding-vertical);
    padding-top: var(--nav-padding-vertical);
    width: var(--nav-width);

    & img {
        height: var(--nav-img-size);

        &.nav-mobile-image {
            --nav-img-size: 32px;
        }
    }

    & .nav-left {
        justify-content: flex-start;
        pointer-events: auto;
    }

    & .nav-right {
        justify-content: flex-end;
        pointer-events: auto;
    }

    & :--list {
        --list-padding-left: 0;
    }

    & ul {
        align-items: center;
        display: flex;
        margin: 0;

        & li {
            margin-bottom: 0;
            margin-left: 32px;
            margin-top: 0;
        }
    }

    &.nav-mobile {
        --nav-padding-vertical: 20px;

        & .nav-left {
            flex-grow: 1;
            margin-right: 16px;
        }
    }

    & .nav-mobile-icon {
        --denim-icon-before-margin: 0;
        --icon-size: var(--nav-mobile-icon-size);
    }
}

.navbar {
    box-shadow: 0 2px 5px rgb(0 0 0 / 6%);
}
