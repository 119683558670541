.progress-bar-gradient {
    --pbg-gradient-start: var(--denim-pbg-gradient-start, blue);
    --pbg-gradient-end: var(--denim-pbg-gradient-end, hotpink);
    --pbg-background-color: var(--denim-pbg-background-color, black);

    /* No animations by default, but these can be enabled */
    --pbg-animation-duration: var(--denim-pbg-animation-duration, 0);
    --pbg-animation-timing: var(--denim-pbg-animation-timing, ease-in-out);

    /* So that circles can be at the very beginning or end, and height is about the line */
    overflow: visible !important;

    & .background {
        fill: var(--pbg-background-color);
    }

    & .gradient {
        & .start {
            stop-color: var(--pbg-gradient-start);
        }

        & .end {
            stop-color: var(--pbg-gradient-end);
        }
    }

    & path,
    & circle {
        transition:
            cy var(--pbg-animation-duration) var(--pbg-animation-timing),
            transform var(--pbg-animation-duration) var(--pbg-animation-timing);
    }

    & .line {
        transition:
            y var(--pbg-animation-duration) var(--pbg-animation-timing),
            height var(--pbg-animation-duration) var(--pbg-animation-timing);
    }
}
